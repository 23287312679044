import * as apiService from 'lib/apollo/client'
import {
  AddTopicDocument,
  AddTopicMutation,
  AddTopicMutationVariables,
  DeleteTopicDocument,
  DeleteTopicMutation,
  DeleteTopicMutationVariables,
  EditTopicDocument,
  EditTopicMutation,
  EditTopicMutationVariables,
  RestoreTopicDocument,
  RestoreTopicMutation,
  RestoreTopicMutationVariables,
  TopicRelationsDocument,
  TopicRelationsQuery,
  TopicRelationsQueryVariables,
  TopicsDocument,
  TopicsQuery,
  TopicsQueryVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

class TopicService {
  async getTopics() {
    const { topics: resp } = await apiService.query<TopicsQuery, TopicsQueryVariables>(TopicsDocument)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.topics
  }

  async createTopic(payload: AddTopicMutationVariables) {
    const { addTopic: resp } = await apiService.mutate<AddTopicMutation, AddTopicMutationVariables>(
      AddTopicDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async updateTopic(payload: EditTopicMutationVariables) {
    const { editTopic: resp } = await apiService.mutate<EditTopicMutation, EditTopicMutationVariables>(
      EditTopicDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async deleteTopic(payload: DeleteTopicMutationVariables) {
    const { deleteTopic: resp } = await apiService.mutate<DeleteTopicMutation, DeleteTopicMutationVariables>(
      DeleteTopicDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async restoreTopic(payload: RestoreTopicMutationVariables) {
    const { restoreTopic: resp } = await apiService.mutate<RestoreTopicMutation, RestoreTopicMutationVariables>(
      RestoreTopicDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async getTopicRelationsCount(payload: TopicRelationsQueryVariables) {
    const { topicRelations: resp } = await apiService.query<TopicRelationsQuery, TopicRelationsQueryVariables>(
      TopicRelationsDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }
}

export default new TopicService()
