import { ApplySchemaAttributes, NodeExtensionSpec, NodeSpecOverride, ProsemirrorNode } from 'remirror'
import { HorizontalRuleExtension } from 'remirror/extensions'

export class HorizontalRuleCustomExtension extends HorizontalRuleExtension {
  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      ...override,
      attrs: { ...extra.defaults(), color: { default: null }, height: { default: null }, border: { default: null } },
      parseDOM: [
        { tag: 'hr', getAttrs: (dom) => ({ ...extra.parse(dom), ...getRuleAttrs(dom as HTMLElement) }) },
        ...(override.parseDOM ?? []),
      ],
      toDOM: (node) => ['hr', { ...extra.dom(node), ...setRuleAttrs(node) }],
    }
  }
}

function getRuleAttrs(dom: HTMLElement) {
  return {
    color: dom.style.backgroundColor,
    height: dom.style.height,
    border: dom.style.border,
  }
}

function setRuleAttrs(node: ProsemirrorNode) {
  const attrs: Record<string, string> = {}
  if (node.attrs.color) {
    attrs.style = `${attrs.style ?? ''}background-color: ${node.attrs.color as string};`
  }
  if (node.attrs.height) {
    attrs.style = `${attrs.style ?? ''}height: ${node.attrs.height as string};`
  }
  if (node.attrs.border) {
    attrs.style = `${attrs.style ?? ''}border: ${node.attrs.border as string};`
  }

  return attrs
}
