import { Alert, Button, Form, Input, Modal, Skeleton } from 'antd'
import ContactAutoComplete from 'components/base/AutoComplete/ContactAutoComplete/ContactAutoComplete'
import ContactAutocompletePM from 'components/base/AutoComplete/ContactAutoComplete/ContactAutocomplete.pm'
import { captureExceptionSilently } from 'helpers/sentry'
import { isEmpty, isNil } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { msg } from 'stores/msg'
import styled from 'styled-components'
import { FlexRow } from 'styles'
import { emailOrIdFormValidator } from '../../../constants/formValidators'
import { Attachments } from './attachments'
import { SecurePrefixSwitch } from './components/SecurePrefixSwitch'
import Presenter from './pm'

async function setDefault(idOrEmail: string, field: string, name: string) {
  try {
    global.app.loading = true

    let defaultEmail = ''
    if (typeof idOrEmail === 'string' && isNaN(Number(idOrEmail))) {
      defaultEmail = idOrEmail
    } else {
      const client = await global.data.clients.getClient(Number(idOrEmail))
      defaultEmail = client.email as string
    }

    await global.data.advisors.update({ [field]: defaultEmail })
    msg.success(`Default ${name} updated`)
  } catch (error) {
    msg.error(`Something went wrong while updating the default ${name}. We'll be taking a look shortly!`, '')
    captureExceptionSilently(error, { message: 'setDefaultBccFromEmailSend', data: {} })
  }

  global.app.loading = false
}

const StyledForm = styled(Form)`
  .ant-form-item {
    .ant-form-item-label {
      label {
        margin-top: 4px;
      }
    }

    &.files {
      margin-bottom: 0;
      .ant-form-item-label {
        label {
          margin-top: 8px !important;
        }
      }
    }
  }
`

interface SendEmailProps {
  config: any
}

export const SendEmail: React.FC<SendEmailProps> = observer(({ config }) => {
  const [toContactAutocompletePM] = useState(() => new ContactAutocompletePM())
  const [ccContactAutocompletePM] = useState(() => new ContactAutocompletePM())
  const [bccContactAutocompletePM] = useState(() => new ContactAutocompletePM())
  const [pm] = useState(() => new Presenter(config))

  const [isInitialInvalid, setInitialInvalid] = useState(true)
  const [form] = Form.useForm()

  const [buttonDisabled, setButtonDisabled] = useState(true)

  const [defaultBccSelectable, setDefaultBccSelectable] = useState(false)
  const [defaultCcSelectable, setDefaultCcSelectable] = useState(false)

  useEffect(() => {
    if (pm.initialTo) {
      toContactAutocompletePM.setClients([...pm.initialTo])
      const invalid = pm.initialTo.some((contact) => isNil(contact.email) || isEmpty(contact.email))
      setInitialInvalid(invalid)
    }
  }, [pm.initialTo, toContactAutocompletePM])

  useEffect(() => {
    const isInvalid = form.getFieldsError().some((field) => field.errors.length > 0)

    setButtonDisabled(pm.isSending || isInvalid || isInitialInvalid)
  }, [pm.isSending, isInitialInvalid, form])

  return (
    <Modal
      onCancel={global.mail.close}
      width={pm.config.modalWidth}
      title={pm.config.modalTitle}
      open
      footer={
        <FlexRow justify="space-between">
          <span>
            <SecurePrefixSwitch form={form} pm={pm} />{' '}
          </span>
          <span>
            <Button onClick={global.mail.close} data-tour="send_email_cancel">
              Cancel
            </Button>
            <Button disabled={buttonDisabled} form="sendEmail" htmlType="submit" type="primary">
              Send Now
            </Button>
          </span>
        </FlexRow>
      }
    >
      <StyledForm
        // @ts-ignore
        onFinish={pm.onSubmit}
        form={form}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
        labelAlign="right"
        style={{ padding: '0 16px 0 0' }}
        id="sendEmail"
        initialValues={{
          to: pm.config.to,
          cc: pm.initialCCs,
          bcc: pm.initialBCCs,
          subject: pm.config.subject,
        }}
        onFieldsChange={() => {
          setButtonDisabled(form.getFieldsError().some((field) => field.errors.length > 0))

          if (pm.initialBCCs.length === 0) {
            const isValidBCCEmail = form.getFieldsError(['bcc'])?.[0].errors.length === 0 || false
            setDefaultBccSelectable((form.getFieldValue('bcc') || []).length === 1 && isValidBCCEmail)
          }

          if (pm.initialCCs.length === 0) {
            const isValidCCEmail = form.getFieldsError(['cc'])?.[0].errors.length === 0 || false
            setDefaultCcSelectable((form.getFieldValue('cc') || []).length === 1 && isValidCCEmail)
          }
        }}
      >
        {isInitialInvalid && (
          <Form.Item>
            <Alert
              style={{ marginBottom: 0 }}
              message="This client does't have an email address!"
              type="warning"
              showIcon
            />
          </Form.Item>
        )}
        <Form.Item
          label="To:"
          name="to"
          rules={[emailOrIdFormValidator, { required: true, message: 'No email address provided' }]}
        >
          {pm.loadingRecipients ? (
            <Skeleton.Input active block />
          ) : (
            <ContactAutoComplete
              pm={toContactAutocompletePM}
              optionMode="email"
              defaultValue={pm.config.to}
              mode="tags"
            />
          )}
        </Form.Item>
        {pm.config.showCCField && (
          <>
            <Form.Item
              label="Cc:"
              name="cc"
              rules={[emailOrIdFormValidator, { required: false, message: 'Carbon copy' }]}
              style={{ marginBottom: defaultCcSelectable ? '12px' : '24px' }}
            >
              <ContactAutoComplete
                pm={ccContactAutocompletePM}
                optionMode="email"
                mode="tags"
                defaultValue={pm.initialCCs.map((cc) => ({
                  key: cc,
                  value: cc,
                }))}
              />
            </Form.Item>
            {defaultCcSelectable && (
              <FlexRow style={{ width: '100%', marginBottom: '24px' }} justify="flex-end">
                <Button
                  htmlType="button"
                  onClick={() => setDefault(form.getFieldValue('cc')[0], 'defaultCc', 'CC')}
                  size="middle"
                >
                  Set as Your Default
                </Button>
              </FlexRow>
            )}
          </>
        )}
        {pm.config.showBCCField && (
          <>
            <Form.Item
              label="Bcc:"
              name="bcc"
              rules={[emailOrIdFormValidator, { required: false, message: 'Blind carbon copy' }]}
              style={{ marginBottom: defaultCcSelectable ? '12px' : '24px' }}
            >
              <ContactAutoComplete
                pm={bccContactAutocompletePM}
                optionMode="email"
                mode="tags"
                defaultValue={pm.initialBCCs.map((bcc) => ({
                  key: bcc,
                  value: bcc,
                }))}
              />
            </Form.Item>

            {defaultBccSelectable && (
              <FlexRow style={{ width: '100%', marginBottom: '24px' }} justify="flex-end">
                <Button
                  htmlType="button"
                  onClick={() => setDefault(form.getFieldValue('bcc')[0], 'defaultBcc', 'BCC')}
                  size="middle"
                >
                  Set as Your Default
                </Button>
              </FlexRow>
            )}
          </>
        )}
        {pm.config.showSubjectField && (
          <Form.Item label="Subject:" name="subject" rules={[{ required: true, message: 'Please add a Subject' }]}>
            <Input size="large" />
          </Form.Item>
        )}
        {/* <Divider type='horizontal' /> */}
        {pm.config.showAttachmentField && (
          <Form.Item label="Files" style={{ width: `100%` }} className="files">
            <Attachments pm={pm} />
          </Form.Item>
        )}
      </StyledForm>
    </Modal>
  )
})
