import { Form, Select } from 'antd'
import { formats } from 'helpers/formats'
import moment from 'moment'
import { SmartFieldContainer } from '../smartfield.container'
import { FormItemLabel } from '../smartfield.styles'
import { SmartField } from '../types'
import { SmartFieldDateConfig } from '../types/data'

interface SmartFieldDateProps {
  smartfield?: SmartField
  disableContainer?: boolean
  preserve?: boolean
}

export const SmartFieldDate: React.FC<SmartFieldDateProps> = ({ smartfield, disableContainer, preserve = true }) => {
  const sf = smartfield as SmartField<SmartFieldDateConfig>
  return (
    <SmartFieldContainer title="Date Options" testId="smartfield-date-form" disableContainer={disableContainer}>
      <Form.Item
        name="date"
        rules={[
          {
            required: true,
            message: 'Please select a date format',
          },
        ]}
        label={<FormItemLabel label="Date Format" description="Select your date format" />}
        initialValue={sf?.config?.date}
        preserve={preserve}
      >
        <Select placeholder="Select format" style={{ width: '100%' }} size="large">
          {Object.keys(formats).map((key) => {
            const format = (formats as any)[key]
            const now = moment().format(format)
            return (
              <Select.Option key={`date-${key}`} value={format} label={now}>
                {now}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>
    </SmartFieldContainer>
  )
}
