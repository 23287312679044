import { command, CommandFunction, extension, PlainExtension } from '@remirror/core'
import { dedentList, indentList } from '@remirror/extension-list'

@extension({})
export class ListItemSharedCustomExtension extends PlainExtension {
  get name() {
    return 'listItemSharedCustom' as const
  }

  @command()
  increaseIndentList(): CommandFunction {
    return (props) => {
      const { tr, dispatch } = props
      if (indentList(tr)) {
        dispatch?.(tr.scrollIntoView())
        return true
      }

      return this.store.commands.increaseIndent.original()(props)
    }
  }

  @command()
  decreaseIndentList(): CommandFunction {
    return (props) => {
      const { tr, dispatch } = props

      if (dedentList(tr)) {
        dispatch?.(tr.scrollIntoView())
        return true
      }

      return this.store.commands.decreaseIndent.original()(props)
    }
  }
}
