/* eslint-disable no-return-await */
import { observable, makeObservable, computed } from 'mobx'
import { defaults, orderBy } from 'lodash'
import { UploadFile } from 'antd'
import { FileObject } from 'types/graphql'
import { captureExceptionSilently } from 'helpers/sentry'
import { msg } from 'stores/msg'

const config = () => ({})

// TODO: change this to be accesible as a singleton service, rather than `global.images`
class Images {
  visible: boolean = false
  callback: Function | null = null
  config = {}

  images: FileObject[] = []

  /* ---------- Modal methods ---------- */

  /**
   * Close the 'insert' modal
   */
  close = () => {
    this.callback = null
    this.visible = false
  }

  /**
   * Choose an image to 'insert' from the modal
   */
  choose = (cb: Function, cfg: any) => {
    this.config = defaults(cfg, config())
    this.visible = true
    this.callback = cb
  }

  /**
   * Call the specified insert callback
   */
  insert = (file: File) => {
    if (this.callback) {
      this.callback(file)
      this.close()
    }
  }

  upload = async (file: UploadFile): Promise<FileObject | false> => {
    if (!(await this.checkImage(file))) {
      return false
    }
    try {
      const key = `common/img/${file.name}`
      const img = (await global.files.upload(file, key)) as FileObject
      this.images.push(img)
      return img
    } catch (error) {
      console.log(error)
      captureExceptionSilently(error, { message: 'uploadImage', data: {} })
      return false
    }
  }

  checkImage = async (file: UploadFile) => {
    const img = new Image()
    img.src = window.URL.createObjectURL(file as unknown as Blob)
    const sizeInMB = (file.size as number) / 1024 / 1024
    const maxPixels = 2000
    const maxMegabyte = 1

    return new Promise((resolve) => {
      img.onload = () => {
        if (sizeInMB > 1 || img.width > maxPixels || img.height > maxPixels) {
          msg.error(
            `Sorry, but the image is too large, we support images of up to ${maxPixels}x${maxPixels} pixels and a maximum of ${maxMegabyte}Mb.`,
            '',
          )
          resolve(false)
        } else {
          resolve(true)
        }
      }
    })
  }

  loadImages = async (): Promise<FileObject[]> => {
    try {
      const images = (await global.files.load(`common/img`)) as FileObject[]
      return orderBy<FileObject>(images, ['date'], ['desc'])
    } catch (error) {
      captureExceptionSilently(error, { message: 'loadImages', data: {} })
      return []
    }
  }

  getImages = async () => {
    if (!this.images || !this.images.length) {
      this.images = await this.loadImages()
    }
    return this.images
  }

  /* ---------- private ---------- */

  constructor() {
    makeObservable(this, {
      visible: observable,
      callback: observable,
      config: observable,
      images: observable,
    })
  }
}

export default Images
