import img from 'assets/images/bg.jpeg'
import styled from 'styled-components'
import media from 'styles/media'
import Layout from './styles'

const StyledLayout = styled(Layout)`
  width: 100vw;
  height: 100vh;
  position: relative;

  .c-layout__content {
    background-color: #ffffff;
  }

  @media (min-width: ${media.points.tablet}px) {
    .bg {
      background-image: url(${img});
      background-repeat: no-repeat;
      background-position: left top;
      background-size: cover;
      width: 100vw;
      height: 100vh;
      position: absolute;
      opacity: 0.8;
    }
  }

  .cl-signIn-root {
    .cl-footer {
      justify-content: space-between;
    }

    .cl-signIn-start {
      .cl-footer {
        justify-content: center;
      }
    }
  }

  .cl-card {
    border: 0;
    @media (min-width: ${media.points.tablet}px) {
      border: 1px solid rgba(19, 19, 22, 0.06);
    }

    padding: 2.375rem 2rem 3rem;
    position: relative;
    // box-shadow: rgba(0, 0, 0, 0.16) 0px 24px 12px

    .cl-footerAction__signIn {
      display: none;
    }

    .cl-headerTitle {
      font-family: Lato;
      font-weight: 600;
      text-align: center;
      font-size: 16px;
    }

    .cl-headerSubtitle {
      font-family: Lato;
      text-align: center;
      font-size: 14px;
    }
  }
`

/**
 * Simple centered layout for positioning content in
 * the center of the page, as per the Login page
 * @param {*} props
 */
export const ClerkLayout = (props) => (
  <StyledLayout>
    <div className="bg" />
    <Layout.Content className="c-layout__content flex">{props.children}</Layout.Content>
  </StyledLayout>
)
