export const contactFields = {
  id: 'ID',
  creator: 'Creator',
  created_at: 'Created At',
  updated_at: 'Updated At',
  prefix: 'Prefix',
  first_name: 'First Name',
  middle_name: 'Middle Name',
  last_name: 'Last Name',
  suffix: 'Suffix',
  nickname: 'Nickname',
  job_title: 'Job Title',
  company_name: 'Company Name',
  twitter_name: 'Twitter Name',
  linkedin_url: 'LinkedIn URL',
  background_information: 'Background Information',
  birth_date: 'Birth Date',
  anniversary: 'Anniversary',
  client_since: 'Client Since',
  date_of_death: 'Date of Death',
  assigned_to: 'Assigned To',
  referred_by: 'Referred By',
  type: 'Type',
  gender: 'Gender',
  contact_source: 'Contact Source',
  contact_type: 'Contact Type',
  status: 'Status',
  marital_status: 'Marital Status',
  attorney: 'Attorney',
  cpa: 'CPA',
  doctor: 'Doctor',
  insurance: 'Insurance',
  business_manager: 'Business Manager',
  family_officer: 'Family Officer',
  assistant: 'Assistant',
  other: 'Other',
  trusted_contact: 'Trusted Contact',
  important_information: 'Important Information',
  personal_interests: 'Personal Interests',
  investment_objective: 'Investment Objective',
  time_horizon: 'Time Horizon',
  risk_tolerance: 'Risk Tolerance',
  mutual_fund_experience: 'Mutual Fund Experience',
  stocks_and_bonds_experience: 'Stocks and Bonds Experience',
  partnerships_experience: 'Partnerships Experience',
  other_investing_experience: 'Other Investing Experience',
  gross_annual_income: 'Gross Annual Income',
  assets: 'Assets',
  non_liquid_assets: 'Non-Liquid Assets',
  liabilities: 'Liabilities',
  adjusted_gross_income: 'Adjusted Gross Income',
  estimated_taxes: 'Estimated Taxes',
  confirmed_by_tax_return: 'Confirmed by Tax Return',
  tax_year: 'Tax Year',
  tax_bracket: 'Tax Bracket',
  birth_place: 'Birth Place',
  maiden_name: 'Maiden Name',
  passport_number: 'Passport Number',
  green_card_number: 'Green Card Number',
  occupation: {
    name: 'Name',
    start_date: 'Start Date',
  },
  drivers_license: {
    number: 'Number',
    state: 'State',
    issued_date: 'Issued Date',
    expires_date: 'Expires Date',
  },
  retirement_date: 'Retirement Date',
  signed_fee_agreement_date: 'Signed Fee Agreement Date',
  signed_ips_agreement_date: 'Signed IPS Agreement Date',
  signed_fp_agreement_date: 'Signed FP Agreement Date',
  last_adv_offering_date: 'Last ADV Offering Date',
  initial_crs_offering_date: 'Initial CRS Offering Date',
  last_crs_offering_date: 'Last CRS Offering Date',
  last_privacy_offering_date: 'Last Privacy Offering Date',
  household: {
    name: 'Name',
    title: 'Title',
    id: 'ID',
    members: [
      {
        id: 'ID',
        first_name: 'First Name',
        last_name: 'Last Name',
        title: 'Title',
        type: 'Type',
      },
    ],
  },
  image: 'Image',
  tags: [
    {
      id: 'ID',
      name: 'Name',
    },
  ],
  street_addresses: [
    {
      street_line_1: 'Street Line 1',
      street_line_2: 'Street Line 2',
      city: 'City',
      state: 'State',
      zip_code: 'Zip Code',
      country: 'Country',
      principal: 'Principal',
      kind: 'Kind',
      id: 'ID',
      address: 'Address',
    },
  ],
  email_addresses: [
    {
      id: 'ID',
      address: 'Address',
      principal: 'Principal',
      kind: 'Kind',
    },
  ],
  phone_numbers: [
    {
      id: 'ID',
      address: 'Address',
      principal: 'Principal',
      extension: 'Extension',
      kind: 'Kind',
    },
  ],
  websites: [
    {
      id: 'ID',
      address: 'Address',
      principal: 'Principal',
      kind: 'Kind',
    },
  ],
  custom_fields: [
    {
      id: 'ID',
      name: 'Name',
      value: 'Value',
      document_type: 'Document Type',
      field_type: 'Field Type',
    },
  ],
  contact_roles: [
    {
      id: 'ID',
      name: 'Name',
      value: 'Value',
      assigned_to: {
        id: 'ID',
        type: 'Type',
        name: 'Name',
      },
    },
  ],
}
