/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Form, FormInstance, Select, Space } from 'antd'
import { useCallback, useState } from 'react'
import { getConnectionConfig } from 'stores/connections/connection.config'
import styled from 'styled-components'
import { UnsupportedProviderAlert } from '../smartfield.alerts'
import { FormItemLabel } from '../smartfield.styles'
import { SmartField, SmartFieldProvider } from '../types'
import { SmartFieldIntegrationConfig } from '../types/data'
import {
  ProviderConfiguration,
  getProviderConfig,
  providerConfig,
} from './providers/provider.config'

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: 18px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
`

interface SmartFieldIntegrationProps {
  smartfield?: SmartField
  form: FormInstance
  testId?: string
}

interface ProviderPros {
  provider: SmartFieldProvider
  form: FormInstance
  smartfield?: SmartField
}

const renderProvider: React.FC<ProviderPros> = ({
  form,
  smartfield,
  provider,
}) => {
  const cfg = getProviderConfig(provider)

  if (cfg) {
    return <cfg.smartfieldBuilder smartfield={smartfield} form={form} />
  }

  return <UnsupportedProviderAlert />
}

interface ProviderOptionPros {
  cfg: ProviderConfiguration
}

const renderProviderOption: React.FC<ProviderOptionPros> = ({ cfg }) => {
  const { connectionIcon: SmartFieldIcon } = getConnectionConfig(cfg.provider)

  return (
    <Select.Option
      key={`integration-${cfg.provider}`}
      value={cfg.provider}
      label={cfg.title}
      disabled={!cfg.enabled}
    >
      <Space>
        <LogoContainer>
          <SmartFieldIcon />
        </LogoContainer>
        {cfg.title}
      </Space>
    </Select.Option>
  )
}

export const SmartFieldIntegration: React.FC<SmartFieldIntegrationProps> = ({
  form,
  smartfield,
}) => {
  const sf = smartfield as SmartField<SmartFieldIntegrationConfig>

  const [provider, setProvider] = useState<SmartFieldProvider>(
    sf?.config?.provider as SmartFieldProvider,
  )

  // reset label field when changing provider
  const onChange = useCallback(
    (value: any) => {
      form.setFieldValue('label', undefined)
      setProvider(value)
    },
    [form],
  )

  return (
    <div data-testid="smartfield-integration-form">
      <Form.Item
        name="provider"
        rules={[
          {
            required: true,
            message: 'Please select a data provider',
          },
        ]}
        label={
          <FormItemLabel
            label="Provider"
            description="Select your Integration provider"
          />
        }
        initialValue={sf?.config?.provider}
        preserve={false}
      >
        <Select
          onChange={onChange}
          value={provider}
          placeholder="Select integration"
          style={{ width: '100%' }}
          size="large"
        >
          {Array.from(providerConfig.values()).map((cfg) =>
            renderProviderOption({ cfg }),
          )}
        </Select>
      </Form.Item>

      {provider && renderProvider({ form, smartfield, provider })}
    </div>
  )
}
