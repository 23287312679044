import { UploadFile } from 'antd'
import { useEffect, useState } from 'react'
import { FileObject } from 'types/graphql'

const useImagePicker = () => {
  const [selectedFile, setSelectedFile] = useState<FileObject | undefined>()
  const [images, setImages] = useState<FileObject[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const showPicker = (callback: (file: FileObject) => void) => {
    global.images.choose((file: FileObject) => {
      callback(file)
    })
  }

  const hidePicker = () => {
    global.images.close()
    setSelectedFile(undefined)
    setLoading(false)
    setImages([])
  }

  const onSelect = (file: FileObject) => {
    setSelectedFile(selectedFile?.id === file.id ? undefined : file)
  }

  const onUpload = async (file: UploadFile) => {
    const img = (await global.images.upload(file)) as FileObject
    setImages(await global.images.getImages())
    setSelectedFile(img)
    return false
  }

  const onRemove = async () => {
    if (!selectedFile) {
      return
    }
    setLoading(true)

    const { key } = selectedFile
    await global.files.delete(key)

    setImages(images.filter((i) => i.key !== key))
    setSelectedFile(undefined)
    setLoading(false)

    return false
  }

  const onInsert = () => {
    if (selectedFile) {
      global.images.insert(selectedFile)
    }
  }

  useEffect(() => {
    const load = async () => {
      setLoading(true)
      setImages(await global.images.getImages())
      setLoading(false)
    }
    load()
  }, [])

  return {
    selectedFile,
    images,
    loading,
    onSelect,
    onUpload,
    onRemove,
    onInsert,
    showPicker,
    hidePicker,
  }
}

export default useImagePicker
