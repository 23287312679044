/* eslint-disable no-useless-escape */
export const USPhone = /^([0-9]( |-)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{4}|[a-zA-Z0-9]{7})$/g
export const USZip = /^\d{5}(?:[-\s]\d{4})?$/
export const Email = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
export const FileName = /([^\\\/]+)$/
export const leadingTrailingSlashes = /^\/+|\/+$/g
export const leadingTrailingBackSlashes = /^\\+|\\+$/g
export const leadingSlashes = /^\//
export const trailingSlashes = /\/$/
// WARNING: this expression is very slow
export const imageURLs = /(?:[^\"]*)(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png|jpeg)(?:[^\"]*)/gi
//
export const imageURLsTagAtrribute =
  /(href|src)( ?= ?)("|')(http(s?):)([^"^'^#^ ^>]+?|\/\?)(\.[png|jpeg|jpg|gif|\?])([^"^'^>^#^ ]+)?(\3|#| |>)/gi
