import styles from 'styles'

const colors = {
  magenta: styles.colors.magenta,
  red: styles.colors.error,
  volcano: styles.colors.volcano,
  orange: styles.colors.orange,
  gold: styles.colors.gold,
  yellow: styles.colors.yellow,
  lime: styles.colors.lime,
  green: styles.colors.green,
  cyan: styles.colors.cyan,
  blue: styles.colors.blue,
  geekblue: styles.colors.deepBlue,
  purple: styles.colors.purple,
  null: '#EFF2F7',
}

export default Object.keys(colors).reduce((all, color) => {
  all[color] = colors[color].toLowerCase()
  return all
}, {})
