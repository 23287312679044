import {
  extension,
  GetAttributes,
  InputRule,
  nodeInputRule,
  NodeSpecOverride,
  ShouldSkipFunction,
} from '@remirror/core'
import { MentionAtomExtension, MentionAtomOptions } from '@remirror/extension-mention-atom'
import {
  ApplySchemaAttributes,
  command,
  isElementDomNode,
  NodeExtensionSpec,
  omitExtraAttributes,
  ProsemirrorAttributes,
} from 'remirror'

import { CommandFunction, NodeType, NodeWithPosition } from '@remirror/core'
import { NamedMentionAtomNodeAttributes } from 'remirror/dist-types/extensions'
import { reapplySelectionMarks, replaceTextCustom } from '../node/NodeCustom.utils'

import { createAtomAttrs } from 'stores/smartfields/utils'
import { DATA_ATTRIB_ID } from './constants'
import { parseDOMAttrs, toDOMAttrs, toDOMClasses } from './utils'

export interface MentionAtomCustomAttributes extends ProsemirrorAttributes {
  type: string

  /**
   * The emoji information of callout.
   *
   * @default ''
   */
  header?: string
  tags: string
}

export function updateNodeAttributes(type: NodeType) {
  return (attributes: MentionAtomCustomAttributes, node: NodeWithPosition, pos?: number): CommandFunction =>
    ({ state, tr, dispatch }) => {
      replaceTextCustom({
        type: type,
        attrs: { ...node.node.attrs, ...attributes },
        selection: state.selection,
        state,
        tr,
      })

      reapplySelectionMarks(tr, state.selection)

      if (dispatch) {
        dispatch(tr)
      }

      return true
    }
}

@extension<MentionAtomOptions>({
  defaultOptions: {
    selectable: true,
    draggable: false,
    mentionTag: 'span' as const,
    matchers: [],
    appendText: ' ',
    suggestTag: 'span' as const,
    disableDecorations: false,
    invalidMarks: [],
    invalidNodes: [],
    isValidPosition: () => true,
    validMarks: null,
    validNodes: null,
  },
  handlerKeyOptions: { onClick: { earlyReturnValue: true } },
  handlerKeys: ['onChange', 'onClick'],
  staticKeys: ['selectable', 'draggable', 'mentionTag', 'matchers'],
})
export class SmartFieldAtomExtension extends MentionAtomExtension {
  @command()
  updateMentionAtomCustom(
    attributes: MentionAtomCustomAttributes,
    node: NodeWithPosition,
    pos?: number,
  ): CommandFunction {
    return updateNodeAttributes(this.type)(attributes, node, pos)
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      inline: true,
      marks: '',
      selectable: this.options.selectable,
      draggable: this.options.draggable,
      atom: true,
      ...override,
      attrs: {
        ...extra.defaults(),
        id: {},
        name: {},
        label: {},
        config: { default: '{}' },
        reuse: { default: 'false' },
      },
      parseDOM: [
        ...this.options.matchers.map((matcher) => ({
          tag: `${matcher.mentionTag ?? this.options.mentionTag}[${DATA_ATTRIB_ID}]`,
          getAttrs: (node: string | Node) => {
            if (!isElementDomNode(node)) {
              return false
            }

            const attrs = {
              ...extra.parse(node),
              ...parseDOMAttrs(node),
            }

            return attrs
          },
        })),
        ...(override.parseDOM ?? []),
      ],
      toDOM: (node) => {
        let { label, name } = omitExtraAttributes(node.attrs, extra) as NamedMentionAtomNodeAttributes
        const matcher = this.options.matchers.find((matcher) => matcher.name === name)

        const attrs = {
          ...extra.dom(node),
          ...toDOMClasses(node, matcher),
          ...toDOMAttrs(node),
        }

        return [matcher?.mentionTag ?? this.options.mentionTag, attrs, label]
      },
    }
  }

  createInputRules(): InputRule[] {
    const shouldSkip: ShouldSkipFunction = ({ captureGroup }) => !captureGroup
    const getAttributes: GetAttributes = ([, match]) => (match ? createAtomAttrs(match) : undefined)

    return [
      // replace old school mergefields with the mentionAtom
      // ie. {{MergeField}}
      nodeInputRule({
        regexp: /{{(.*?)}}/,
        type: 'mentionAtom',
        shouldSkip,
        getAttributes,
      }),
    ]
  }
}
