import * as apiService from 'lib/apollo/client'
import {
  AppointmentTypesDocument,
  AppointmentTypesExternalDocument,
  AppointmentTypesExternalQuery,
  AppointmentTypesExternalQueryVariables,
  AppointmentTypesQuery,
  AppointmentTypesQueryVariables,
  SetAppointmentTypesDocument,
  SetAppointmentTypesMutation,
  SetAppointmentTypesMutationVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

class AppointmentTypeService {
  async getAppointmentTypes() {
    const { appointmentTypes: resp } = await apiService.query<AppointmentTypesQuery, AppointmentTypesQueryVariables>(
      AppointmentTypesDocument,
    )

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.appointmentTypes
  }

  async getAppointmentTypesExternal() {
    const { appointmentTypesExternal: resp } = await apiService.query<
      AppointmentTypesExternalQuery,
      AppointmentTypesExternalQueryVariables
    >(AppointmentTypesExternalDocument)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.appointmentTypesExternal
  }

  async setAppointmentTypes(payload: SetAppointmentTypesMutationVariables) {
    const { setAppointmentTypes: resp } = await apiService.mutate<
      SetAppointmentTypesMutation,
      SetAppointmentTypesMutationVariables
    >(SetAppointmentTypesDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.accepted
  }
}

export default new AppointmentTypeService()
