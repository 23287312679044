import { isDocNodeEmpty, removeNodeAtPosition, Transaction } from 'remirror'

export function removeEmptyNode({ pos, tr }: { pos: number; tr: Transaction }) {
  const resolvedPos = tr.doc.resolve(pos)

  const parent = resolvedPos.parent

  const parentsToRemove = ['listItem', 'taskListItem', 'blockquote']

  if (parent && parent.childCount === 1 && parentsToRemove.includes(parent.type.name)) {
    removeNodeAtPosition({ pos: pos - 1, tr })

    return
  }

  removeNodeAtPosition({ pos, tr })
}

export function ifNodeEmptyRemove({ pos, tr }: { pos: number; tr: Transaction }) {
  const resolvedPos = tr.doc.resolve(pos + 1)

  const parent = resolvedPos.parent

  const isListEmpty: boolean[] = []

  parent.content.forEach((node) => {
    const isListItemEmpty = isDocNodeEmpty(node)

    isListEmpty.push(isListItemEmpty)
  })

  if (isListEmpty.every((listItem) => listItem === true)) {
    removeEmptyNode({ pos, tr })
  }
}
