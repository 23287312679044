import { SmartFieldContainer } from 'components/drawers/Smartfields/smartfield.container'
import { ProviderSmartFieldsBuilderProps } from '../provider.interfaces'
import { usePreciseFP } from './precisefp.hooks'

export const PreciseFPBuilder: React.FC<
  ProviderSmartFieldsBuilderProps
> = () => {
  const { connected } = usePreciseFP()

  return (
    <SmartFieldContainer
      title="PreciseFP Options"
      connectionError={!connected}
      provider="precisefp"
      testId="smartfield-precisefp-form"
    >
      <></>
    </SmartFieldContainer>
  )
}
