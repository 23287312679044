import { CloseOutlined, MenuFoldOutlined, MenuUnfoldOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Col, Grid, Row } from 'antd'
import { observer } from 'mobx-react'
import { FC, useState } from 'react'
import { useMemberships } from 'stores/auth/hook'
import { PERMS_KEYS } from '../../../../constants/perms.constants'
import { mainLayoutPM } from '../../mainLayout.pm'
import HelpMenu from '../../menu/TopMenu/HelpMenu'
import SettingsMenu from '../../menu/TopMenu/SettingsMenu'
import ShortcutMenu from '../../menu/TopMenu/ShortcutMenu'
import UserMenu from '../../menu/TopMenu/UserMenu'
import { SelectContactPage } from './SelectContactPage'
import { OrgSwitcher } from './OrganizationSwitcher'

interface HeaderNavBarProps {}

const { useBreakpoint } = Grid

const LargeMenu = observer(() => {
  const showQuickCaptureNote = global.perms.get(PERMS_KEYS.QUICK_CAPTURE_NOTE)
  const { memberships } = useMemberships()

  return (
    <Row justify="space-between" align="middle">
      <Col lg={6} data-tour="layout_search_contact">
        <SelectContactPage />
      </Col>

      <Col span={12}>
        <Row justify="end" gutter={16} align="middle">
          {showQuickCaptureNote ? (
            <Col>
              <ShortcutMenu data-tour="quick_caputre_note" />
            </Col>
          ) : null}
          <Col>
            <SettingsMenu />
          </Col>
          <Col>
            <HelpMenu />
          </Col>
          {memberships.length > 1 && (
            <Col>
              <OrgSwitcher />
            </Col>
          )}
          <Col>
            <UserMenu />
          </Col>
        </Row>
      </Col>
    </Row>
  )
})

const MobileMenu = observer(() => {
  const [isSearchVisible, setIsSearchVisible] = useState(false)
  const screens = useBreakpoint()

  if (isSearchVisible) {
    return (
      <Row justify="center">
        <Col span={19}>
          <SelectContactPage onSelect={() => setIsSearchVisible(!isSearchVisible)} />
        </Col>
        <Col span={2}>
          <Button
            type="text"
            icon={<CloseOutlined />}
            onClick={() => setIsSearchVisible(!isSearchVisible)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
        </Col>
      </Row>
    )
  }

  return (
    <Row justify="space-between" align="middle" gutter={8}>
      {screens.sm ? (
        <Col>
          <Button
            type="text"
            icon={mainLayoutPM.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={mainLayoutPM.toggleCollapsed}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
        </Col>
      ) : null}
      {mainLayoutPM.collapsed && (
        <Col style={{ paddingLeft: 20 }}>
          <OrgSwitcher />
        </Col>
      )}

      {mainLayoutPM.collapsed && (
        <Col span={3}>
          {/* <Button
            type="text"
            icon={<SearchOutlined />}
            onClick={() => setIsSearchVisible(!isSearchVisible)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          /> */}
          <UserMenu onlyLogout />
        </Col>
      )}
    </Row>
  )
})

const HeaderNavBar: FC<HeaderNavBarProps> = () => {
  const screens = useBreakpoint()

  return screens.lg ? <LargeMenu /> : <MobileMenu />
}

export default observer(HeaderNavBar)
