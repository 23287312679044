export const UUID = 'c73bcdcc-2669-4bf6-81d3-e4ae73fb11fd'
export const SID = `${UUID}|123`

// Regex for a standard UUID
//  eg. 123e4567-e89b-12d3-a456-426655440000
export const singleUseRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

// Regex for uuid (non-reusable), appended with reusable id, separated with pipe:
//  eg. 123e4567-e89b-12d3-a456-426655440000|123
export const multiUseRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\|\d{1,}$/

export const sidOnlyRegex = /^\d+$/
