import { ERRORS as BASE_ERRORS } from '../provider.errors'

export const ERRORS = {
  ...BASE_ERRORS,
  LOAD_CONTACTS_ERROR: 'Unable to load Clients',
  LOAD_CLIENT_ERROR: 'Unable to load Client',
  LOAD_MODELS_ERROR: 'Unable to load Models/Portfolios',
  NO_CONTACTS_ERROR: 'Unable to load Clients',
  NO_MODELS_ERROR: 'There are no Models/Portolios for this account',
  NO_CLIENT_ERROR: 'We could not find that Client',
  NO_DATA_ERROR: 'Nitrogen does not have data for this Client/Smartfield',
  UNEXPECTED_ERROR: "We're having trouble fetching your data",
  CREATE_QUESTIONAIRE_ERROR: 'Unable to create Nitrogen Questionnaire',
  INVESTED_ERROR: 'You must input amount invested',
}
