import * as apiService from 'lib/apollo/client'
import { EntityId } from 'types/entity.interface'
import {
  AddClientDocument,
  AddClientMutation,
  AddClientMutationVariables,
  ChecklistDocument,
  ChecklistInput,
  ChecklistQuery,
  ChecklistQueryVariables,
  Client,
  ClientExistsInCrmDocument,
  ClientExistsInCrmQuery,
  ClientExistsInCrmQueryVariables,
  ClientInput,
  ClientModelAssociationsDocument,
  ClientModelAssociationsQuery,
  ClientModelAssociationsQueryVariables,
  ClientsDocument,
  ClientsFilters,
  ClientsQuery,
  ClientsQueryVariables,
  DeleteClientDocument,
  DeleteClientMutation,
  DeleteClientMutationVariables,
  DeleteExternalAssetDocument,
  DeleteExternalAssetMutation,
  DeleteExternalAssetMutationVariables,
  DeleteFutureStrategyDocument,
  DeleteFutureStrategyMutation,
  DeleteFutureStrategyMutationVariables,
  ExternalAssetInput,
  ExternalAssetsDocument,
  ExternalAssetsQuery,
  ExternalAssetsQueryVariables,
  FutureStrategiesDocument,
  FutureStrategiesQuery,
  FutureStrategiesQueryVariables,
  FutureStratgeyInput,
  GetClientDocument,
  GetClientQuery,
  GetClientQueryVariables,
  GetClientsCountDocument,
  GetClientsCountQuery,
  GetClientsCountQueryVariables,
  GetClientsDocument,
  GetClientsQuery,
  GetClientsQueryVariables,
  RestoreClientDocument,
  RestoreClientMutation,
  RestoreClientMutationVariables,
  SearchClientsDocument,
  SearchClientsQuery,
  SearchClientsQueryVariables,
  SocialMediaDocument,
  SocialMediaInput,
  SocialMediaQuery,
  SocialMediaQueryVariables,
  UpdateChecklistDocument,
  UpdateChecklistMutation,
  UpdateChecklistMutationVariables,
  UpdateClientDocument,
  UpdateClientMutation,
  UpdateClientMutationVariables,
  UpdateExternalAssetDocument,
  UpdateExternalAssetMutation,
  UpdateExternalAssetMutationVariables,
  UpdateFutureStrategyDocument,
  UpdateFutureStrategyMutation,
  UpdateFutureStrategyMutationVariables,
  UpdateSocialMediaDocument,
  UpdateSocialMediaMutation,
  UpdateSocialMediaMutationVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

class ClientsService {
  async clients() {
    const { clients: resp } = await apiService.query<ClientsQuery, ClientsQueryVariables>(ClientsDocument)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async getClients(filters?: ClientsFilters) {
    const { getClients: resp } = await apiService.query<GetClientsQuery, GetClientsQueryVariables>(
      GetClientsDocument,
      {
        filters,
      },
      {
        fetchPolicy: 'no-cache',
      },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async searchClients(name: string, includeArchived?: boolean) {
    const { searchClients: resp } = await apiService.query<SearchClientsQuery, SearchClientsQueryVariables>(
      SearchClientsDocument,
      {
        name,
        includeArchived,
      },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.clients
  }

  async getClient(id: EntityId, includeArchived?: boolean): Promise<never | Client> {
    const { getClient: resp } = await apiService.query<GetClientQuery, GetClientQueryVariables>(
      GetClientDocument,
      {
        id,
        includeArchived,
      },
      { fetchPolicy: 'no-cache' },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async getChecklist(clientId: EntityId) {
    const { checklist: resp } = await apiService.query<ChecklistQuery, ChecklistQueryVariables>(ChecklistDocument, {
      clientId,
    })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async getAssociations(clientId: EntityId) {
    const { clientModelAssociations: resp } = await apiService.query<
      ClientModelAssociationsQuery,
      ClientModelAssociationsQueryVariables
    >(ClientModelAssociationsDocument, {
      clientId,
    })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async getExternalAssets(clientId: EntityId) {
    const { externalAssets: resp } = await apiService.query<ExternalAssetsQuery, ExternalAssetsQueryVariables>(
      ExternalAssetsDocument,
      {
        clientId,
      },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.assets
  }

  async getFutureStrategies(clientId: EntityId) {
    const { futureStrategies: resp } = await apiService.query<FutureStrategiesQuery, FutureStrategiesQueryVariables>(
      FutureStrategiesDocument,
      {
        clientId,
      },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.strategies
  }

  async getSocialMedia(clientId: EntityId) {
    const { socialMedia: resp } = await apiService.query<SocialMediaQuery, SocialMediaQueryVariables>(
      SocialMediaDocument,
      {
        clientId,
      },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async getClientsCount() {
    const { getClientsCount: resp } = await apiService.query<GetClientsCountQuery, GetClientsCountQueryVariables>(
      GetClientsCountDocument,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.count
  }

  async clientExistsInCrm(clientId: EntityId) {
    const { clientExistsInCrm: resp } = await apiService.query<ClientExistsInCrmQuery, ClientExistsInCrmQueryVariables>(
      ClientExistsInCrmDocument,
      {
        clientId,
      },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.exists
  }

  async addClient(clientData: ClientInput) {
    const { addClient: resp } = await apiService.mutate<AddClientMutation, AddClientMutationVariables>(
      AddClientDocument,
      {
        client: clientData,
      },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async deleteClient(clientId: EntityId) {
    const { deleteClient: resp } = await apiService.mutate<DeleteClientMutation, DeleteClientMutationVariables>(
      DeleteClientDocument,
      {
        id: clientId,
      },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async restoreClient(clientId: EntityId) {
    const { restoreClient: resp } = await apiService.mutate<RestoreClientMutation, RestoreClientMutationVariables>(
      RestoreClientDocument,
      {
        id: clientId,
      },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async updateClient(clientId: EntityId, clientData: ClientInput) {
    const { updateClient: resp } = await apiService.mutate<UpdateClientMutation, UpdateClientMutationVariables>(
      UpdateClientDocument,
      {
        client: clientData,
        id: clientId,
      },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async deleteExternalAsset(id: EntityId) {
    const { deleteExternalAsset: resp } = await apiService.mutate<
      DeleteExternalAssetMutation,
      DeleteExternalAssetMutationVariables
    >(DeleteExternalAssetDocument, {
      id,
    })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async createExternalAsset(clientId: EntityId) {
    const { updateExternalAsset: resp } = await apiService.mutate<
      UpdateExternalAssetMutation,
      UpdateExternalAssetMutationVariables
    >(UpdateExternalAssetDocument, {
      clientId,
      asset: {},
    })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.asset
  }

  async updateExternalAsset(clientId: EntityId, asset: ExternalAssetInput, assetId: EntityId) {
    const { updateExternalAsset: resp } = await apiService.mutate<
      UpdateExternalAssetMutation,
      UpdateExternalAssetMutationVariables
    >(
      UpdateExternalAssetDocument,
      {
        clientId,
        asset,
        id: assetId,
      },
      { fetchPolicy: 'no-cache' },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.asset
  }

  async createFutureStrategy(clientId: EntityId) {
    const { updateFutureStrategy: resp } = await apiService.mutate<
      UpdateFutureStrategyMutation,
      UpdateFutureStrategyMutationVariables
    >(UpdateFutureStrategyDocument, {
      clientId,
      strategy: {},
    })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.strategy
  }

  async updateFutureStrategy(clientId: EntityId, strategy: FutureStratgeyInput, strategyId: EntityId) {
    const { updateFutureStrategy: resp } = await apiService.mutate<
      UpdateFutureStrategyMutation,
      UpdateFutureStrategyMutationVariables
    >(
      UpdateFutureStrategyDocument,
      {
        clientId,
        strategy,
        id: strategyId,
      },
      { fetchPolicy: 'no-cache' },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.strategy
  }

  async deleteFutureStrategy(id: EntityId) {
    const { deleteFutureStrategy: resp } = await apiService.mutate<
      DeleteFutureStrategyMutation,
      DeleteFutureStrategyMutationVariables
    >(DeleteFutureStrategyDocument, {
      id,
    })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async updateSocialMedia(clientId: EntityId, social: SocialMediaInput, socialMediaId: EntityId) {
    const { updateSocialMedia: resp } = await apiService.mutate<
      UpdateSocialMediaMutation,
      UpdateSocialMediaMutationVariables
    >(UpdateSocialMediaDocument, {
      clientId,
      social,
      id: socialMediaId,
    })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async updateChecklist(clientId: EntityId, checklist: ChecklistInput, checklistId: EntityId) {
    const { updateChecklist: resp } = await apiService.mutate<
      UpdateChecklistMutation,
      UpdateChecklistMutationVariables
    >(
      UpdateChecklistDocument,
      {
        clientId,
        id: checklistId,
        checklist,
      },
      { fetchPolicy: 'no-cache' },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }
}

export default new ClientsService()
