/* eslint-disable prefer-promise-reject-errors */
import { EMPTY_PREVIEW } from 'components/routes/tasks/notes/document/constants/DocumentEditor.constants'
import { filterValidFiles } from 'components/routes/tasks/notes/notes.utils'
import { formats } from 'constants/formats'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { msg } from 'stores/msg'
import { captureExceptionSilently } from '../../helpers/sentry'
import communicationService from '../services/communication.service'

class Html {
  margins = '1in 1in 1in 1in'
  options = {}
  html = EMPTY_PREVIEW
  preview = ''
  docTitle = ''
  attachments = {}
  attachmentsNotes = {}
  warnings = []
  template = null
  advisor = null
  alias = null
  report360view = []
  htmlChanges = -1

  constructor(authStore) {
    this.authStore = authStore

    makeObservable(this, {
      margins: observable.ref,
      options: observable.ref,
      warnings: observable.ref,
      html: observable,
      preview: observable,
      docTitle: observable.ref,
      report360view: observable.ref,
      htmlChanges: observable,
      attachments: observable,
      emailAttachments: computed,
      attachmentsNotes: observable,
      addAttachment: action,
      removeAttachment: action,
      loadReport360viewRange: action.bound,
      addDocumentState: action.bound,
      buildDocument: action.bound,
      setBlankHtml: action,
      setEmptyHtml: action,
      template: observable,
      advisor: observable,
      alias: observable,
    })
  }

  get emailAttachments() {
    return Object.keys(this.attachments).map((key) => {
      const file = this.attachments[key]
      if (file.content) {
        return {
          filename: file.name,
          contentType: file.contentType,
          encoding: 'base64',
          content: file.content,
        }
      }
      return {
        filename: file.name,
        path: file.url,
      }
    })
  }

  /* ---------- handlers ---------- */

  /**
   *
   */
  addAttachment = (file) => {
    this.attachments[file.id] = file
  }

  /**
   *
   */
  removeAttachment = (fileId) => {
    delete this.attachments[fileId]
  }

  /**
   *
   */
  setBlankHtml = () => {
    this.docTitle = 'Blank'
    this.html = EMPTY_PREVIEW
    this.attachments = {}
    this.warnings = []
    this.attachmentsNotes = {}
    this.template = null
    this.htmlChanges = -1
    this.preview = ''
  }

  /**
   *
   */
  setEmptyHtml = () => {
    this.docTitle = 'Empty'
    this.attachments = {}
    this.html = EMPTY_PREVIEW
    this.warnings = []
    this.attachmentsNotes = {}
    this.template = null
    this.htmlChanges = -1
    this.preview = ''
  }

  /**
   * @param {*}
   */
  async loadReport360viewRange(range) {
    if (!range) {
      throw new Error('Appts: No range provided!')
    }
    try {
      const documents = await communicationService.load360View(range)
      this.report360view = documents
      return documents
    } catch (err) {
      msg.error(['360view', 'fetching'])
      captureExceptionSilently(err, {
        message: 'loadReport360viewRange',
        data: { range },
      })
    }
  }

  /**
   * @param {*}
   */
  async loadFullDocument(id) {
    global.app.loading = true
    try {
      const document = await communicationService.loadCommunication(id)
      return document
    } catch (err) {
      captureExceptionSilently(err, {
        message: 'loadFullDocument',
        data: { id },
      })
      throw err
    }
  }

  /* ---------- export handlers ----------- */
  /* ---------- private ---------- */

  /**
   *
   */
  buildDocument = (html, selectedTemplate, appliedNotes, advisor, alias) => {
    runInAction(() => {
      this.template = selectedTemplate
      this.advisor = advisor
      this.alias = alias
      const options = this.getOptions(selectedTemplate, html)
      const { margins, name } = options
      this.options = options
      this.margins = margins
      this.docTitle = name
      this.attachments = {}
      this.attachmentsNotes = {}
      this.warnings = []
      this.processAttachments(appliedNotes)
      this.htmlChanges = -1
    })
  }

  /**
   * Add 1 to the current htmlChanges counter.
   *
   * The logic of this, is that visually setting the HTML in the editor takes 2 changes:
   * The one from the global.data.html.html, and one extra change that froala makes to set it.
   * The idea is that the initial state is 0, but we start from -1 to account for froala's initial change.
   *
   * In the future, this function might work to have a "history" of changes.
   */
  addDocumentState(_html) {
    this.htmlChanges = this.htmlChanges + 1
  }

  /**
   * Retrieves the required window size, converting to pixels
   */
  getOptions = (tmpl, html) => {
    const format = (tmpl && tmpl.format) || 'letter'
    const tation = (tmpl && tmpl.orientation) || 'portrait'

    const dims = formats[format]
    const wIndex = tation === 'portrait' ? 0 : 1
    const hIndex = tation === 'portrait' ? 1 : 0

    return {
      // height: Math.floor(dims[hIndex] * PixelsPerCentimenter),
      // width: Math.floor(dims[wIndex] * PixelsPerCentimenter),
      height: dims[hIndex],
      width: dims[wIndex],
      margins: tmpl?.margins || '1in 1in 1in 1in',
      orientation: tation,
      format,
      html,
    }
  }

  processAttachments(appliedNotes) {
    const filesOfNotes = appliedNotes.reduce((acum, note) => {
      const files = filterValidFiles(note)
      files.forEach((fileId) => {
        if (fileId) {
          // skip fileId === 0
          if (!acum[fileId]) {
            acum[fileId] = { id: fileId, notes: [note] }
          } else {
            acum[fileId].notes.push(note)
          }
        }
      })
      return acum
    }, {})

    const attachments = Object.values(filesOfNotes).map((relatedFile) => ({
      ...relatedFile,
      file: global.data.docs.get(relatedFile.id),
    }))

    if (attachments) {
      this.warnings = attachments.reduce((acum, { file, notes, id }) => {
        if (file && file.id) {
          this.addAttachment(file)
          notes.map((note) => (this.attachmentsNotes[file.id] = note))
        } else {
          notes.forEach((note) => {
            const tagStrippedWords = note.text.replace(/<[^>]*>?/gm, '')
            const words =
              note.text.length > 20 ? tagStrippedWords.substring(0, 17) + '...' : tagStrippedWords.substring(0, 20)
            acum.push({ text: `The text with words “${words}” has an attachment that is missing.` })
          })
        }
        return acum
      }, [])
    }
  }
}

export default Html
