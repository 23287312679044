import { baseConfig } from './base'

const base = baseConfig('staging')

export const config = {
  ...base,

  scriptSrc: [
    ...base.scriptSrc,
    // Product Fruits
    'https://*.productfruits.com',
    'https://*.chameleon.io',

    // Clerk Auth endpoint
    'https://regular-ocelot-58.clerk.accounts.dev',
  ],

  connectSrc: [
    ...base.connectSrc,
    // Product Fruits
    'https://*.productfruits.com',
    'wss://*.productfruits.com',
    'https://*.chameleon.io',

    // Clerk Auth endpoint
    'https://regular-ocelot-58.clerk.accounts.dev',
  ],

  frameSrc: [
    ...base.frameSrc,
    // Product Fruits
    'https://*.productfruits.com',
    'https://*.chameleon.io',
  ],

  imgSrc: [
    ...base.imgSrc,
    // Product Fruits
    'https://*.productfruits.com',
    'https://*.chameleon.io',
  ],

  styleSrc: [
    ...base.styleSrc,
    // Product Fruits
    'https://*.productfruits.com',
  ],
}
