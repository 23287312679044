/* eslint-disable no-console */
// import logger from 'electron-log'

const proto = Object.getOwnPropertyNames(console)

/**
 *
 */
export const debugEnabled = () => true

const proxy = (obj) => {
  proto.forEach((k) => {
    if (typeof console[k] === 'function') {
      obj[k] = (...opts) => console[k].apply(null, opts)
    }
  })
}

/**
 * In production, we need to hijack the global.log to prevent
 * cpu intensive logging outputted to the console.  Logging is
 * disabled by default. It can be enabled/disabled explicitly,
 * by typing the following into the console:
 *
 * console functions are disabled entirely
 *
 * 1. Enable logging:
 *
 *  localStorage.debug = true
 *
 * 2. Disable logging:
 *
 *  localStorage = null
 *
 * NB// Both 1 and 2 require a page refresh to see results
 */

function log(ats, test) {
  // proxy console methods to ats
  proxy(ats)
}

export default log
