import {
  SmartFieldConfig,
  SmartFieldIntegrationConfig,
} from 'components/drawers/Smartfields/types/data'
import {
  RiskalyzeDataFormValues,
  RiskalyzeFormValues,
  SmartFieldFormValues,
  SmartFieldIntegrationFormValues,
} from 'components/drawers/Smartfields/types/form'
import {
  RiskalyzeConfig,
  RiskalyzeDataConfig,
} from 'components/drawers/Smartfields/types/integration'
import { ConfigParser, FormParser } from '../../../provider.interfaces'

export const riskalyzeModelParser: FormParser = (values, config) => {
  const cfg = config as SmartFieldIntegrationConfig<
    RiskalyzeConfig<RiskalyzeDataConfig>
  >
  const vals = values as SmartFieldFormValues<
    SmartFieldIntegrationFormValues<
      RiskalyzeFormValues<RiskalyzeDataFormValues>
    >
  >

  cfg.provider = 'riskalyze'
  cfg.data_fields = JSON.stringify(vals.data_fields)

  return config as SmartFieldConfig
}

export const riskalyzeModelConfigParser: ConfigParser = (values, config) => {
  const cfg = config as SmartFieldConfig<
    SmartFieldIntegrationConfig<RiskalyzeConfig<RiskalyzeDataConfig>>
  >
  const vals = values as SmartFieldFormValues<
    SmartFieldIntegrationFormValues<
      RiskalyzeFormValues<RiskalyzeDataFormValues>
    >
  >

  vals.data_fields = JSON.parse(cfg.data_fields)
  return vals
}
