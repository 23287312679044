import { EditorState, extension, Helper, helper, isDocNodeEmpty, PlainExtension } from '@remirror/core'
import { Node } from '@remirror/pm/model'

export interface EmptyOptions {}

/**
 * Allows to know if the editor is empty or not.
 */
@extension<EmptyOptions>({})
export class EmptyExtension extends PlainExtension<EmptyOptions> {
  get name() {
    return 'empty' as const
  }

  /**
   * Allows to know if the editor is empty or not.
   *
   * @param state
   */
  @helper()
  isEmpty(state: EditorState = this.store.getState()): Helper<boolean> {
    const node = state.doc

    return isDocNodeEmpty(node)
  }

  static isDocEmpty(node: Node) {
    return isDocNodeEmpty(node)
  }
}
