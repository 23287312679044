import { SmartFieldConfig, SmartFieldListConfig, SmartFieldListItem } from '../types/data'
import { SmartFieldFormValues, SmartFieldListFormValues } from '../types/form'

export const listFormParser = (
  values: SmartFieldFormValues,
  config: Partial<SmartFieldConfig<SmartFieldListConfig>>,
): SmartFieldConfig => {
  const listValues = values as SmartFieldFormValues<SmartFieldListFormValues>

  config.list = Object.keys(listValues).reduce<SmartFieldListItem[]>((list, labelKey) => {
    if (/listItem\d/.test(labelKey)) {
      const value = (values as any)[labelKey]
      delete (config as any)[labelKey]

      list.push({
        label: value,
        value: value,
      })
    }

    return list
  }, [])

  return config as SmartFieldConfig<SmartFieldListConfig>
}

export const listConfigParser = (config: SmartFieldConfig): SmartFieldListFormValues => {
  const cfg = config as SmartFieldConfig<SmartFieldListConfig>

  return cfg.list.reduce((items, { value }, index) => {
    return { ...items, [`listItem${index + 1}`]: value }
  }, {})
}
