import * as apiService from 'lib/apollo/client'
import {
  CreateFolderDocument,
  CreateFolderMutation,
  CreateFolderMutationVariables,
  DeleteFileDocument,
  DeleteFileMutation,
  DeleteFileMutationVariables,
  FileObjectInput,
  LoadFilesDocument,
  LoadFilesQuery,
  LoadFilesQueryVariables,
  SignFileUrlDocument,
  SignFileUrlQuery,
  SignFileUrlQueryVariables,
  UpdateFileDocument,
  UpdateFileMutation,
  UpdateFileMutationVariables,
  UploadFileDocument,
  UploadFileMutation,
  UploadFileMutationVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

class FilesService {
  async loadFiles(path: string) {
    const { loadFiles: resp } = await apiService.query<LoadFilesQuery, LoadFilesQueryVariables>(LoadFilesDocument, {
      path,
    })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.files
  }

  async signFileURL(url: string) {
    const { signFileURL: resp } = await apiService.query<SignFileUrlQuery, SignFileUrlQueryVariables>(
      SignFileUrlDocument,
      { url },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.signedURL
  }

  async deleteFile(key: string) {
    const { deleteFile: resp } = await apiService.mutate<DeleteFileMutation, DeleteFileMutationVariables>(
      DeleteFileDocument,
      { key },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async createFolder(key: string) {
    const { createFolder: resp } = await apiService.mutate<CreateFolderMutation, CreateFolderMutationVariables>(
      CreateFolderDocument,
      { key },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.file
  }

  async updateFile(id: string, file: FileObjectInput) {
    const { updateFile: resp } = await apiService.mutate<UpdateFileMutation, UpdateFileMutationVariables>(
      UpdateFileDocument,
      {
        file,
        id,
      },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.file
  }

  async uploadFile(file: any, key: string) {
    const { uploadFile: resp } = await apiService.mutate<UploadFileMutation, UploadFileMutationVariables>(
      UploadFileDocument,
      {
        file,
        key,
      },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.file
  }
}

export default new FilesService()
