import * as apiService from 'lib/apollo/client'
import DataError, { IsError } from '../../helpers/dataError'
import {
  HolistiplanAllClientsDocument,
  HolistiplanAllClientsQuery,
  HolistiplanAllClientsQueryVariables,
  HolistiplanLoadClientDocument,
  HolistiplanLoadClientQuery,
  HolistiplanLoadClientQueryVariables,
  HolistiplanLoadTaxRecordsDocument,
  HolistiplanLoadTaxRecordsQuery,
  HolistiplanLoadTaxRecordsQueryVariables,
} from '../../types/graphql'

export const allClients = async () => {
  const { holistiplanAllClients: resp } = await apiService.mutate<
    HolistiplanAllClientsQuery,
    HolistiplanAllClientsQueryVariables
  >(HolistiplanAllClientsDocument, {})
  if (IsError(resp)) {
    throw new DataError(resp)
  }

  return resp.clients
}

export const loadClient = async (payload: HolistiplanLoadTaxRecordsQueryVariables) => {
  const { holistiplanLoadClient: resp } = await apiService.mutate<
    HolistiplanLoadClientQuery,
    HolistiplanLoadClientQueryVariables
  >(HolistiplanLoadClientDocument, payload)
  if (IsError(resp)) {
    throw new DataError(resp)
  }

  return resp.client
}

export const loadTaxRecords = async (payload: HolistiplanLoadTaxRecordsQueryVariables) => {
  const { holistiplanLoadTaxRecords: resp } = await apiService.mutate<
    HolistiplanLoadTaxRecordsQuery,
    HolistiplanLoadTaxRecordsQueryVariables
  >(HolistiplanLoadTaxRecordsDocument, payload)
  if (IsError(resp)) {
    throw new DataError(resp)
  }

  return resp.records
}
