import * as apiService from 'lib/apollo/client'

import DataError, { IsError } from '../../helpers/dataError'
import {
  ConfirmTranscriptionDocument,
  ConfirmTranscriptionMutation,
  ConfirmTranscriptionMutationVariables,
  CreateTranscriptionDocument,
  CreateTranscriptionMutation,
  CreateTranscriptionMutationVariables,
  CreateTranscriptionSummaryDocument,
  CreateTranscriptionSummaryMutation,
  CreateTranscriptionSummaryMutationVariables,
  CreateUploadTranscriptionFileUrlDocument,
  CreateUploadTranscriptionFileUrlMutation,
  CreateUploadTranscriptionFileUrlMutationVariables,
  DeleteTranscriptionDocument,
  DeleteTranscriptionMutation,
  DeleteTranscriptionMutationVariables,
  GetBotStatusDocument,
  GetBotStatusQuery,
  GetBotStatusQueryVariables,
  GetUnprocessedTranscriptionsDocument,
  GetUnprocessedTranscriptionsQuery,
  GetUnprocessedTranscriptionsQueryVariables,
  RequestBotJoinMeetingDocument,
  RequestBotJoinMeetingMutation,
  RequestBotJoinMeetingMutationVariables,
  RequestBotLeaveMeetingDocument,
  RequestBotLeaveMeetingMutation,
  RequestBotLeaveMeetingMutationVariables,
  RetriggerTranscriptionDocument,
  RetriggerTranscriptionMutation,
  RetriggerTranscriptionMutationVariables,
  TranscriptionDocument,
  TranscriptionQuery,
  TranscriptionQueryVariables,
  TranscriptionsByEventDocument,
  TranscriptionsByEventQuery,
  TranscriptionsByEventQueryVariables,
  TranscriptionsDocument,
  TranscriptionsQuery,
  TranscriptionsQueryVariables,
  TriggerTranscriptionStepDocument,
  TriggerTranscriptionStepMutation,
  TriggerTranscriptionStepMutationVariables,
  UpdateTranscriptionDocument,
  UpdateTranscriptionMutation,
  UpdateTranscriptionMutationVariables,
  UploadTranscriptionFileDocument,
  UploadTranscriptionFileMutation,
  UploadTranscriptionFileMutationVariables,
} from '../../types/graphql'

class PulseRecorderService {
  async createTranscription(payload: CreateTranscriptionMutationVariables) {
    const { createTranscription: resp } = await apiService.mutate<
      CreateTranscriptionMutation,
      CreateTranscriptionMutationVariables
    >(CreateTranscriptionDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async updateTranscription(payload: UpdateTranscriptionMutationVariables) {
    const { updateTranscription: resp } = await apiService.mutate<
      UpdateTranscriptionMutation,
      UpdateTranscriptionMutationVariables
    >(UpdateTranscriptionDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async deleteTranscription(payload: DeleteTranscriptionMutationVariables) {
    const { deleteTranscription: resp } = await apiService.mutate<
      DeleteTranscriptionMutation,
      DeleteTranscriptionMutationVariables
    >(DeleteTranscriptionDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.resources
  }

  async triggerTranscriptionStep(
    payload: TriggerTranscriptionStepMutationVariables,
  ) {
    const { triggerTranscriptionStep: resp } = await apiService.mutate<
      TriggerTranscriptionStepMutation,
      TriggerTranscriptionStepMutationVariables
    >(TriggerTranscriptionStepDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async createTranscriptionSummary(
    payload: CreateTranscriptionSummaryMutationVariables,
  ) {
    const { createTranscriptionSummary: resp } = await apiService.mutate<
      CreateTranscriptionSummaryMutation,
      CreateTranscriptionSummaryMutationVariables
    >(CreateTranscriptionSummaryDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.summary
  }

  async getTranscription(id: number) {
    const { transcription: resp } = await apiService.query<
      TranscriptionQuery,
      TranscriptionQueryVariables
    >(
      TranscriptionDocument,
      {
        id,
      },
      { fetchPolicy: 'no-cache' },
    )

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async getTranscriptions() {
    const { transcriptions: resp } = await apiService.query<
      TranscriptionsQuery,
      TranscriptionsQueryVariables
    >(TranscriptionsDocument, {}, { fetchPolicy: 'no-cache' })

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.transcriptions
  }

  async getTranscriptionsByEventId(eventId: number) {
    const { transcriptionsByEvent: resp } = await apiService.query<
      TranscriptionsByEventQuery,
      TranscriptionsByEventQueryVariables
    >(TranscriptionsByEventDocument, {
      eventId,
    })

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.transcriptions
  }

  async uploadTranscriptionFile(file: Blob, key: string) {
    const { uploadTranscriptionFile: resp } = await apiService.mutate<
      UploadTranscriptionFileMutation,
      UploadTranscriptionFileMutationVariables
    >(UploadTranscriptionFileDocument, {
      file,
      key,
    })

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async uploadTranscriptionFileFromUrl(key: string) {
    const { createUploadTranscriptionFileUrl: resp } = await apiService.mutate<
      CreateUploadTranscriptionFileUrlMutation,
      CreateUploadTranscriptionFileUrlMutationVariables
    >(CreateUploadTranscriptionFileUrlDocument, {
      key,
    })

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.url
  }

  async retriggerTranscription(
    payload: RetriggerTranscriptionMutationVariables,
  ) {
    const { retriggerTranscription: resp } = await apiService.mutate<
      RetriggerTranscriptionMutation,
      RetriggerTranscriptionMutationVariables
    >(RetriggerTranscriptionDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async confirmTranscription(payload: ConfirmTranscriptionMutationVariables) {
    const { confirmTranscription: resp } = await apiService.mutate<
      ConfirmTranscriptionMutation,
      ConfirmTranscriptionMutationVariables
    >(ConfirmTranscriptionDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async requestBotJoinMeeting(payload: RequestBotJoinMeetingMutationVariables) {
    const { requestBotJoinMeeting: resp } = await apiService.mutate<
      RequestBotJoinMeetingMutation,
      RequestBotJoinMeetingMutationVariables
    >(RequestBotJoinMeetingDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async requestBotLeaveMeeting(
    payload: RequestBotLeaveMeetingMutationVariables,
  ) {
    const { requestBotLeaveMeeting: resp } = await apiService.mutate<
      RequestBotLeaveMeetingMutation,
      RequestBotLeaveMeetingMutationVariables
    >(RequestBotLeaveMeetingDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async getBotStatus(payload: GetBotStatusQueryVariables) {
    const { getBotStatus: resp } = await apiService.mutate<
      GetBotStatusQuery,
      GetBotStatusQueryVariables
    >(GetBotStatusDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async getUnprocessedTranscriptions() {
    const { getUnprocessedTranscriptions: resp } = await apiService.query<
      GetUnprocessedTranscriptionsQuery,
      GetUnprocessedTranscriptionsQueryVariables
    >(GetUnprocessedTranscriptionsDocument, {})

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.events
  }
}

const pulseRecorderService = new PulseRecorderService()

export default pulseRecorderService
