import { useOrganization } from '@clerk/clerk-react'
import logo from 'assets/images/pulse_logo_green_v3.png'
import styled from 'styled-components'
import styles from 'styles'

const PADDING = 12

const StyledWrapperLogo = styled.div`
  height: ${styles.headerHeight}px;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 100;

  padding: ${PADDING}px;

  .container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    padding: ${PADDING}px;
    box-sizing: border-box;

    img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      object-fit: cover;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`

const WrapperLogo = (props: any) => {
  const { organization } = useOrganization()

  return (
    <StyledWrapperLogo>
      <div className="container">
        {organization?.hasImage ? (
          <img alt="logo" className="logo" src={organization?.imageUrl} />
        ) : (
          <img alt="logo" className="logo" src={logo} />
        )}
      </div>
    </StyledWrapperLogo>
  )
}

export default WrapperLogo
