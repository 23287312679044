export const AREA_TYPES = {
  ORDERED_LIST: 'orderedList',
  BULLET_LIST: 'bulletList',
  TASK_LIST: 'taskList',
  INLINE: 'inline',
} as const

export type AreaTypes = (typeof AREA_TYPES)[keyof typeof AREA_TYPES]

export const CONTAINER_TYPES = {
  ORDERED_LIST: 'orderedList',
  BULLET_LIST: 'bulletList',
  TASK_LIST: 'taskList',
  INLINE: 'inline',
} as const

export type ContainerTypes = (typeof CONTAINER_TYPES)[keyof typeof CONTAINER_TYPES]

export const AREA_TYPES_LIST: Omit<AreaTypes, typeof AREA_TYPES.INLINE>[] = [
  AREA_TYPES.ORDERED_LIST,
  AREA_TYPES.BULLET_LIST,
  AREA_TYPES.TASK_LIST,
]
