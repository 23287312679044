/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Form, Select } from 'antd'
import { SmartFieldContainer } from 'components/drawers/Smartfields/smartfield.container'
import { FormItemLabel } from 'components/drawers/Smartfields/smartfield.styles'
import {
  SmartFieldConfig,
  SmartFieldIntegrationConfig,
} from 'components/drawers/Smartfields/types/data'
import {
  RiskalyzeConfig,
  RiskalyzeDataConfig,
  RiskalyzeQuestionaireConfig,
} from 'components/drawers/Smartfields/types/integration'
import { useConnection } from 'stores/connections/connection.hooks'
import { ProviderSmartFieldsBuilderProps } from '../provider.interfaces'
import { RiskalyzeDataType } from './riskalyze.types'
import { SmartFieldIntegrationRiskalyzeData } from './types/data'
import { SmartFieldIntegrationRiskalyzeQuestionaire } from './types/questionaire/questionaire.builder'

export const RiskalyzeBuilder: React.FC<ProviderSmartFieldsBuilderProps> = ({
  smartfield,
  form,
}) => {
  const config = smartfield?.config as SmartFieldConfig<
    SmartFieldIntegrationConfig<RiskalyzeConfig>
  >

  const dataType = Form.useWatch<RiskalyzeDataType>('dataType', form)
  const { connected } = useConnection('riskalyze')

  return (
    <SmartFieldContainer
      title="Nitrogen Options"
      connectionError={!connected}
      provider="riskalyze"
      testId="smartfield-riskalyze-form"
    >
      <Form.Item
        name="dataType"
        rules={[{ required: true, message: 'Please select a data type' }]}
        label={
          <FormItemLabel
            label="Task"
            description="What would you like to do?"
          />
        }
        initialValue={config?.dataType}
        preserve={false}
      >
        <Select
          placeholder="Select Nitrogen task"
          style={{ width: '100%' }}
          size="large"
        >
          <Select.Option value="model">Import Nitrogen Data</Select.Option>
          <Select.Option value="questionaire">
            Create Questionnaire
          </Select.Option>
        </Select>
      </Form.Item>

      {dataType === 'questionaire' ? (
        <SmartFieldIntegrationRiskalyzeQuestionaire
          config={
            config as unknown as RiskalyzeConfig<RiskalyzeQuestionaireConfig>
          }
          smartfield={smartfield}
          form={form}
        />
      ) : (
        <>
          {dataType && (
            <SmartFieldIntegrationRiskalyzeData
              config={config as unknown as RiskalyzeConfig<RiskalyzeDataConfig>}
              smartfield={smartfield}
              type={dataType}
              form={form}
            />
          )}
        </>
      )}
    </SmartFieldContainer>
  )
}
