import { delay, get } from 'lodash'
import { msg } from 'stores/msg'

const logUserOut = (errorMessage) => {
  global.auth.signOut().then(() => {
    global.router.goto()
    delay(() => msg.error(errorMessage, 10), 500)
    delay(() => !document.querySelector('.header') && window.location.reload(), 7000)
  })
}

export function errorHandlers(code) {
  const errorHandlers = {
    disabled_practice: () =>
      logUserOut(
        `Your practice is disabled or under maintenance, please try to login later or reach out to the support team`,
      ),
    not_authorized: () => logUserOut(`Your access has been disabled. Please contact your admin.`),
    default: () => {
      msg.error(`Well this is embarrassing - something went wrong. Tech Support have been notified 😕`, 10)
    },
  }
  if (get(code, 'message', '') === 'Connection refused!') {
    return global.auth.signOut()
  }
  return code && code in errorHandlers ? errorHandlers[code]() : errorHandlers.default()
}
