import { SignInTheme } from '@clerk/types'
import styles from 'styles'

export const elements: SignInTheme['elements'] = {
  rootBox: {
    border: 0,
  },
  card: {
    gap: '1rem',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 10px 18px',
    borderRadius: 10,
    border: 0,
  },
  footerPagesLink: {
    color: styles.colors.primary,
  },
  logoBox: {
    height: 45,
    justifyContent: 'center',
  },
}
