import { Divider, Form, Typography } from 'antd'
import { ConnectionErrorAlert } from './smartfield.alerts'

interface SmartFieldContainerProps {
  children: React.ReactNode
  title: string
  connectionError?: boolean
  provider?: string
  testId: string
  disableContainer?: boolean
}

export const SmartFieldContainer: React.FC<SmartFieldContainerProps> = ({
  children,
  title,
  connectionError,
  provider,
  testId,
  disableContainer = false,
}) => (
  <>
    {disableContainer ? (
      <>{children}</>
    ) : (
      <div data-testid={testId}>
        <Divider />

        {connectionError && provider ? (
          <ConnectionErrorAlert provider={provider} />
        ) : (
          <>
            <Form.Item>
              <Typography.Title level={5} data-testid="smartfield-container-title">
                {title}
              </Typography.Title>
            </Form.Item>

            {children}
          </>
        )}
      </div>
    )}
  </>
)
