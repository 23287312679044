import { makeAutoObservable } from 'mobx'

class HolistiplanStore {
  _client = null
  _clientId = null

  constructor() {
    makeAutoObservable(this)
  }
}

export default HolistiplanStore
