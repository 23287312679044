import { DISPLAY_MODE } from 'lib/remirror/extensions/smartFieldAtom/utils/displayMode.constant'
import { EditorState, isProsemirrorNode } from 'remirror'
import { getNotesByTypeAndTags, getNotesNodesList } from '../../NoteReplacer.helpers'
import { MergeFieldContentToReplace, MergeFieldToReplace, NotesGroup } from '../../NoteReplacer.interfaces'
import { emptyMergeFieldContent } from './utils/emptyMergeFieldContent.util'
import { fixGroupingList } from './utils/fixGroupingList.util'
import { TopicTitle } from './utils/topicTitle.util'
import { hasValidSubtopic } from './utils/hasValidSubtopic.util'

/**
 * Processes a MergeFieldToReplace item, and returns it wrapped in a ListItem.
 * @param state
 * @param mergeFieldToReplace
 * @param notesGroup
 * @returns
 */
export function processMergeFieldToReplace(
  state: Readonly<EditorState>,
  mergeFieldToReplace: MergeFieldToReplace,
  notesGroup: NotesGroup[],
): MergeFieldContentToReplace {
  const { header, tags: placeholderTags, mergeFieldType, kind, marks, pos, parentAttrs } = mergeFieldToReplace

  if (isProsemirrorNode(mergeFieldToReplace) || !hasValidSubtopic(mergeFieldToReplace)) {
    return emptyMergeFieldContent(mergeFieldType, pos, marks)
  }

  const notesForPlaceholder = getNotesByTypeAndTags(notesGroup, mergeFieldType, placeholderTags)

  if (!notesForPlaceholder?.length) {
    return emptyMergeFieldContent(mergeFieldType, pos, marks)
  }

  const notesContent = getNotesNodesList(state, notesForPlaceholder, mergeFieldToReplace)
  const noteNodes = notesContent.map((note) => state.schema.nodes.listItem.create(null, note))
  const noteGroupList = state.schema.nodes.orderedList.create(null, noteNodes)

  const content = []

  const title = TopicTitle({ kind, header, state, mergeFieldType, placeholderTags, marks, attrs: parentAttrs })
  if (!title) {
    // FIX OL: not grouping lists
    fixGroupingList(state, pos, DISPLAY_MODE.OLLIST, (value) => content.push(value))
  }
  if (title) {
    content.push(title)
  }

  content.push(noteGroupList)

  const contentSize = content.reduce((accumulator, node) => accumulator + node.nodeSize, 0)

  return {
    type: mergeFieldType,
    pos,
    content,
    contentSize,
    marks,
  }
}
