import { map } from 'lodash'

export const queryStr = (args, append = '') => {
  const queries = map(args, (val, key) => `${key}=${val}`)
  return queries.join('&')
}

/**
 * Utility for processing a query string into json
 * object literal, ie. ?a=1&b=2 to {a:1, b:2}
 *
 * @param queryString
 */
export const queryParams = (query) => {
  if (!query) {
    return {}
  }
  const esc = decodeURIComponent
  const params = query.split('&')
  return params.reduce((obj, param) => {
    const p = param.split('=')
    obj[p[0]] = esc(p[1])
    return obj
  }, {})
}

/**
 * @returns
 */
export const getQueryParams = () => {
  const { search } = window.location
  return queryParams(search.replace(/^\?/, ''))
}
