/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useUser } from '@clerk/clerk-react'
import type { MenuProps } from 'antd'
import { Avatar, Dropdown } from 'antd'
import { HiOutlineLogout } from 'react-icons/hi'
import { MdSecurity } from 'react-icons/md'
import styled from 'styled-components'
import styles, { FlexCol, FlexRow } from 'styles'

const StyledTile = styled.div`
  padding: 0.2rem 2.5rem 0.2rem 0.2rem;

  img {
    max-height: 64px;
  }

  .full-name {
    font-weight: 600;
    font-size: 14px;
  }

  .email {
    font-size: 12px;
    color: ${styles.colors.gray7};
    margin-top: -3px;
  }

  .edit {
    font-size: 14px;
    text-decoration: underline;
    color: ${styles.colors.primary};
  }
`

const ProfileTile = () => {
  const { user } = useUser()
  return (
    <StyledTile>
      <FlexRow style={{ gap: 12 }}>
        <img src={user?.imageUrl} alt="" />
        <FlexCol style={{ gap: 0 }}>
          <div className="full-name">{user?.fullName}</div>
          <div className="email">{user?.primaryEmailAddress?.emailAddress}</div>
        </FlexCol>
      </FlexRow>
    </StyledTile>
  )
}

const UserMenu = ({ onlyLogout = false }: { onlyLogout?: boolean }) => {
  const { user } = useUser()

  const items: MenuProps['items'] = [
    {
      key: 'profile',
      label: <ProfileTile />,
    },
    {
      key: 'manage',
      label: 'Manage account',
      icon: <MdSecurity style={{ fontSize: 16, color: styles.colors.blue, marginLeft: 1 }} />,
    },
    {
      key: 'logout',
      icon: <HiOutlineLogout style={{ fontSize: 18, color: styles.colors.red, marginLeft: 2 }} />,
      label: 'Sign out',
    },
  ]

  const itemsFiltered = onlyLogout ? items.filter((item) => item?.key === 'logout') : items

  const menuClick = ({ key }: { key: string }) => {
    switch (true) {
      case key === 'logout':
        global.auth.signOut()
        break
      default:
        global.router.goto('/profile')
        break
    }
  }

  return (
    <Dropdown menu={{ items: itemsFiltered, onClick: menuClick }} placement="bottomRight" trigger={['click']}>
      <Avatar src={user?.imageUrl} size="default" shape="circle" />
      {/* <Button
        shape="circle"
      >
      </Button> */}
    </Dropdown>
  )
}

export default UserMenu
