import * as apiService from 'lib/apollo/client'
import {
  AddTaskDocument,
  AddTaskMutation,
  AddTaskMutationVariables,
  DeleteTaskDocument,
  DeleteTaskMutation,
  DeleteTaskMutationVariables,
  FindCategoriesDocument,
  FindCategoriesQuery,
  FindCategoriesQueryVariables,
  FindPrioritiesDocument,
  FindPrioritiesQuery,
  FindPrioritiesQueryVariables,
  GetTasksByClientDocument,
  GetTasksByClientQuery,
  GetTasksByClientQueryVariables,
  Task,
  UpdateTaskDocument,
  UpdateTaskMutation,
  UpdateTaskMutationVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'
import { EntityId } from '../../types/entity.interface'

class TaskService {
  async findCrmCategories() {
    const { findCategories: resp } = await apiService.query<FindCategoriesQuery, FindCategoriesQueryVariables>(
      FindCategoriesDocument,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.categories
  }

  async findCrmPriorities() {
    const { findPriorities: resp } = await apiService.query<FindPrioritiesQuery, FindPrioritiesQueryVariables>(
      FindPrioritiesDocument,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.priorities
  }

  async getTasksByClient(clientId: EntityId): Promise<Task[]> {
    const { getTasksByClient: resp } = await apiService.query<GetTasksByClientQuery, GetTasksByClientQueryVariables>(
      GetTasksByClientDocument,
      { clientId },
      { fetchPolicy: 'no-cache' },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.tasks
  }

  async createTask(payload: AddTaskMutationVariables) {
    const { addTask: resp } = await apiService.mutate<AddTaskMutation, AddTaskMutationVariables>(
      AddTaskDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async updateTask(payload: UpdateTaskMutationVariables) {
    const { updateTask: resp } = await apiService.mutate<UpdateTaskMutation, UpdateTaskMutationVariables>(
      UpdateTaskDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async deleteTask(payload: DeleteTaskMutationVariables) {
    const { deleteTask: resp } = await apiService.mutate<DeleteTaskMutation, DeleteTaskMutationVariables>(
      DeleteTaskDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }
}

export default new TaskService()
