/* eslint-disable react-hooks/exhaustive-deps */
import { Form, FormInstance } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useConnection } from 'stores/connections/connection.hooks'
import { smartfieldStore } from 'stores/smartfields'
import { ERRORS } from './generic.errors'
import { GenericSmartfieldOptions } from './generic.replacer'
import {
  ModelSelects,
  parseDataFields,
  parseRequiredSelects,
} from './generic.utils'

export const useGenericModelData = (
  options: GenericSmartfieldOptions,
  form?: FormInstance,
  uid: string = 'uid',
) => {
  const {
    provider,
    loadContacts,
    loadContact,
    firstNameProp = 'first_name',
    lastNameProp = 'last_name',
  } = options

  const [contacts, setContacts] = useState<any[]>([])
  const [contact, setContact] = useState<any>()

  const [error, setError] = useState<string | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingContact, setLoadingContact] = useState<boolean>(false)

  const contactId = Form.useWatch('contact_id', form)

  const { connected } = useConnection(provider)

  const setGenericError = (err: string) => {
    setError(err)
    setContacts([])
  }

  useEffect(() => {
    if (!connected) {
      return setGenericError(ERRORS.INTEGRATION_ERROR)
    }

    setContacts([])
    setError(undefined)
    setLoading(true)

    loadContacts()
      .then((cls) => {
        if (!cls || !cls.length) {
          return setGenericError(ERRORS.NO_CONTACTS_ERROR)
        }

        const sorted = cls
          .filter(
            (c) => !!(c as any)[firstNameProp] && !!(c as any)[lastNameProp],
          )
          .sort((a, b) => {
            const fullNameA =
              `${(a as any)[firstNameProp]} ${(a as any)[lastNameProp]}`.toLowerCase()
            const fullNameB =
              `${(b as any)[firstNameProp]} ${(b as any)[lastNameProp]}`.toLowerCase()

            if (fullNameA < fullNameB) return -1
            if (fullNameA > fullNameB) return 1
            return 0
          })

        setContacts(sorted)
      })
      .catch(() => {
        return setGenericError(ERRORS.LOAD_CONTACTS_ERROR)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [uid, connected])

  useEffect(() => {
    if (contactId) {
      setLoadingContact(true)
      loadContact(contactId)
        .then((cli) => {
          setLoadingContact(false)
          if (!cli) {
            return setGenericError(ERRORS.NO_CONTACT_ERROR)
          }

          setContact(cli)
        })
        .catch(() => {
          setLoadingContact(false)
          return setGenericError(ERRORS.LOAD_CONTACT_ERROR)
        })
    }
  }, [contactId, uid])

  return useMemo(
    () => ({
      contacts,
      contact,
      loading,
      error,
      loadingContact,
    }),
    [contacts, contact, loading, error, loadingContact],
  )
}

export const useModelSelects = (
  contact: any,
  dataFields: any,
  form: FormInstance,
  uid: string,
) => {
  const [selects, setSelects] = useState<ModelSelects[]>([])
  const [indices, setIndices] = useState<number[]>([])
  const [error, setError] = useState<string | undefined>()

  const onSelectIndice = useCallback(
    (indice = 0, index: number) => {
      const newIndices = [...indices]
      newIndices[index] = indice

      setIndices(newIndices)
      setError(undefined)
    },
    [indices],
  )

  const onUpdate = useCallback(
    (val: any) => {
      smartfieldStore.update({ [uid]: val })
      form?.setFieldValue(uid, val)
    },
    [form, uid],
  )

  useEffect(() => {
    if (contact?.id) {
      const fields = parseDataFields(dataFields)
      setIndices(new Array(fields.length))
      setError(undefined)
      setSelects([])
    }
  }, [contact?.id, dataFields])

  useEffect(() => {
    if (indices?.length) {
      try {
        setSelects(parseRequiredSelects(contact, dataFields, indices, onUpdate))
        setError(undefined)
      } catch (er) {
        setError(ERRORS.UNEXPECTED_ERROR)
      }
    }
  }, [contact, indices, dataFields, onUpdate])

  return useMemo(
    () => ({
      selects,
      error,
      onSelectIndice,
    }),
    [selects, error, onSelectIndice],
  )
}
