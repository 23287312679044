const isDev = process.env.NODE_ENV === 'development'
const ENV = process.env.REACT_APP_ENV || 'dev'
const getPulse360SubDomain = () => {
  switch (ENV) {
    case 'dev':
    case 'development':
      return 'app.dev'
    case 'staging':
      return 'app.staging'
    default:
      return 'app'
  }
}

const getPulse360Domain = (canBeLocal = false) => {
  if (isDev && canBeLocal) {
    return `http://localhost:9001`
  }

  return `https://${getPulse360SubDomain()}.pulse360.com`
}

exports.getPulse360Domain = getPulse360Domain
module.exports.getPulse360Domain = getPulse360Domain
