import { extension } from '@remirror/core'
import { TableOptions } from '@remirror/extension-tables'
import { ApplySchemaAttributes, ExtensionPriority, Handler, NodeSpecOverride, NodeWithPosition } from 'remirror'
import { TableSchemaSpec } from 'remirror/dist-types/extensions'
import { TableCustomExtension } from '../TableCustom.extension'
import { createTableNodeSchema } from './TableCustomForExport.utils'
import { TableRowCustomForExportExtension } from './TableRowCustomForExport.extension'

export interface TableCustomOptions extends TableOptions {
  onClick: Handler<(event: MouseEvent, nodeWithPosition: NodeWithPosition) => boolean | undefined | void>
}

@extension<TableCustomOptions>({
  defaultOptions: {
    resizable: true,
    resizeableOptions: {},
  },
  handlerKeys: ['onClick'],
  defaultPriority: ExtensionPriority.Default,
})
// TODO: This extension shall only be enabled for Export. Doing this as a workaround, until we can implement this behavior in the main `TableCustomExtension` by props.
export class TableCustomForExportExtension extends TableCustomExtension {
  /**
   * The last known good state that didn't need fixing. This helps make the fix
   * command more effective.
   */

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): TableSchemaSpec {
    return createTableNodeSchema(extra, override).table
  }

  /**
   * We're overriding the internal `TableRowExtension` and `TableCellExtension` to enable them using our custom `createTableNodeSchema` function.
   * Using this so `verticalAlign` is inside the TableCell Attributes.
   */
  createExtensions() {
    return [new TableRowCustomForExportExtension({ priority: ExtensionPriority.Low })]
  }
}
