/* eslint-disable @typescript-eslint/ban-ts-comment */
import { SmartFieldIntegrationGenericBuilder } from '../genericData/generic.builder'
import { ProviderSmartFieldsBuilderProps } from '../provider.interfaces'
import { contactFields } from './wealthbox.constants'

export const WealthboxBuilder: React.FC<ProviderSmartFieldsBuilderProps> = (
  props,
) => (
  <SmartFieldIntegrationGenericBuilder
    {...props}
    contactFields={contactFields}
    provider="wealthbox-connector"
    providerTitle="Wealthbox"
  />
)
