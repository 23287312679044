type Style = 'decimal' | 'percent' | 'currency'

export const formatNum = (num: number, style: Style = 'decimal', digits = 0) => {
  // for percentages, expects numbers in range 0 - 1
  if (style === 'percent') {
    num /= 100
  }

  const options: Intl.NumberFormatOptions = {
    style,
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
    currency: undefined,
  }

  if (style === 'currency') {
    options.currency = 'USD'
  }

  return num.toLocaleString('en-US', options)
}
