import { TableView } from '@remirror/pm/tables'
import { ApplySchemaAttributes, ExtensionTag, NodeSpecOverride, ProsemirrorNode } from 'remirror'
import { TableSchemaSpec } from 'remirror/dist-types/extensions'

import {
  cellAround,
  checkIfNewCellsWereAdded,
  getAllTableCellsAttributes,
  getTableAttributes,
  goToNextCell,
  isInTable,
  moveCellForward,
  selectionCell,
  setAttr,
  setCellAttr,
  setCellAttrByResolvedPos,
  setSelectedTableAndChildAttributes,
  setSelectedTableAttribute,
  setSelectedTableCellAttribute,
} from '../TableCustom.utils'

// INFO: exports the same functions not modified in original util
export {
  isInTable,
  selectionCell,
  cellAround,
  moveCellForward,
  goToNextCell,
  setCellAttr,
  setCellAttrByResolvedPos,
  setAttr,
  checkIfNewCellsWereAdded,
  getTableAttributes,
  getAllTableCellsAttributes,
  setSelectedTableAttribute,
  setSelectedTableCellAttribute,
  setSelectedTableAndChildAttributes,
}

/**
 * This function creates the base for the tableNode ProseMirror specs.
 */
export function createTableNodeSchema(
  extra: ApplySchemaAttributes,
  override: NodeSpecOverride,
): Record<'table' | 'tableRow' | 'tableCell' | 'tableHeaderCell', TableSchemaSpec> {
  const cellAttrs = {
    ...extra.defaults(),
    colspan: { default: 1 },
    rowspan: { default: 1 },
    colwidth: { default: null },
    background: { default: null },
    verticalAlign: { default: null },
    textAlign: { default: null },
    border: { default: null },
  }

  const headerCellAttrs = {
    ...cellAttrs,
    background: { default: '#8b929e' },
  }

  const tableAttrs = {
    ...extra.defaults(),
    border: { default: null },
    zebra: { default: null },
    width: { default: null },
  }

  const tableRowAttrs = {
    ...extra.defaults(),
    background: { default: null },
  }

  return {
    table: {
      isolating: true,
      ...override,
      attrs: tableAttrs,
      content: 'tableRow+',
      tableRole: 'table',
      parseDOM: [
        {
          tag: 'table',
          getAttrs: (dom) => ({ ...extra.parse(dom), ...getTableAttrs(dom as HTMLElement) }),
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM(node) {
        return ['table', { ...extra.dom(node), ...setTableAttrs(node, 25) }, ['tbody', 0]]
      },
    },

    tableRow: {
      ...override,
      attrs: tableRowAttrs,
      content: '(tableCell | tableHeaderCell)*',
      tableRole: 'row',
      parseDOM: [
        { tag: 'tr', getAttrs: (dom) => ({ ...extra.parse(dom), ...getRowAttrs(dom as HTMLElement) }) },
        ...(override.parseDOM ?? []),
      ],
      toDOM(node) {
        return ['tr', { ...extra.dom(node), ...setRowAttrs(node) }, 0]
      },
    },

    tableCell: {
      isolating: true,
      content: `${ExtensionTag.Block}+`,
      ...override,
      attrs: cellAttrs,
      tableRole: 'cell',
      parseDOM: [
        {
          tag: 'td',
          getAttrs: (dom) => ({ ...extra.parse(dom), ...getCellAttrs(dom as HTMLElement) }),
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM(node) {
        return ['td', { ...extra.dom(node), ...setCellAttrs(node) }, 0]
      },
    },

    tableHeaderCell: {
      isolating: true,
      content: `${ExtensionTag.Block}+`,
      ...override,
      attrs: headerCellAttrs,
      tableRole: 'header_cell',
      parseDOM: [
        {
          tag: 'th',
          getAttrs: (dom) => ({ ...extra.parse(dom), ...getCellAttrs(dom as HTMLElement) }),
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM(node) {
        return ['th', { ...extra.dom(node), ...setCellAttrs(node) }, 0]
      },
    },
  }
}

function getBorder(dom: HTMLElement) {
  const borderAttr = dom.getAttribute('data-border')

  if (borderAttr) {
    return borderAttr
  }

  const borderClass = dom.classList.contains('white-borders') ? 'none' : null
  if (borderClass) {
    return borderClass
  }

  const borderStyle = dom.style.border || null

  return borderStyle
}

function getTableAttrs(dom: HTMLElement) {
  const border = getBorder(dom)
  const zebra = dom.getAttribute('data-zebra')
  const width = dom.getAttribute('data-width')

  return {
    border: border,
    zebra: zebra || null,
    width: width || null,
  }
}

function setTableAttrs(node: ProsemirrorNode, cellMinWidth: number) {
  const attrs: Record<string, string> = {}

  if (node.attrs.border) {
    attrs.style = `${attrs.style ?? ''}border-style: ${node.attrs.border as string};`
    attrs['data-border'] = node.attrs.border
  }

  const nodeView = new TableView(node, cellMinWidth)

  const tableWidth = nodeView.table.style.width

  if (tableWidth) {
    attrs.style = `${attrs.style ?? ''}width: ${tableWidth};`
    attrs['data-width'] = tableWidth.replace('px', '')
  }

  return attrs
}

function getRowAttrs(dom: HTMLElement) {
  const background = dom.getAttribute('data-background')
  return {
    background: background || null,
  }
}

function setRowAttrs(node: ProsemirrorNode) {
  const attrs: Record<string, string> = {}

  if (node.attrs.background) {
    attrs.style = `${attrs.style ?? ''}background: ${node.attrs.background as string};`
    attrs['data-background'] = node.attrs.background
  }

  return attrs
}

function getCellWidthAttr(colwidthAttr: string | null, widthStyle: string | null, colspan: number) {
  if (colwidthAttr && /^\d+(,\d+)*$/.test(colwidthAttr)) {
    const widths = colwidthAttr.split(',').map((s) => Number(s))

    if (widths.length === colspan) {
      return widths
    }
  }

  if (widthStyle && /^\d*\.?\d+px/.test(widthStyle)) {
    return [Number(widthStyle.replace('px', ''))]
  }

  return null
}

function getCellAttrs(dom: HTMLElement) {
  const widthAttr = dom.getAttribute('data-colwidth')
  const widthStyle = dom.style.width
  const colspan = Number(dom.getAttribute('colspan') ?? 1)
  const backgroundColor = dom.getAttribute('data-background-color')
  const verticalAlign = dom.getAttribute('data-vertical-align')
  const textAlign = dom.getAttribute('data-text-align') || dom.style.textAlign

  const table = dom.closest('table')
  const border = (table && getBorder(table)) || null

  const colwidth = getCellWidthAttr(widthAttr, widthStyle, colspan)
  return {
    colspan,
    rowspan: Number(dom.getAttribute('rowspan') ?? 1),
    colwidth: colwidth,
    background: backgroundColor || dom.style.backgroundColor || null,
    verticalAlign: verticalAlign || null,
    textAlign: textAlign || null,
    border: border || null,
  }
}

// FIXME: copied from `table-utils`.ts
function setCellAttrs(node: ProsemirrorNode) {
  const attrs: Record<string, string> = {}

  if (node.attrs.colspan !== 1) {
    attrs.colspan = node.attrs.colspan
  }

  if (node.attrs.rowspan !== 1) {
    attrs.rowspan = node.attrs.rowspan
  }

  if (node.attrs.colwidth) {
    attrs.style = `${attrs.style ?? ''}width: ${node.attrs.colwidth as string}px;`
    attrs['data-colwidth'] = node.attrs.colwidth.join(',')
  }

  if (node.attrs.background) {
    attrs.style = `${attrs.style ?? ''}background-color: ${node.attrs.background as string};`
    attrs['data-background-color'] = node.attrs.background
  }

  if (node.attrs.verticalAlign) {
    attrs.style = `${attrs.style ?? ''}vertical-align: ${node.attrs.verticalAlign as string};`
    attrs['data-vertical-align'] = node.attrs.verticalAlign
  }

  if (node.attrs.textAlign) {
    attrs.style = `${attrs.style ?? ''}text-align: ${node.attrs.textAlign as string};`
    attrs['data-text-align'] = node.attrs.textAlign
  }

  if (node.attrs.border) {
    attrs.style = `${attrs.style ?? ''}border-style: ${node.attrs.border as string};`
    attrs['data-border'] = node.attrs.border
  }

  return attrs
}
