import { SmartFieldConfig } from 'components/drawers/Smartfields/types/data'
import {
  HolistiplanFormValues,
  SmartFieldFormValues,
  SmartFieldIntegrationFormValues,
} from 'components/drawers/Smartfields/types/form'
import { ConfigParser, FormParser } from '../provider.interfaces'

export const preciseFPParser: FormParser = (values, config) => {
  return config as SmartFieldConfig
}

export const preciseFPConfigParser: ConfigParser = (values, config) => {
  return values as SmartFieldFormValues<
    SmartFieldIntegrationFormValues<HolistiplanFormValues>
  >
}
