import {
  captureEvent,
  captureExceptionSilently,
  updateHelpHero,
} from 'helpers/sentry'
import {
  action,
  computed,
  makeObservable,
  observable,
  observe,
  runInAction,
} from 'mobx'
import moment from 'moment'
import { AppointmentData } from 'stores/appointment/appointment.data'
import { msg } from 'stores/msg'
import { EntityId } from 'types/entity.interface'
import {
  Appointment,
  AppointmentInput,
  BulkNoteFieldsFragment,
  Client,
  Note,
  NoteCreationInput,
  NoteFieldsFragment,
  NoteInput,
  ScratchObject,
  Transcription,
} from 'types/graphql'
import { composeDateTime } from '../../helpers/time'
import appointmentService from '../services/appointment.service'
import noteService from '../services/note.service'
import pulseRecorderService from '../services/pulseRecorder.service'
import scratchService from '../services/scratch.service'

class Appointments {
  ApptID: EntityId | null
  appointment: Appointment | null
  globalAppt: Appointment
  transferedAppt = undefined
  appointments: Appointment[] = []
  notes: (Note | NoteFieldsFragment | BulkNoteFieldsFragment)[] = []
  scratchNotes: ScratchObject[] = []
  transcriptions: Transcription[] = []

  get isGlobal() {
    if (this.globalAppt) {
      return this.globalAppt.global
    }
    return false
  }

  constructor() {
    window.moment = moment

    makeObservable(this, {
      ApptID: observable.ref,
      appointment: observable.ref,
      globalAppt: observable.ref,
      transferedAppt: observable,
      appointments: observable.ref,
      notes: observable,
      scratchNotes: observable,
      transcriptions: observable,
      isGlobal: computed,
      loadApptsByClientID: action.bound,
      loadAll: action.bound,
      loadByDate: action.bound,
      createAppt: action.bound,
      reloadCurrentAppointment: action.bound,
      unloadAppointments: action.bound,
      setAppointment: action.bound,
      updateAppointmentLocally: action.bound,
      unloadAppt: action.bound,
      mirrorNotes: action.bound,
    })
    observe(
      this,
      'appointments',
      (c) =>
        Array.isArray(c.newValue) &&
        updateHelpHero({ numberOfAppointments: c.newValue.length }),
      true,
    )

    observe(
      this,
      'notes',
      (c) =>
        Array.isArray(c.newValue) &&
        updateHelpHero({ numberOfNotes: c.newValue.length }),
      true,
    )

    observe(
      this,
      'scratchNotes',
      (c) =>
        Array.isArray(c.newValue) &&
        updateHelpHero({ numberOfScratchNotes: c.newValue.length }),
      true,
    )
  }

  /**
   * Load specific client information
   */
  unloadAppointments = () => {
    // @ts-ignore
    this.globalAppt = undefined
    // @ts-ignore
    this.appointment = undefined
    this.appointments = []
    this.scratchNotes = []
    this.notes = []
    this.transcriptions = []
  }

  /**
   * @param param0
   */
  setAppointment = ({
    apptId,
    notes,
    appointment,
    scratches,
    transcriptions,
  }: AppointmentData) => {
    this.ApptID = apptId as number
    this.appointment = appointment
    this.scratchNotes = scratches
    this.notes = notes
    this.transcriptions = transcriptions
  }

  /**
   * Updates Appointment store locally. Useful if any of these props change via a new mutation.
   * @param param0
   */
  updateAppointmentLocally = ({
    apptId,
    notes,
    appointment,
    scratches,
    transcriptions,
  }: Partial<AppointmentData>) => {
    this.ApptID = (apptId as number) || this.ApptID
    this.appointment = appointment || this.appointment
    this.scratchNotes = scratches || this.scratchNotes
    this.notes = notes || this.notes
    this.transcriptions = transcriptions || this.transcriptions
  }

  /**
   *
   */
  async loadApptsByClientID(clientId: EntityId, clear = true) {
    if (!clientId && !global.data.clients.client) {
      return
    }

    if (clear) {
      this.scratchNotes = []
      this.notes = []
      this.transcriptions = []
    }

    // @ts-ignore
    global.app.loading = 'Loading appointments..'

    let appointments: Appointment[] = []

    try {
      appointments = await appointmentService.getAppointmentByClientId(clientId)
    } catch (error) {
      const errorMsg = `Ups! Seems like there was an error fetching this client appointments.
      We've been notified and we'll take a look into the issue. Meanwhile, try refreshing the page!`
      msg.error(errorMsg, undefined)

      captureExceptionSilently(error, {
        message: 'loadApptsByClientID',
        data: { clientId },
      })
    }

    this.globalAppt = appointments.find((a) => a?.global)!
    this.appointments = appointments.filter((a) => !a?.global)
    // @ts-ignore
    global.app.loading = false

    return appointments
  }

  /**
   *
   */
  loadGlobalAppt = async () => {
    // @ts-ignore
    global.app.loading = 'Loading Quick Notes..'
    if (!this.globalAppt) {
      msg.error(
        `Ups! Seems we failed bringing that client's data up!.
      We've been notified. Meanwhile, we'll take you somewhere safe!`,
        undefined,
      )
      captureEvent({
        message: 'loadGlobalAppt',
        data: { payload: global.router.params },
      })
      // global.router.goto(`/notes/client`)
    } else {
      await this.loadAppt(+this.globalAppt.id)
    }
    // @ts-ignore
    global.app.loading = false
  }

  /**
   * @param {*} apptId
   */
  loadAppt = async (apptId: EntityId | null = this.ApptID) => {
    if (!apptId) {
      runInAction(() => {
        this.scratchNotes = []
        this.appointment = null
        this.ApptID = null
        this.notes = []
        this.transcriptions = []
      })

      return []
    }

    try {
      // @ts-ignore
      global.app.wait = 'Fetching your notes'

      this.ApptID = apptId
      this.appointment = this.getAppt(apptId)

      const notes = await noteService.getNotesByAppointmentId(apptId)
      this.ApptID = apptId
      this.notes = notes

      const scratches = await scratchService.getScratches({ apptId })
      this.scratchNotes = scratches || []

      const transcriptions =
        await pulseRecorderService.getTranscriptionsByEventId(apptId)
      this.transcriptions = transcriptions || []

      return this.appointment
    } catch (error) {
      msg.error(['notes and handwritten notes', 'fetching'], undefined)

      captureExceptionSilently(error, { message: 'loadAppt', data: { apptId } })
    } finally {
      // @ts-ignore
      global.app.wait = false
    }
  }

  async unloadAppt() {
    return this.loadAppt(null)
  }

  /**
   * @param {*}
   */
  async loadAll(clientId: EntityId) {
    try {
      // @ts-ignore
      global.app.wait = 'Fetching all of your notes'

      this.appointment = null
      this.ApptID = null
      const notes = await noteService.getAllNotesByClientId(clientId)
      this.notes = notes

      return notes
    } catch (error) {
      if (error instanceof Error) {
        msg.error(error.message, undefined)
      }

      captureExceptionSilently(error, {
        message: 'loadAll',
        data: { clientId },
      })
    } finally {
      // @ts-ignore
      global.app.wait = false
    }
  }

  // FIXME: dead code?
  async loadRelatedScratchNotes() {
    const appts = this.notes.map((note) => note.appointmentId)
    if (!Array.isArray(appts) || (Array.isArray(appts) && !appts.length)) {
      return
    }
    // @ts-ignore
    global.app.loading = true
    try {
      const payload = {
        appts: Array.from(new Set(appts)),
      }
      const scratches = await scratchService.getScratchesOfAppts(payload)
      this.scratchNotes = scratches

      return this.scratchNotes
    } catch (error) {
      captureExceptionSilently(error, {
        message: 'loadRelatedScratchNotes',
        data: { appts },
      })
    } finally {
      // @ts-ignore
      global.app.loading = false
    }
  }

  /**
   * @param {*}
   */
  async loadByDate(date: moment.Moment) {
    // @ts-ignore
    global.app.loading = 'Loading appointments..'
    const start = moment(date).startOf('date').toJSON()
    const end = moment(date).endOf('date').toJSON()

    try {
      return await appointmentService.getAppointmentsInRange(start, end)
    } catch (error) {
      if (error instanceof Error) {
        msg.error(error.message, undefined)
      }
      captureExceptionSilently(error, {
        message: 'loadByDate',
        data: { start, end },
      })
    } finally {
      // @ts-ignore
      global.app.loading = false
    }
  }

  /**
   * @param {*} clientId
   * @param {*} date
   * @param {*} time
   */
  async createAppt(
    {
      client,
      date,
      time,
      subject,
    }: {
      client: Client
      date: moment.Moment
      time: moment.Moment
      subject: string
    },
    moveRoute = true,
  ) {
    const event = await this._createAppt({ client, date, time, subject })

    if (!event) {
      return
    }

    this.appointments = [event, ...this.appointments].sort(
      (a, b) => +new Date(b.date) - +new Date(a.date),
    )
    if (moveRoute) {
      await this.loadAppt(+event.id)
      global.router.gotoEvent(client.id, event.id)
    }

    global.data.topics.filterSummaries([], null, null)

    return event
  }

  async editEvent(
    id: EntityId,
    {
      client,
      date,
      time,
      subject,
    }: {
      client: Client
      date: moment.Moment
      time: moment.Moment
      subject: string
    },
    moveRoute = true,
  ) {
    await this._updateAppointment(
      id,
      { clientId: client.id, date, time, subject },
      false,
    )

    const event = this.appointments.find((a) => a.id === id)

    if (!event) {
      return
    }

    Object.assign(event, { clientId: client.id, date, time, subject })

    this.appointments.sort((a, b) => +new Date(b.date) - +new Date(a.date))
    if (moveRoute) {
      await this.loadAppt(+event.id)
      global.router.gotoEvent(client.id, event.id)
    }
    // @ts-ignore
    global.data.topics.filterSummaries([])

    return event
  }

  async getAppointmentByClientId(clientId: EntityId) {
    try {
      const appointments =
        await appointmentService.getAppointmentByClientId(clientId)

      return appointments
    } catch (error) {
      const errorMsg = `Ups! Seems like there was an error fetching this client appointments.
      We've been notified and we'll take a look into the issue. Meanwhile, try refreshing the page!`
      msg.error(errorMsg, undefined)

      captureExceptionSilently(error, {
        message: 'getAppointmentByClientId',
        data: { clientId },
      })
    }
  }

  /**
   * Delete Note
   */
  deleteNote = async (
    note: Note,
    tasks: number[] = [],
    workflows: number[] = [],
    deleteOnProvider = false,
    deleteMirrored: boolean = false,
  ) => {
    // @ts-ignore
    const deletedNotes = await global.notes.deleteNote(
      note.id,
      tasks,
      workflows,
      deleteOnProvider,
      deleteMirrored,
    )

    const deletedNotesIds = deletedNotes!.map((n) => n!.id)

    if (!deletedNotesIds || !deletedNotesIds.length) {
      return
    }

    this.notes = this.notes.filter((n) => !deletedNotesIds.includes(n.id))
    msg.success('Note deleted!')
    return deletedNotesIds
  }

  removeMirroredNote = async (originalNote: Note) => {
    const { id } = originalNote
    try {
      const removedMirrorNotes = await global.notes.removeMirroredNote(
        id,
        this.ApptID!,
      )

      if (!removedMirrorNotes || !removedMirrorNotes.length) {
        return
      }

      const removedMirroredNotesIds = removedMirrorNotes.map((n) => n!.id)

      this.notes = this.notes.filter(
        (n) => !removedMirroredNotesIds.includes(n.id),
      )

      msg.success('Note removed!')
    } catch (error) {
      console.error('failed')
    }
  }

  transferMainMirroredNote = async (note: Note, newEventId: EntityId) => {
    const accepted = await noteService.transferMainMirroredNote({
      noteId: note.id,
      newEventId,
    })
    if (accepted) {
      this.notes = this.notes.filter((n) => n.id !== note.id)
    }
  }

  /**
   * Update Note
   */
  updateNote = async (id: EntityId, fields: NoteInput) => {
    // @ts-ignore
    await global.notes.updateNote(id, fields)
    this.updateNoteLocally(id, fields)
  }

  updateNoteLocally = (id: EntityId, fields?: NoteInput) => {
    // @ts-ignore
    this.notes = global.notes.updateNoteListItemProps(this.notes, id, {
      ...fields,
      isMirrored: false,
    })
  }

  /**
   * Update Note
   */
  reorderNotes = async (newOrderedIds: EntityId[], localOnly = false) => {
    const backupOrder = this.notes.map(({ id }) => id)
    // @ts-ignore
    this.notes = global.notes.orderNotesById(newOrderedIds, this.notes)
    if (localOnly) {
      return
    }

    try {
      // @ts-ignore
      await global.notes.reorderNotes(global.data.appt.ApptID, newOrderedIds)
    } catch (error) {
      // @ts-ignore
      this.notes = global.notes.orderNotesById(backupOrder, this.notes)
      msg.error(['note', 'ordering'], undefined)
      captureExceptionSilently(error, { message: 'reorderNotes', data: {} })
    }
  }

  /**
   * Add Note
   */
  bulkAddNote = async (notesData: NoteCreationInput[], showSuccess = true) => {
    try {
      // @ts-ignore
      await global.notes.bulkAddNote(notesData)

      showSuccess && msg.success('Notes Added')
    } catch (error) {
      captureExceptionSilently(error, { message: 'bulkAddNote', data: {} })
    }
  }

  bulkAddNoteAndUpdateLocally = async (
    notesData: NoteCreationInput[],
    showSuccess = true,
  ) => {
    try {
      // @ts-ignore
      const notes = await global.notes.bulkAddNote(notesData)
      if (Array.isArray(notes) && notes.length) {
        if (notes[0]?.order) {
          // @ts-ignore
          this.notes = [...this.notes, ...notes].sort(
            (a, b) => a.order! - b.order! || Number(a.id) - Number(b.id),
          )
        } else {
          this.notes = [...this.notes, ...notes]
        }
      }
      showSuccess && msg.success('Notes Added')
      return notes
    } catch (error) {
      captureExceptionSilently(error, {
        message: 'bulkAddNoteAndOrder',
        data: {},
      })
    }
  }

  /**
   * Add Note
   */
  addNote = async (noteData: NoteCreationInput) => {
    try {
      // @ts-ignore
      const note = await global.notes.addNote(noteData)
      if (note?.order) {
        // @ts-ignore
        this.notes = [...this.notes, note].sort(
          (a, b) => a.order! - b.order! || Number(a.id) - Number(b.id),
        )
      } else {
        this.notes = [...this.notes, note]
      }

      return note
    } catch (error) {
      // @ts-ignore
      global.app.loading = false
      msg.error(['note', 'adding'], undefined)

      captureExceptionSilently(error, { message: 'addNote', data: {} })
    }
  }

  /**
   * Creates a new note based from a mirrored note.
   * It adds the new note to the notes list and removes the original note reference (which was previously the mirrored one).
   * @param noteData
   * @param originalNoteId
   * @returns
   */
  createNoteFromMirrored = async (
    noteData: NoteCreationInput,
    originalNoteId: number,
  ) => {
    try {
      const originalNote = this.notes.find((note) => note.id === originalNoteId)

      if (!originalNote) {
        return
      }

      const note = await global.notes.createNoteFromMirrored(
        { ...noteData, order: originalNote.order },
        originalNoteId,
      )
      if (note?.order !== undefined && note?.order !== null) {
        // @ts-ignore
        this.notes = [...this.notes, note]
          .filter((n) => n.id !== originalNoteId)
          .sort((a, b) => a.order! - b.order! || Number(a.id) - Number(b.id))
      } else {
        this.notes = [...this.notes, note].filter(
          (n) => n.id !== originalNoteId,
        )
      }

      return note
    } catch (error) {
      // @ts-ignore
      global.app.loading = false
      msg.error(['note', 'adding'], undefined)

      captureExceptionSilently(error, {
        message: 'createNoteFromMirrored',
        data: {},
      })
    }
  }

  mirrorNotes = async (
    notesData: { id: number; templateIncludedId: number | null }[],
  ) => {
    try {
      const fromOrder =
        Math.max(-1, ...this.notes.map((note) => note.order ?? 0)) + 1
      const notes = await noteService.mirrorNotes({
        notesData,
        eventId: this.ApptID!,
        fromOrder,
      })
      this.notes = [...this.notes, ...notes]
      return notes
    } catch (err) {
      console.error('failed!', err)
    }
  }

  /**
   * Transfer data from one appointment (or no appointment) to another.
   * The appointment can be of different clients.
   * @param {Array} notes an array of notes or ids
   * @param {String|Number} apptId
   * @param {String|Number} clientId
   * @returns {Promise<Boolean>}
   */
  transfer = async ({
    model,
    items,
    apptId,
    clientId,
  }: {
    model: 'notes' | 'scratchNotes'
    items: (string | { id: string })[]
    apptId: EntityId
    clientId: EntityId
  }) => {
    // @ts-ignore
    const accepted = await global.notes.transfer(model, items, apptId, clientId)
    if (!accepted) {
      return
    }
    const idsToRemove = items.map((item) =>
      typeof item === 'string' || typeof item === 'number' ? item : item.id,
    )

    if (model === 'notes') {
      this.notes = this.notes.filter(
        (i) => !idsToRemove.some((id) => String(i.id) === String(id)),
      )
    } else {
      this.scratchNotes = this.scratchNotes.filter(
        (i) => !idsToRemove.some((id) => String(i.id) === String(id)),
      )
    }

    return accepted
  }

  /**
   * Find an appointment
   */
  getAppt = (id = this.ApptID) => {
    const appointment = this.appointments
      .concat(this.globalAppt)
      .find((a) => a.id === Number(id))
    if (!appointment) {
      throw new Error('the appointment was not found')
    }

    return appointment
  }

  /**
   *
   */
  getApptDateMoment = (AppointmentID = this.ApptID) => {
    const appt = this.getAppt(AppointmentID)
    if (!appt) {
      return moment()
    }

    const date = moment(appt.date)
    const time = moment(appt.time)

    return date.set({
      minute: time.get('minute'),
      hour: time.get('hour'),
    })
  }

  /* ---------- private ---------- */

  _updateAppointment = async (
    id: EntityId,
    fields: AppointmentInput,
    isGlobal = this.isGlobal!,
  ) => {
    const payload = { appointment: fields, id, global: isGlobal }
    try {
      return await appointmentService.updateAppointment(payload)
    } catch (error) {
      msg.error(['appointment', 'updating'], undefined)
      captureExceptionSilently(error, {
        message: '_updateAppointment',
        data: payload,
      })
    }
  }

  /**
   *
   */
  async _createAppt({
    client,
    date,
    time,
    subject,
  }: {
    client: Client
    date: moment.Moment
    time: moment.Moment
    subject?: string
  }) {
    const payload = {
      appointment: {
        clientId: client.id,
        date: date.toISOString(),
        time: time.toISOString(),
        subject,
      },
    }

    try {
      const appointment = await appointmentService.createAppointment(payload)
      return appointment
    } catch (error) {
      msg.error(['appointment', 'creating'], undefined)
      captureExceptionSilently(error, {
        message: '_createAppointment',
        data: payload,
      })
    }
  }

  updateInMeetingNotes = async ({
    id,
    comments,
    html,
    content,
    sync = false,
  }: {
    id: EntityId
    comments?: string
    sync: boolean
    html: string
    content: string
  }) => {
    const payload = {
      inMeetingNotes: { appointmentId: id, comments, sync, html, content },
    }

    try {
      await appointmentService.updateInMeetingNotes(payload)

      if (this.ApptID === id) {
        this.appointment = {
          ...this.appointment!,
          html,
          content,
          ...(comments && { comments }),
        }
      }
      const index = this.appointments.findIndex((a) => a.id === Number(id))
      if (index !== -1) {
        this.appointments[index] = {
          ...this.appointments[index],
          html,
          content,
          ...(comments && { comments }),
        }
      } else if (id === this.globalAppt.id) {
        this.globalAppt = {
          ...this.globalAppt,
          html,
          content,
          ...(comments && { comments }),
        }
      }

      return true
    } catch (error) {
      msg.error(['appointment', 'updating'], undefined)
      captureExceptionSilently(error, {
        message: 'updateInMeetingNotes',
        data: payload,
      })
    }
  }

  /**
   *
   * ALL_NOTES: load all the notes (preferably at the same time than appts)
   * AGENDA: load appts and next appt notes and sketches
   * SUMMARY: load appts and last appt notes and sketches
   * GLOBAL: load appts and global appt notes and sketches
   * EVENTS: load appts, nothing else
   */
  loadAccordingToFilter = async (
    filter = 'global',
    clientId: EntityId,
    appointmentId?: any,
  ) => {
    const apptId = appointmentId ?? global.router.params?.apptId
    this.ApptID = apptId
    this.appointment = null

    let result
    if (filter === 'global') {
      await this.loadGlobalAppt()
    } else if (filter === 'agenda') {
      const nextId = this.getNearestAppointmentId(this.appointments, 'agenda')
      result = await this.loadAppt(apptId || nextId || this.globalAppt.id)
      this.showNoNearestFoundError(!apptId && !nextId, 'next')
    } else if (filter === 'summary') {
      const lastId = this.getNearestAppointmentId(this.appointments, 'summary')
      result = await this.loadAppt(apptId || lastId || this.globalAppt.id)
      this.showNoNearestFoundError(!apptId && !lastId, 'last')
    } else if (filter === 'all') {
      result = await this.loadAll(clientId)
    } else if (filter === 'appts') {
      result = await this.loadAppt(
        apptId || this.getNearestAppointmentId(this.appointments, 'appts'),
      )
    } else if (filter === 'history' && apptId === -1) {
      result = await this.loadAll(clientId)
    }

    return result
  }

  showNoNearestFoundError(showConditions: boolean, modDate: string) {
    showConditions &&
      msg.warning(
        `Quick Notes selected`,
        `We couldn't find your ${modDate} meeting, create one here or on your CRM`,
        10,
      )
  }

  getNearestAppointmentId = (appointments: Appointment[], mode: string) => {
    const differences = appointments.map((appt) => ({
      diff: composeDateTime(appt.date, appt.time).diff(moment()),
      id: appt.id,
    }))

    if (!differences.length) {
      return null
    }

    if (mode === 'summary') {
      const previousAppointments = differences
        .sort((a, b) => a.diff - b.diff)
        .filter((e) => e.diff < 0)
      return previousAppointments.length
        ? previousAppointments[previousAppointments.length - 1].id
        : null // closest prev appt
    } else if (mode === 'agenda') {
      const nextAppointments = differences
        .sort((a, b) => a.diff - b.diff)
        .filter((e) => e.diff > 0)
      return nextAppointments.length ? nextAppointments[0].id : null // closest next appt
    } else {
      const allAppointments = differences.sort(
        (a, b) => Math.abs(a.diff) - Math.abs(b.diff),
      )
      return allAppointments.length ? allAppointments[0].id : null // closest appt
    }
  }

  // FIXME: we are not using cache anymore BUT we need to test if every property is unloaded
  clearCache = () => {
    this.appointments = []
    this.appointment = null
    this.ApptID = null
  }

  reloadCurrentAppointment = async () => {
    if (
      this.ApptID &&
      this.appointments &&
      Array.isArray(this.appointments) &&
      this.appointment
    ) {
      try {
        const reloadedAppointment = await appointmentService.getAppointmentById(
          this.ApptID,
        )
        const reloadedAppointmentIndex = this.appointments.findIndex(
          (appointment) => appointment.id === Number(this.ApptID),
        )
        if (reloadedAppointmentIndex !== -1) {
          this.appointments[reloadedAppointmentIndex] = reloadedAppointment
        } else if (!this.appointments.length) {
          this.appointments.push(reloadedAppointment)
        }
        this.appointment = reloadedAppointment
      } catch (err) {
        captureExceptionSilently(err, {
          message: 'reloadCurrentAppointment',
          data: { appointmentId: this.ApptID },
        })
        msg.error('Error reloading appointment', undefined)
      }
    }
  }
}

export default Appointments
