import { SmartFieldConfig, SmartFieldTextConfig } from '../types/data'
import { SmartFieldFormValues, SmartFieldTextFormValues } from '../types/form'

export const textFormParser = (
  values: SmartFieldFormValues,
  config: Partial<SmartFieldConfig<SmartFieldTextConfig>>,
): SmartFieldConfig => {
  return config as SmartFieldConfig<SmartFieldTextConfig>
}

export const textConfigParser = (config: SmartFieldConfig): SmartFieldTextFormValues => {
  return {}
}
