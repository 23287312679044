import * as apiService from 'lib/apollo/client'
import { transformNote, transformNotes } from 'stores/smartfields/transforms'
import {
  AddSummaryDocument,
  AddSummaryMutation,
  AddSummaryMutationVariables,
  DeleteSummariesDocument,
  DeleteSummariesMutation,
  DeleteSummariesMutationVariables,
  SummariesDocument,
  SummariesQuery,
  SummariesQueryVariables,
  UpdateSummaryDocument,
  UpdateSummaryMutation,
  UpdateSummaryMutationVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

class SummaryService {
  async getSummaries() {
    const { summaries: resp } = await apiService.query<SummariesQuery, SummariesQueryVariables>(
      SummariesDocument,
      {},
      { fetchPolicy: 'no-cache' },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return transformNotes(resp.summaries)
  }

  async createSummary(payload: AddSummaryMutationVariables) {
    const { addSummary: resp } = await apiService.mutate<AddSummaryMutation, AddSummaryMutationVariables>(
      AddSummaryDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return transformNote(resp)
  }

  async updateSummary(payload: UpdateSummaryMutationVariables) {
    const { updateSummary: resp } = await apiService.mutate<UpdateSummaryMutation, UpdateSummaryMutationVariables>(
      UpdateSummaryDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return transformNote(resp)
  }

  async deleteSummaries(payload: DeleteSummariesMutationVariables) {
    const { deleteSummaries: resp } = await apiService.mutate<
      DeleteSummariesMutation,
      DeleteSummariesMutationVariables
    >(DeleteSummariesDocument, payload)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.accepted
  }
}

export default new SummaryService()
