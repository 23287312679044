/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Form, FormItemProps, Space } from 'antd'
import { ImageIcon } from 'lib/remirror/components/icons/ImageIcon'
import styled from 'styled-components'

export const Label = styled.span`
  font-weight: 600;
  font-family: Lato;
`

export const Description = styled.div`
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
`

export const SelectOptionIcon = styled(ImageIcon)`
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 4px;
`

interface ItemProps extends FormItemProps {
  description: string
  children: React.ReactNode
}

export const FormItem: React.FC<ItemProps> = ({ label, description, children, ...formProps }) => (
  <Form.Item {...formProps}>
    <Space direction="vertical" style={{ width: '100%' }} size={0}>
      <Label>{label}</Label>
      <Description>{description}</Description>
      {children}
    </Space>
  </Form.Item>
)

interface FormItemLabelProps {
  label: string
  description?: string
  direction?: string
}

export const FormItemLabel: React.FC<FormItemLabelProps> = ({ label, description, direction = 'vertical' }) => (
  // @ts-ignore
  <Space direction={direction} style={{ width: '100%' }} size={0}>
    <Label data-testid="form-item-label">{label}</Label>
    {description && <Description data-testid="form-item-desc">{description}</Description>}
  </Space>
)
