import { SmartFieldConfig, SmartFieldIntegrationConfig } from '../types/data'
import {
  SmartFieldFormValues,
  SmartFieldIntegrationFormValues,
} from '../types/form'
import { getProviderConfig } from './providers/provider.config'

export const integrationFormParser = (
  values: SmartFieldFormValues,
  config: Partial<SmartFieldConfig<SmartFieldIntegrationConfig>>,
): SmartFieldConfig => {
  const intgrValues =
    values as SmartFieldFormValues<SmartFieldIntegrationFormValues>
  config.provider = intgrValues.provider

  return getProviderConfig(config.provider).formParser(values, config)
}

export const integrationConfigParser = (
  config: SmartFieldConfig,
): SmartFieldIntegrationFormValues => {
  const cfg = config as SmartFieldConfig<SmartFieldIntegrationConfig>
  const values: Partial<SmartFieldFormValues<SmartFieldIntegrationFormValues>> =
    {
      provider: cfg.provider,
    }

  return getProviderConfig(cfg.provider).configParser(values, cfg)
}
