import { SearchOutlined } from '@ant-design/icons'
import { Avatar, Button, Divider, Input, List, Modal } from 'antd'
import colors from 'components/base/ColorPicker/colors'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { msg } from 'stores/msg'
import styled from 'styled-components'
import styles, { FlexRow } from 'styles'

const lc = (str = '') => (str ? str.toLowerCase() : '')

const StyledSearchBar = styled(FlexRow)`
  border-bottom: 1px solid ${styles.colors.border};
  padding: 6px 24px;
  .clear {
    color: ${styles.colors.black};
    font-size: 14px;
    cursor: pointer;

    &:hover {
      color: ${styles.colors.blue};
    }
  }

  .anticon-search {
    color: ${styles.colors.black};
    cursor: pointer;
  }

  .anticon-search {
    margin-right: 16px;
  }

  .note-input {
    margin-right: 16px;
    padding: 0 !important;
    border: 0;

    &:focus {
      box-shadow: unset;
      border: 0;
    }
  }

  .ant-divider {
    margin: 0 16px;
  }
`

const SearchBar = ({ search, onSearch }) => {
  return (
    <StyledSearchBar>
      <SearchOutlined />
      <Input
        className="note-input"
        onChange={(e) => onSearch(e.target.value)}
        placeholder="Filter tags ..."
        value={search}
        size="large"
        autoFocus
      />
      <span onClick={() => onSearch('')} className="clear">
        Clear
      </span>
      <Divider type="vertical" />
    </StyledSearchBar>
  )
}

const StyledList = styled(List)`
  background: white;
  overflow: auto;
  height: 350px;

  .ant-list-item,
  .ant-list-item-no-flex {
    padding: 5px 12px !important;
    border-bottom: 0;
    cursor: pointer;
    font-size: 12px;
    min-height: 54px;

    &.selected,
    &:hover {
      background: ${styles.colors.selectedBackground};
    }
  }

  .ant-list-item-meta {
    margin-bottom: 0;
    .ant-list-item-meta-title {
      color: rgba(0, 0, 0, 0.7) !important;
      font-size: 14px !important;
      line-height: 22px;
      margin-bottom: 0px;
    }
  }
`

const getColor = (color) => {
  if (!color) {
    return colors[null]
  }

  if (color.charAt(0) === '#') {
    return color
  }
  return colors[color]
}

const TagList = ({ tags, selected, onAdd, onRemove }) => {
  const isSelected = (value) => selected.length && selected.includes(value)

  return (
    <StyledList
      dataSource={tags}
      className="notes-list"
      itemLayout="horizontal"
      size="small"
      renderItem={(item, i) => (
        <List.Item
          actions={[
            isSelected(item.value) ? (
              <Button type="link" onClick={() => onRemove(i)} size="small">
                Remove
              </Button>
            ) : (
              <Button type="link" onClick={() => onAdd(i)} size="small">
                Add
              </Button>
            ),
          ]}
        >
          <List.Item.Meta
            avatar={
              <Avatar
                style={{ backgroundColor: getColor(item.color), verticalAlign: 'middle' }}
                size="small"
                shape="square"
              ></Avatar>
            }
            title={<strong>{item.name}</strong>}
            description={item.description}
          />
        </List.Item>
      )}
    />
  )
}

const StyledModal = styled(Modal)`
  .ant-modal-close {
    .ant-modal-close-x {
      height: 40px;

      .ant-modal-close-icon {
        vertical-align: 0.45em;
        color: ${styles.colors.black};
        cursor: pointer;
      }
    }
  }
  .ant-modal-header {
    padding: 0;
  }
  .ant-modal-body {
    padding: 0;
    border-radius: 6px;
  }
  .ant-modal-footer {
    padding: 0;
  }
`

const getItemTags = ({ item }) => {
  if (!item) {
    return []
  }
  const map = (t) => t.value
  return item.tags.map(map)
}

const AddTag = (props) => {
  const [search, setSearch] = useState('')
  const tagValues = getItemTags(global.app.showAddTag)

  const tags = global.tags.userTags.filter((t) => {
    const txt = lc(`${t.name}|${t.description}`)
    const filterMatch = txt.includes(lc(search))
    return filterMatch
  })

  const onManage = () => {
    global.router.goto('/settings/tags')
    global.app.showAddTag = false
  }

  const onSelectTag = async (index) => {
    const { item, model } = global.app.showAddTag

    const name = tags[index].name

    await global.tags.addTag(item, model, tags[index].value)
    msg.success(`${name} added`)

    setSearch('')
  }

  const onRemoveTag = async (index) => {
    const { item, model } = global.app.showAddTag
    const name = tags[index].name
    await global.tags.removeTag(item, model, tags[index].value)
    msg.success(`${name} removed`)

    setSearch('')
  }

  return (
    <StyledModal
      title={<SearchBar search={search} onSearch={setSearch} />}
      onCancel={() => (global.app.showAddTag = false)}
      open={global.app.showAddTag}
      closable
      width={550}
      footer={
        <FlexRow justify="space-between">
          <Button type="link" onClick={onManage} style={{ fontSize: 15, color: styles.colors.blue, marginBottom: 4 }}>
            Manage tags
          </Button>
        </FlexRow>
      }
    >
      <TagList onAdd={onSelectTag} onRemove={onRemoveTag} tags={tags} selected={tagValues} />
    </StyledModal>
  )
}

export default observer(AddTag)
