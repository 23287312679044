export const taxRecordFields = {
  input_year: 'Input Year',
  household_name: 'Household Name',
  household_state: 'Household State',
  household: 'Household',
  tax_payer_1: 'Tax Payer 1',
  tax_payer_2: 'Tax Payer 2',
  filing_status: 'Filing Status',
  total_income: 'Total Income',
  agi: 'AGI',
  total_deductions: 'Total Deductions',
  total_tax: 'Total Tax',
  total_deductible_taxes: 'Total Deductable Taxes',
  taxable_income: 'Taxable Income',
  ordinary_income: 'Ordinary Income',
  marginal_bracket: 'Marginal Bracket',
  total_capital_gains: 'Total Capital Gains',
  average_rate: 'Average Rate',
  marginal_cap_gains_bracket: 'Marginal Cap. Gains Bracket',
  penalty_proof: 'Penalty Proof',
  tax_exempt_interest: 'Tax Exempt Interest',
  qualified_dividends: 'Qualified Dividends',
  ordinary_dividends: 'Ordinary Dividends',
  short_term_capital_gains: 'Short Term Cap. Gains',
  long_term_capital_gains: 'Long Term Cap. Gains',
  short_term_capital_loss_carryover: 'Short Term Cap. Loss Carryover',
  long_term_capital_loss_carryover: 'Long Term Cap. Loss Carryover',
  credits: 'Credits',
  medicare_magi: 'Medicare MAGI',
  medicare_premium_increase: 'Medicare Premium Increase',
  itemized_deduction: 'Itemized Deduction',
  medical_expenses: 'Medical Expenses',
  total_itemized_interest: 'Total Itemized Interest',
  charitable_deduction: 'Charitable Deduction',
  amt_2c: 'AMT - Line 2C',
  amt_8: 'AMT 8',
  tax_letter_note: 'Tax Letter Note',
  amount_you_owe: 'Amount You Owe',
  standard_or_itemized_deduction: 'Standard Or Itemized Deduction',
  sch_a_medical_expenses: 'Sch A Medical Expenses',
  sch_a_taxes_paid: 'Sch A Taxes Paid',
  sch_a_mortgage_investment_expenses: 'Sch A Mortgage Investment Expenses',
  sch_a_charity: 'Sch A Charity',
  sch_b_interest_by_entity: 'Sch B Interest By Entity',
  sch_b_dividend_by_entity: 'Sch B Dividend By Entity',
  sch_c_net_profit: 'Sch C Net Profit',
  sch_d_gross_short_term_gain: 'Sch D Gross Short Term Gain',
  sch_d_short_term_carryover: 'Sch D Short Term Carryover',
  sch_d_net_short_term_gain: 'Sch D Net Short Term Gain',
  sch_d_gross_long_term_gain: 'Sch D Gross Long Term Gain',
  sch_d_long_term_carryover: 'Sch D Long Term Carryover',
  sch_d_net_long_term_gain: 'Sch D Net Long Term Gain',
  sch_d_total_gain: 'Sch D Total Gain',
  sch_e_net_income: 'Sch E Net Income',
}
