// TODO: add types for intercom
export function update(payload: Object) {
  // @ts-ignore
  window.Intercom('update', payload)
}

export default function init(payload: Object) {
  // @ts-ignore
  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'qu9r2t9q',
    ...payload,
  })
}
