import { action, makeAutoObservable } from 'mobx'

class MainLayoutPM {
  collapsed = false

  constructor() {
    makeAutoObservable(this, {
      toggleCollapsed: action.bound,
    })
  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }
}

export const mainLayoutPM = new MainLayoutPM()
