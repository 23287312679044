/* eslint-disable react-hooks/rules-of-hooks */
import React, { Suspense, lazy, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Spin } from 'antd'
import styles from 'styles'

const Container = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  flex: 1;
`

export const Loading = (props) => {
  if (props.error) {
    throw props.error
  }
  const offset = props.offset ? styles.sideMenuCollapsedWidth : 0
  return (
    <Container>
      <Spin size="large" style={{ marginLeft: offset }} />
    </Container>
  )
}

const Async =
  (loader, offset = false) =>
  () => {
    const Component = lazy(loader)
    return (
      <Suspense fallback={<Loading offset={offset} />} style={{ height: '100%' }}>
        <Component />
      </Suspense>
    )
  }

/**
 * Prevents React.suspense creating a new child on every render
 * effective unmounting/remounting every route on render
 * https://github.com/facebook/react/issues/14299
 * @param {*} path
 * @param {*} offset
 */
export const Lazy = (path, offset = 0) => {
  return (props) => {
    const [Container, setContainer] = useState(null)

    useEffect(() => {
      setContainer(lazy(() => path()))
    }, [])

    return <Suspense fallback={<Loading offset={offset} />}>{Container && <Container {...props} />}</Suspense>
  }
}

export default Async
