import {
  SmartFieldConfig,
  SmartFieldIntegrationConfig,
} from 'components/drawers/Smartfields/types/data'
import {
  RiskalyzeFormValues,
  RiskalyzeQuestionaireFormValues,
  SmartFieldFormValues,
  SmartFieldIntegrationFormValues,
} from 'components/drawers/Smartfields/types/form'
import {
  RiskalyzeConfig,
  RiskalyzeQuestionaireConfig,
} from 'components/drawers/Smartfields/types/integration'
import { ConfigParser, FormParser } from '../../../provider.interfaces'

const PROPS = [
  'questionaire_type',
  'questionaire_link',
  'questionaire_link_title',
  'questionaire_contact',
  'questionaire_invested',
]

export const riskalyzeQuestionaireParser: FormParser = (
  values,
  config,
): SmartFieldConfig => {
  const cfg = config as SmartFieldIntegrationConfig<
    RiskalyzeConfig<RiskalyzeQuestionaireConfig>
  >
  const vals = values as SmartFieldFormValues<
    SmartFieldIntegrationFormValues<
      RiskalyzeFormValues<RiskalyzeQuestionaireFormValues>
    >
  >

  cfg.provider = 'riskalyze'
  PROPS.map((prop) => {
    ;(cfg as any)[prop] = (vals as any)[prop]
  })

  return config as SmartFieldConfig
}

export const riskalyzeQuestionaireConfigParser: ConfigParser = (
  values,
  config,
) => {
  const cfg = config as SmartFieldConfig<
    SmartFieldIntegrationConfig<RiskalyzeConfig<RiskalyzeQuestionaireConfig>>
  >
  const vals = values as SmartFieldFormValues<
    SmartFieldIntegrationFormValues<
      RiskalyzeFormValues<RiskalyzeQuestionaireFormValues>
    >
  >

  PROPS.map((prop) => {
    ;(vals as any)[prop] = (cfg as any)[prop]
  })

  return vals
}
