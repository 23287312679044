import { isNil } from 'lodash'
import { Email as emailRegex } from './regex'

export const emailOrIdFormValidator = {
  /* message: "This client does't have an valid email address!", */
  validator: async (rule, value) => {
    if (!value) {
      return Promise.resolve()
    }

    let emailOrIdsArray = value
    if (!Array.isArray(value)) {
      emailOrIdsArray = [value]
    }

    const clients = await getClients(emailOrIdsArray)
    const invalid = []

    const isValid = emailOrIdsArray.every((emailOrId) => {
      if (isNil(emailOrId)) {
        return false
      }

      const isEmail = emailRegex.test(emailOrId)

      if (isEmail) {
        return true
      }

      const isClient = clients.find((client) => client.id === Number(emailOrId))
      if (isClient) {
        if (emailRegex.test(isClient.email)) {
          return true
        } else {
          invalid.push(isClient.firstName)
          return false
        }
      }

      return false
    })

    return isValid
      ? Promise.resolve()
      : Promise.reject(new Error(`${invalid.join(', ')} does't have an valid email address!`))
  },
}

const getClients = async (emailOrIdsArray) => {
  const clients = []

  for (const emailOrId of emailOrIdsArray) {
    if (isNil(emailOrId)) {
      continue
    }

    if (!isNaN(emailOrId)) {
      try {
        const client = await global.data.clients.getClient(emailOrId)
        clients.push(client)
      } catch (error) {
        // silence is golden
      }
    }
  }

  return clients
}
