import { SettingOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { LAYOUT_SUBMODULES, MODULES, createTourLabel } from '../../../../constants/tours'

const dataTourLabel = createTourLabel(MODULES.LAYOUT, LAYOUT_SUBMODULES.HEADER)

const SettingsMenu = () => {
  return (
    <Button
      shape="circle"
      data-tour={dataTourLabel('settingsMenu', 'settings')}
      onClick={() => global.router.goto('/settings/practice')}
    >
      <SettingOutlined />
    </Button>
  )
}

export default SettingsMenu
