import React, { useState } from 'react'
import useInterval from '../../hooks/useInterval'
import { sample } from 'lodash'

const msgs = [
  'Working hard',
  "Won't be a second",
  'Hold on a minute',
  'Crunching numbers',
  "You're being very patient",
  'Please wait while we make coffee',
  'Turning some cogs',
  'Wait for it',
  'Nearly there',
  'We love your patience',
]

const Messages = ({ msg }) => {
  const [text, setText] = useState(msg || sample(msgs))

  useInterval(() => {
    if (!msg) {
      setText(sample(msgs))
    }
  }, 5000)

  return <>{text}...</>
}

export default Messages
