import { ERRORS as BASE_ERRORS } from '../provider.errors'

export const ERRORS = {
  ...BASE_ERRORS,
  LOAD_CONTACTS_ERROR: 'Unable to load Holistplan Contacts',
  NO_CONTACTS_ERROR: 'Unable to load Holistplan Contacts',
  LOAD_TAX_RECORDS_ERROR: 'Unable to load Holistplan Tax Records',
  NO_TAX_RECORDS_ERROR: 'There are no Holistplan Tax Records for this contact',
  FIELD_ERROR: 'Unable to load Holistplan data for field ',
}
