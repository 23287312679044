import { EditorState, ProsemirrorNode } from '@remirror/pm'
import { Node } from '@remirror/pm/model'
import { isProsemirrorFragment, isProsemirrorNode } from 'remirror'
import { formatInlineContent, getNotesByTypeAndTags } from '../../NoteReplacer.helpers'
import { MergeFieldContentToReplace, MergeFieldToReplace, NotesGroup } from '../../NoteReplacer.interfaces'
import { emptyMergeFieldContent } from './utils/emptyMergeFieldContent.util'
import { TopicTitle } from './utils/topicTitle.util'
import { hasValidSubtopic } from './utils/hasValidSubtopic.util'

/**
 * Processes a MergeFieldToReplace item, and returns it wrapped in a ListItem.
 * @param state
 * @param mergeFieldToReplace
 * @param notesGroup
 * @returns
 */
export function processMergeFieldToReplace(
  state: Readonly<EditorState>,
  mergeFieldToReplace: MergeFieldToReplace,
  notesGroup: NotesGroup[],
): MergeFieldContentToReplace {
  const {
    header,
    tags: placeholderTags,
    mergeFieldType,
    kind,
    marks,
    pos,
    displayMode,
    parentAttrs,
  } = mergeFieldToReplace

  const notesForPlaceholder = getNotesByTypeAndTags(notesGroup, mergeFieldType, placeholderTags)

  if (!notesForPlaceholder?.length || !hasValidSubtopic(mergeFieldToReplace)) {
    return emptyMergeFieldContent(mergeFieldType, pos, marks)
  }

  const noteGroupList = notesForPlaceholder
    .flatMap((note) => {
      const content = note.content
      if (isProsemirrorNode(content)) {
        return formatInlineContent({ node: content, attrs: parentAttrs, marks, state })
      }

      if (isProsemirrorFragment(content)) {
        const nodes: ProsemirrorNode[] = []

        content.forEach((node, offset, index) => {
          //a note can have two paragraphs, we need to add the corresponding line break.
          const prev = index - 1 >= 0 ? content.child(index - 1) : undefined

          if (prev && prev.type.name === 'paragraph' && prev.content.size > 0) {
            nodes.push(state.schema.nodes.hardBreak.create())
            if (node.type.name === 'paragraph' && node.content.size === 0) {
              nodes.push(state.schema.nodes.hardBreak.create())
            }
          } else if (node.type.name === 'paragraph' && node.content.size === 0) {
            nodes.push(state.schema.nodes.hardBreak.create())
          }

          const inlineContent = formatInlineContent({ node, attrs: parentAttrs, marks, state })
          nodes.push(...inlineContent)
        })

        return nodes
      }

      return content.flatMap((child) => formatInlineContent({ node: child, attrs: parentAttrs, marks, state }))
    })
    .filter(Boolean)

  const spaceTextNode = state.schema.text(' ', marks)

  const content: Node[] = []

  const title = TopicTitle({
    kind,
    header,
    state,
    mergeFieldType,
    placeholderTags,
    marks,
    onlyText: true,
    attrs: parentAttrs,
  })
  if (title) {
    content.push(title)
  }

  content.push(...noteGroupList)

  const spacedContent = content.flatMap((node, index) => {
    // when adding the space between words we must check that the element is not a line break, so as not to generate unnecessary initial spaces.
    if (node.type.name === 'hardBreak') {
      return [node]
    }

    return [node, spaceTextNode]
  })

  const contentSize = content.reduce((accumulator, node) => accumulator + node.nodeSize, 0)

  return {
    type: mergeFieldType,
    pos,
    content: spacedContent,
    contentSize,
    marks,
    displayMode,
  }
}
