export type DocumentFormat = Record<string, number[]>

export const formats: DocumentFormat = {
  a4: [8.3, 11.7],
  letter: [8.5, 11],
}

export const DocumentFormats = Object.keys(formats)

export const orientations = ['portrait', 'landscape']
