import { isElementDomNode, NodeSpecOverride } from 'remirror'
import { v4 as uuidv4 } from 'uuid'
import { smartFieldMenuItems, SmartFieldMenuConstants } from '../../../components/menus/smartFieldMenu/utils'
import { SmartFieldMentionAttrs } from 'components/drawers/Smartfields/types'

export const FroalaTopicParseDOM: NodeSpecOverride = {
  parseDOM: [
    {
      tag: 'span[data-type="p360-ph"]',
      getAttrs: (node: string | Node) => {
        if (!isElementDomNode(node)) {
          return false
        }

        const id = node.getAttribute('data-id') || uuidv4()
        const name = 'command'
        const tags = removeNull(node.getAttribute('data-tags'), '')
        const header = removeNull(node.getAttribute('data-header'), '')
        const kind = node.getAttribute('data-id')?.startsWith('topic::') ? 'topic' : 'placeholder'
        const label = node.textContent

        // rename placeholders
        if (kind === 'placeholder' && id in placeholderMigraion) {
          const placeholder = placeholderMigraion[id]

          if (!placeholder) {
            throw new Error('the placeholder does not exist')
            return false
          }

          return {
            id: placeholder.id,
            label: placeholder.label,
            name,
            tags,
            header,
            kind,
            displayMode: placeholder.displayMode,
          }
        }

        return { id, label, name, tags, header, kind, displayMode: '' }
      },
    },
  ],
}

function removeNull(value: null | string, defaultValue: string) {
  if (!value) {
    return defaultValue
  }

  if (value === 'null') {
    return defaultValue
  }

  return value
}

const placeholderMigraion: Record<string, SmartFieldMentionAttrs | undefined> = {
  'client-greeting': smartFieldMenuItems.get(SmartFieldMenuConstants.CONTACT_GREETING),
  'client-formal-greeting': smartFieldMenuItems.get(SmartFieldMenuConstants.CONTACT_FORMAL_GREETING),
  'client-then-addr': smartFieldMenuItems.get(SmartFieldMenuConstants.CONTACT_NAME_AND_ADDRESS),
  'client-addr': smartFieldMenuItems.get(SmartFieldMenuConstants.CONTACT_ADDRESS),
  'advisor-fullname': smartFieldMenuItems.get(SmartFieldMenuConstants.USER_FULLNAME),
  advisor: smartFieldMenuItems.get(SmartFieldMenuConstants.USER_NICKNAME),
  'wet-sign': smartFieldMenuItems.get(SmartFieldMenuConstants.USER_WET_SIGNATURE),
  'email-sign': smartFieldMenuItems.get(SmartFieldMenuConstants.USER_EMAIL_SIGNATURE),
  'date-export': smartFieldMenuItems.get(SmartFieldMenuConstants.DOCUMENT_CREATION_DATE),
  'date-appt': smartFieldMenuItems.get(SmartFieldMenuConstants.EVENT_DATE),
  'time-appt': smartFieldMenuItems.get(SmartFieldMenuConstants.EVENT_TIME),
}
