import { command, PlainExtension } from '@remirror/core'
import { CommandFunction } from 'remirror'

export class ClearFormattingExtension extends PlainExtension {
  get name() {
    return 'clearFormatting' as const
  }

  @command()
  clearFormatting(): CommandFunction {
    return ({ dispatch, tr, state }) => {
      const { empty, ranges } = state.selection

      if (empty) {
        return false
      }

      Object.entries(state.schema.marks).forEach(([, mark]) => {
        ranges.forEach((range) => {
          dispatch?.(tr.removeMark(range.$from.pos, range.$to.pos, mark as any))
        })
      })
      return true
    }
  }
}
