import * as types from 'types/graphql'

export const IsError = (resp: any): resp is types.Error => {
  return !resp || resp.__typename === 'Error'
}

class DataError extends Error {
  constructor(response: { message: string; error: string }) {
    super(response.message)
    this.name = response.error
  }
}

export default DataError
