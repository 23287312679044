import * as apiService from 'lib/apollo/client'
import {
  ConnectOAuthCallbackDocument,
  ConnectOAuthCallbackMutation,
  ConnectOAuthCallbackMutationVariables,
  ConnectOAuthDocument,
  ConnectOAuthMutation,
  ConnectOAuthMutationVariables,
  ReconnectOAuthDocument,
  ReconnectOAuthMutation,
  ReconnectOAuthMutationVariables,
  RemoveOAuthDocument,
  RemoveOAuthMutation,
  RemoveOAuthMutationVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

class ConnectionService {
  async connect(clientId: string, clientSecret: string, redirect: string, identifier: string, type: string) {
    const { connectOAuth: resp } = await apiService.mutate<ConnectOAuthMutation, ConnectOAuthMutationVariables>(
      ConnectOAuthDocument,
      {
        params: {
          clientId,
          clientSecret,
          redirect,
          identifier,
          type,
        },
      },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.authorizationUri
  }

  async reconnect(identifier: string, redirect: string, type: string) {
    const { reconnectOAuth: resp } = await apiService.mutate<ReconnectOAuthMutation, ReconnectOAuthMutationVariables>(
      ReconnectOAuthDocument,
      {
        identifier,
        redirect,
        type,
      },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.authorizationUri
  }

  async connectCallback(redirect: string, identifier: string, authCode: string, type: string) {
    const { connectOAuthCallback: resp } = await apiService.mutate<
      ConnectOAuthCallbackMutation,
      ConnectOAuthCallbackMutationVariables
    >(ConnectOAuthCallbackDocument, {
      redirect,
      identifier,
      authCode,
      type,
    })

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.connection
  }

  async removeCallback(identifier: string, type: string) {
    const { removeOAuth: resp } = await apiService.mutate<RemoveOAuthMutation, RemoveOAuthMutationVariables>(
      RemoveOAuthDocument,
      {
        identifier,
        type,
      },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.connection
  }
}

export default new ConnectionService()
