import { SmartFieldConfig, SmartFieldNumberConfig } from '../types/data'
import { SmartFieldFormValues, SmartFieldNumberFormValues } from '../types/form'

const fields = ['format', 'places']

export const numberFormParser = (
  values: SmartFieldFormValues,
  config: Partial<SmartFieldConfig<SmartFieldNumberConfig>>,
): SmartFieldConfig => {
  const numValues = values as SmartFieldFormValues<SmartFieldNumberFormValues>

  fields.forEach((field) => {
    ;(config as any)[field] = (numValues as any)[field]
  })

  return config as SmartFieldConfig<SmartFieldNumberConfig>
}

export const numberConfigParser = (config: SmartFieldConfig): SmartFieldNumberFormValues => {
  return fields.reduce((values, value) => {
    return { ...values, [value]: (config as any)[value] }
  }, {}) as SmartFieldNumberFormValues
}
