import moment from 'moment'

export const pause = async (duration = 1000) => {
  await new Promise((res) => setTimeout(res, duration))
}

export function calculateIntervals(
  interval = 15,
  openingHour = 7,
  openingMinute = 0,
  closingHour = 19,
  closingMinute = 0,
) {
  const startDate = moment()
  startDate.set('minute', openingMinute)
  startDate.set('hour', openingHour)

  const closingLimit = moment()
  closingLimit.set('hour', closingHour)
  closingLimit.set('minute', closingMinute)

  const dates = []
  do {
    dates.push(moment(startDate))
    startDate.add(60 / (60 / interval), 'minute')
  } while (closingLimit.isSameOrAfter(startDate))

  return dates
}

export function getCurrentRoundedTime(interval = 15, roundUp = false) {
  return getRoundedTime(moment(), interval, roundUp)
}

export function getRoundedTime(otherMoment, interval = 30, roundUp = false) {
  const roundDirection = roundUp ? 'ceil' : 'floor'
  const rounded = moment(otherMoment)
    .minute(Math[roundDirection](moment().minute() / interval) * interval)
    .second(0)
    .millisecond(0)
  if (rounded.isAfter(otherMoment, 'day')) {
    return rounded.subtract(interval, 'minutes')
  }
  return rounded
}

export function composeDateTime(date, time) {
  const timeMoment = moment(time)
  return moment(date).hour(timeMoment.hour()).minute(timeMoment.minute()).second(0).millisecond(0)
}
