import { AppstoreOutlined, PushpinOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Button, Dropdown } from 'antd'
import { observer } from 'mobx-react'
import { PERMS_KEYS } from '../../../../constants/perms.constants'

const menuClick = ({ key }: { key: string }) => {
  switch (true) {
    case key === 'quick-note':
      global.router.goto('/quick/note')
      break

    case key === 'create-document':
      global.router.goto('/documents/create-from')
      break

    default:
      break
  }
}

const ShortcutMenu = () => {
  const items: MenuProps['items'] = [
    {
      type: 'group',
      label: `Select Shortcuts`,
      children: [
        {
          key: 'quick-note',
          icon: <PushpinOutlined />,
          label: 'Quick Capture Note',
        },
        ...(global.perms.get(PERMS_KEYS.DOCUMENTS) && global.perms.isAvailable(PERMS_KEYS.DOCUMENTS)
          ? [
              {
                key: 'create-document',
                icon: <PushpinOutlined />,
                label: 'Create Document',
              },
            ]
          : []),
      ],
    },
  ]
  return (
    <Dropdown menu={{ items, onClick: menuClick }} placement="bottomRight" trigger={['click']}>
      <Button shape="circle" icon={<AppstoreOutlined />} />
    </Dropdown>
  )
}

export default observer(ShortcutMenu)
