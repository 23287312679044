import { Layout } from 'antd'
import { observer } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'
import HeaderLayout from '../components/header/HeaderLayout'
import HeaderNavBar from '../components/header/HeaderNavBar'

const { Content } = Layout

interface MainTemplateProps {
  children: React.ReactNode
  className?: React.HTMLAttributes<HTMLDivElement>['className']
}

const StyledContent = styled(Content)`
  display: flex;
  flex-direction: column;
  padding: 12px 24px 12px 24px;
  gap: 8px;
  overflow: auto;
`

interface MainTemplateContentProps {
  children: React.ReactNode
  id?: string
}

export function MainTemplateContent({ children, id }: MainTemplateContentProps) {
  return (
    <StyledContent className="main-template__content" id={id}>
      {children}
    </StyledContent>
  )
}

const MainTemplate = ({ children, className }: MainTemplateProps) => {
  return (
    <Layout className={className}>
      <HeaderLayout>
        <HeaderNavBar />
      </HeaderLayout>
      {children}
    </Layout>
  )
}

export default observer(MainTemplate)
