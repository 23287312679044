import { useAuth, useClerk, useOrganizationList } from '@clerk/clerk-react'
import type { OrganizationMembershipResource } from '@clerk/types'
import { autorun } from 'mobx'
import { useEffect, useState } from 'react'
import { authStore } from './index'

export const useMemberships = () => {
  const [memberships, setMemberships] = useState<OrganizationMembershipResource[]>([])

  const { setActive, userMemberships, isLoaded } = useOrganizationList({
    userMemberships: {
      infinite: true,
      pageSize: 499,
    },
  })

  useEffect(() => {
    setMemberships(userMemberships?.data ?? [])
  }, [userMemberships.data, isLoaded])

  return {
    memberships,
    setActive,
  }
}

export const useAuthStore = () => {
  const { memberships, setActive } = useMemberships()
  const { orgId, orgSlug, isSignedIn } = useAuth()

  const clerk = useClerk()

  useEffect((): any => {
    authStore.setClerk(clerk)
  }, [clerk])

  // If we have an active org, signin.
  useEffect((): any => {
    if (orgId) authStore.signIn(orgId as string, orgSlug as string)
  }, [orgId, orgSlug])

  useEffect((): any => {
    // If we're signedIn but there is no orgId, it means we don't have
    // an active organization set, so wait for memberships to load and set the first as active
    if (isSignedIn && !orgId) {
      if (memberships.length) {
        const { id } = memberships[0].organization

        setActive?.({ organization: id })
      }
    }
  }, [isSignedIn, memberships, orgId, setActive])
}

export const useSignedIn = () => {
  const [signedIn, setSignedIn] = useState(false)

  useAuthStore()

  useEffect(() => {
    return autorun(() => {
      setSignedIn(authStore.signedIn)
    })
  }, [])

  return {
    signedIn,
  }
}
