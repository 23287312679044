import { SmartFieldMentionAttrs } from 'components/drawers/Smartfields/types'
import { SmartFieldKind } from 'stores/smartfields/constants'
import { SmartFieldAtom } from './atoms/smartField/atom'
import { MentionAtom } from './atoms/topic/atom'

export type SmartFieldAtomAttrs = SmartFieldMentionAttrs & {
  disableAtomSync?: boolean
}

export const MentionAtomCustom: React.FC<SmartFieldAtomAttrs> = (props) => {
  const { label, kind } = props

  if (kind === SmartFieldKind.TOPIC) {
    return <MentionAtom {...props} />
  }

  if (kind === SmartFieldKind.SMARTFIELD) {
    return <SmartFieldAtom {...props} />
  }

  return <span>{label}</span>
}
