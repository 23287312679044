import { cloneDeep } from 'lodash'
import { makeObservable, observable } from 'mobx'
import moment from 'moment'
import { captureExceptionSilently } from '../helpers/sentry'
import { getCurrentRoundedTime, getRoundedTime } from '../helpers/time'
import { authStore } from './auth'
import { integrationStore } from './integrations'
import { msg } from './msg'
import workflowService from './services/workflow.service'

class Workflows {
  workspace = ''
  _workflowCrmTemplates = []

  /**
   * Get Workflow CRM Templates
   */
  findWorkflowCrmTemplates = async () => {
    if (
      this._workflowCrmTemplates.length &&
      this.workspace === authStore.orgName
    ) {
      return this._workflowCrmTemplates
    }

    try {
      global.app.loading = true
      const workflowCrmTemplates =
        await workflowService.findWorkflowCrmTemplates()
      // TODO - clerk, check this is correct
      this.workspace = authStore.orgName

      this._workflowCrmTemplates = workflowCrmTemplates || [
        { id: 1, name: 'Onboard the client' },
      ]
    } catch (error) {
      msg.error(['Workflow CRM Template', 'fetching'])
      captureExceptionSilently(error, { message: 'findWorkflowCRMTemplates' })
    } finally {
      global.app.loading = false
    }

    return this._workflowCrmTemplates
  }

  /**
   * Creates a workflow
   * @param {*} workflow
   */
  addWorkflow = async (workflow) => {
    global.app.loading = true
    try {
      const workflowCreated = await workflowService.createWorkflow({ workflow })
      workflow.id = workflowCreated.id
      global.data.appt.updateNoteLocally(workflow.noteId, { workflow })
      msg.success(`Workflow Triggered!`)
    } catch (error) {
      msg.error(['workflow', 'creating'])
      captureExceptionSilently(error, {
        message: 'addWorkflow',
        data: { workflow },
      })
    } finally {
      global.app.loading = false
    }

    return workflow
  }

  /**
   * Updates a workflow on Pulse360 and in the Provider
   * @param {*} workflow
   */
  updateWorkflow = async (workflow) => {
    const { id, ...payload } = workflow
    global.app.loading = true
    try {
      const isWorkflowUpdated = await workflowService.updateWorkflow({
        id,
        workflow: payload,
      })
      global.data.appt.updateNoteLocally(workflow.noteId, {
        workflow: workflow,
      })
      msg.success(`Workflow Updated!`)

      return isWorkflowUpdated
    } catch (error) {
      msg.error(['workflow', 'updating'])
    }

    global.app.loading = false
  }

  /**
   * Delete a workflow on Pulse360 and optionally, on the Provider
   * @param {*} workflow
   * @param {*} deleteOnProvider
   */
  deleteWorkflow = async (workflow, deleteOnProvider) => {
    global.app.loading = true
    try {
      const id = await workflowService.deleteWorkflow({
        id: workflow.id,
        deleteOnProvider,
      })
      global.data.appt.updateNoteLocally(workflow.noteId, { workflow: null })
      msg.success(`Workflow Deleted!`)
      return id
    } catch (error) {
      msg.error(['workflow', 'deleting'])
      captureExceptionSilently(error, {
        message: 'deleteWorkflow',
        data: { workflow, deleteOnProvider },
      })
    } finally {
      global.app.loading = false
    }
  }

  constructor() {
    makeObservable(this, {
      _workflowCrmTemplates: observable,
    })
  }

  parseWorkflowData(workflow) {
    if (!workflow) {
      return workflow
    }
    const assignedTo = (workflow.assignedTo || []).map((advisor) =>
      isNaN(advisor) ? String(advisor.id) : advisor,
    )
    return { ...workflow, assignedTo }
  }

  /**
   * Build Workflow Template for Microtemplates
   * @param {Note} note
   * @param {WorkflowTemplate} workflowTemplate
   */
  buildWorkflowTemplate(workflow, summary) {
    const workflowTemplate = cloneDeep(workflow)
    if (!workflowTemplate?.customFields?.workflowCrmTemplate) {
      return null
    }
    const endDate =
      'endDate' in workflowTemplate
        ? String(Number(workflowTemplate.endDate))
        : String(0)
    const startDate =
      'startDate' in workflowTemplate
        ? String(Number(workflowTemplate.startDate))
        : String(0)
    const crmTemplate = this._workflowCrmTemplates.find(
      (t) => t.id === workflowTemplate.customFields.workflowCrmTemplate,
    )
    workflowTemplate.name = crmTemplate?.name || ''
    if (workflowTemplate.customFields.milestones && crmTemplate) {
      workflowTemplate.customFields.milestones = this.getTemplateMilestonesData(
        workflowTemplate.customFields.milestones,
        crmTemplate,
      )
    }
    if (summary?.workflowTemplate?.id) {
      return {
        id: summary.workflowTemplate.id,
        ...workflowTemplate,
        startDate,
        endDate,
      }
    }
    return { ...workflowTemplate, startDate, endDate }
  }

  /**
   * Build Workflow from a note and a workflowTemplate
   * @param {Note} note
   * @param {WorkflowTemplate} workflowTemplate
   */
  buildWorkflow(note, workflowTemplate, isEditing = false, appointment) {
    const { id: workflowId, __typename, ...workflow } = workflowTemplate || {}
    const { workflowCrmTemplate, milestones } = workflow.customFields || {}

    if (!workflow || !workflowCrmTemplate) {
      return undefined
    }

    if (isEditing) {
      workflow.id = workflowId
    }
    if (note.id) {
      workflow.noteId = note.id
    }
    if (!workflow.name) {
      // FIXME: this._workflowCrmTemplates won't be hydrated unless we call previously `findWorkflowCrmTemplates`
      const crmTemplate = this._workflowCrmTemplates.find(
        (t) => t.id === workflowCrmTemplate,
      )
      workflow.name = crmTemplate?.name || ''
    }

    if (workflow.linkedTo && !Array.isArray(workflow.linkedTo)) {
      workflow.linkedTo = [
        isNaN(workflow.linkedTo) ? workflow.linkedTo.id : workflow.linkedTo,
      ]
    } else if (Array.isArray(workflow.linkedTo)) {
      workflow.linkedTo = workflow.linkedTo.map((clientId) =>
        isNaN(clientId) ? clientId.id : clientId,
      )
    } else {
      workflow.linkedTo = [note.clientId]
    }

    const [startDate, endDate] = this._createWorkflowDates(
      workflow.startDate,
      workflow.endDate,
      workflow.customFields,
      appointment,
    )

    let milestonesData
    if (milestones) {
      milestonesData = milestones.map((milestone) => {
        const milestoneStartDate = moment(startDate)
          .add(milestone.date, 'd')
          .toISOString()
        return {
          ...milestone,
          date: milestoneStartDate,
        }
      })
    }

    return {
      ...workflow,
      customFields: { workflowCrmTemplate, milestones: milestonesData },
      startDate,
      endDate,
    }
  }

  /**
   * Builds the dates for a Workflow. The dates can be user-set, or number.
   * @param {Number | Date} startDate
   * @param {Number | Date} endDate
   * @param {Object} customFields
   * @param {Object} relatedAppointment
   */
  _createWorkflowDates(
    startDate,
    endDate = 0,
    customFields,
    relatedAppointment,
  ) {
    const {
      allDay = true,
      endFromEvent = false,
      startFromEvent = false,
    } = customFields || {}
    const { global: globalAppointment = false, date: apptDate = new Date() } =
      relatedAppointment || {}

    const endDateIsNumber = !isNaN(endDate) && !moment.isMoment(endDate)
    const startDateExists = startDate !== null && startDate !== undefined
    const startDateIsNumber =
      startDateExists && !isNaN(startDate) && !moment.isMoment(startDate)

    // for global appointments, ignore the endFrom and startFrom props...
    const useEvent = (endFromEvent || startFromEvent) && !globalAppointment

    if (startDateExists) {
      // if specified (depends on the CRM), use it to set the startDate

      if (startDateIsNumber) {
        startDate = (useEvent ? moment(apptDate) : moment()).add(
          +startDate,
          'd',
        )
      } else {
        startDate = moment(startDate)
      }
      if (endDateIsNumber) {
        endDate = moment(startDate).add(+endDate, 'd')
      } else {
        endDate = moment(endDate)
      }
    } else {
      // if not (the CRM doesn't support a start date), we calculate the endDate and then copy that to the startDate
      if (endDateIsNumber) {
        // useEvent defines if we use the current date & time or the date & time of the event
        endDate = (
          useEvent ? moment(apptDate) : moment(getCurrentRoundedTime(30, true))
        ).add(+endDate, 'd')
      } else {
        endDate = moment(endDate)
      }
      startDate = moment(endDate)
    }

    if (integrationStore.enabledCRM.includes('RedTail') && allDay) {
      // only redtail has support for the allDay custom field
      startDate = getRoundedTime(moment(startDate).hour(12))
      endDate = getRoundedTime(moment(endDate).hour(12))
    }

    return [startDate.toISOString(), endDate.toISOString()]
  }

  getTemplateMilestonesData = (milestones, crmTemplate) => {
    return Object.keys(milestones).map((milestoneId) => {
      const daysDifference = milestones[milestoneId]
      const milestone = crmTemplate.customFields.milestones.find(
        ({ id }) => id === milestoneId,
      )
      return {
        id: milestone.id,
        name: milestone.name,
        date: daysDifference,
      }
    })
  }
}

export default Workflows
