import { SmartFieldDrawer } from 'components/drawers/Smartfields'
import UpgradePlanModal from 'components/modals/UpgradePlan'
import { useMemo } from 'react'
import AddTag from './modals/AddTag'
import ImageUpload from './modals/ImageUpload'
import { SendEmail } from './modals/SendEmail'
import { Lazy } from './routes/async'
import { ReplaceSmartFields } from './routes/tasks/modals/ReplaceSmartFields'

export const AppModalsAndDrawers = () => {
  const LibraryDrawer = useMemo(
    () =>
      Lazy(() =>
        import('../modules/library/library.drawer').then((module) => ({
          default: module.LibraryDrawer,
        })),
      ),
    [],
  )
  return (
    <>
      <SmartFieldDrawer />
      <ReplaceSmartFields />
      <SendEmail />
      <ImageUpload />
      <AddTag />
      <UpgradePlanModal />
      <LibraryDrawer />
    </>
  )
}
