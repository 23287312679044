import { SignUp as ClerkSignUp } from '@clerk/clerk-react'
import { ClerkLayout } from 'components/layouts/ClerkLayout'
import { elements } from 'lib/clerk/elements'
import { useLocation } from 'react-router-dom'

export const SignUp = () => {
  const { state } = useLocation()
  return (
    <ClerkLayout>
      <ClerkSignUp
        redirectUrl={state?.redirectTo}
        appearance={{
          elements: {
            ...elements,
            // Disable user from changing the email
            // address from the invite they received
            formFieldInput__emailAddress: {
              pointerEvents: 'none',
              '&:disabled': 'true',
            },
          },
        }}
      />
    </ClerkLayout>
  )
}
