import {
  SmartFieldConfig,
  SmartFieldIntegrationConfig,
} from 'components/drawers/Smartfields/types/data'
import {
  HolistiplanFormValues,
  SmartFieldFormValues,
  SmartFieldIntegrationFormValues,
} from 'components/drawers/Smartfields/types/form'
import { HolistiplanConfig } from 'components/drawers/Smartfields/types/integration'
import { ConfigParser, FormParser } from '../provider.interfaces'

export const holistiplanParser: FormParser = (values, config) => {
  const cfg = config as SmartFieldIntegrationConfig<HolistiplanConfig>
  const vals = values as SmartFieldFormValues<
    SmartFieldIntegrationFormValues<HolistiplanFormValues>
  >

  cfg.provider = 'holistiplan'
  cfg.field = vals.field

  return config as SmartFieldConfig
}

export const holistiplanConfigParser: ConfigParser = (values, config) => {
  const cfg = config as SmartFieldConfig<
    SmartFieldIntegrationConfig<HolistiplanConfig>
  >
  const vals = values as SmartFieldFormValues<
    SmartFieldIntegrationFormValues<HolistiplanFormValues>
  >

  vals.field = cfg.field
  return vals
}
