import * as apiService from 'lib/apollo/client'
import { isNil, omitBy } from 'lodash'
import {
  ConnectEmailDocument,
  ConnectEmailMutation,
  ConnectEmailMutationVariables,
  EmailInput,
  GetAuthUrlDocument,
  GetAuthUrlMutation,
  GetAuthUrlMutationVariables,
  RevokeEmailDocument,
  RevokeEmailMutation,
  SendEmailDocument,
  SendEmailMutation,
  SendEmailMutationVariables,
  SendTestEmailDocument,
  SendTestEmailMutation,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

class EmailService {
  async sendEmail(input: EmailInput, readReceipt = false) {
    const email = omitBy(input, isNil) as unknown as EmailInput
    const { sendEmail: resp } = await apiService.mutate<SendEmailMutation, SendEmailMutationVariables>(
      SendEmailDocument,
      { email, readReceipt },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async sendTestEmail() {
    const { sendTestEmail: resp } = await apiService.mutate<SendTestEmailMutation>(SendTestEmailDocument, {})
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async connectEmail(code: string) {
    const { connectEmail: resp } = await apiService.mutate<ConnectEmailMutation, ConnectEmailMutationVariables>(
      ConnectEmailDocument,
      { code },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.creds
  }

  async revokeEmail() {
    const { revokeEmail: resp } = await apiService.mutate<RevokeEmailMutation>(RevokeEmailDocument, {})
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async getAuthUrl(redirect: string, hint: string) {
    const { getAuthUrl: resp } = await apiService.mutate<GetAuthUrlMutation, GetAuthUrlMutationVariables>(
      GetAuthUrlDocument,
      {
        redirect,
        hint,
      },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.url
  }
}

export default new EmailService()
