import { autorun } from 'mobx'
import { useEffect, useMemo, useState } from 'react'

export const useMail = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [config, setConfig] = useState<any>()

  useEffect(() => {
    return autorun(() => {
      setShowModal(global.mail.visible)
      setConfig({ ...global.mail.config })
    })
  }, [])

  return useMemo(
    () => ({
      showModal,
      config,
    }),
    [showModal, config],
  )
}
