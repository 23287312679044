import * as apiService from 'lib/apollo/client'
import {
  CreateSmartfieldDocument,
  CreateSmartfieldMutation,
  CreateSmartfieldMutationVariables,
  DeleteSmartfieldDocument,
  DeleteSmartfieldMutation,
  DeleteSmartfieldMutationVariables,
  RestoreSmartfieldDocument,
  RestoreSmartfieldMutation,
  RestoreSmartfieldMutationVariables,
  Smartfield,
  SmartfieldInput,
  SmartfieldsDocument,
  SmartfieldsQuery,
  SmartfieldsQueryVariables,
  UpdateSmartfieldDocument,
  UpdateSmartfieldMutation,
  UpdateSmartfieldMutationVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

export const get = async () => {
  const { smartfields: resp } = await apiService.query<SmartfieldsQuery, SmartfieldsQueryVariables>(SmartfieldsDocument)

  if (IsError(resp)) {
    throw new DataError(resp)
  }

  return resp.smartfields as Smartfield[]
}

export const create = async (smartfield: SmartfieldInput) => {
  const { createSmartfield: resp } = await apiService.mutate<
    CreateSmartfieldMutation,
    CreateSmartfieldMutationVariables
  >(CreateSmartfieldDocument, { smartfield })

  if (IsError(resp)) {
    throw new DataError(resp)
  }

  return resp.smartfield
}

export const update = async (id: string, smartfield: SmartfieldInput) => {
  const { updateSmartfield: resp } = await apiService.mutate<
    UpdateSmartfieldMutation,
    UpdateSmartfieldMutationVariables
  >(UpdateSmartfieldDocument, {
    id,
    smartfield,
  })
  if (IsError(resp)) {
    throw new DataError(resp)
  }

  return resp.smartfield
}

export const remove = async (id: string) => {
  const { deleteSmartfield: resp } = await apiService.mutate<
    DeleteSmartfieldMutation,
    DeleteSmartfieldMutationVariables
  >(DeleteSmartfieldDocument, {
    id,
  })
  if (IsError(resp)) {
    throw new DataError(resp)
  }

  return resp?.smartfield as Smartfield
}

export const restore = async (id: string) => {
  const { restoreSmartfield: resp } = await apiService.mutate<
    RestoreSmartfieldMutation,
    RestoreSmartfieldMutationVariables
  >(RestoreSmartfieldDocument, {
    id,
  })
  if (IsError(resp)) {
    throw new DataError(resp)
  }

  return resp?.smartfield as Smartfield
}
