import { addColumn, addRow, selectedRect } from '@remirror/pm/tables'
import { EditorState, Transaction } from 'remirror'

export function isInTable(state: Readonly<EditorState>) {
  let $head = state.selection.$head
  for (let d = $head.depth; d > 0; d--) {
    if ($head.node(d).type.spec.tableRole == 'row') {
      return true
    }
  }
  return false
}

// :: (EditorState, tr: Transaction) → Transaction
// Add a table row before the selection.
export function addRowBefore(state: EditorState, tr: Transaction): Transaction {
  if (!isInTable(state)) {
    return tr
  }
  let rect = selectedRect(state)
  addRow(tr, rect, rect.top)
  return tr
}

// :: (EditorState, tr: Transaction) → Transaction
// Add a table row after the selection.
export function addRowAfter(state: EditorState, tr: Transaction): Transaction {
  if (!isInTable(state)) {
    return tr
  }
  let rect = selectedRect(state)
  addRow(tr, rect, rect.bottom)
  return tr
}

// :: (EditorState, tr: Transaction) → Transaction
// Command to add a column before the column with the selection.
export function addColumnBefore(state: EditorState, tr: Transaction): Transaction {
  if (!isInTable(state)) {
    return tr
  }
  let rect = selectedRect(state)
  addColumn(tr, rect, rect.left)
  return tr
}

// :: (EditorState, tr: Transaction) → Transaction
// Command to add a column after the column with the selection.
export function addColumnAfter(state: EditorState, tr: Transaction): Transaction {
  if (!isInTable(state)) {
    return tr
  }
  let rect = selectedRect(state)
  addColumn(tr, rect, rect.right)
  return tr
}
