import { makeObservable, observable } from 'mobx'
import { RiskalyzeClient } from '../types/graphql'
import { msg } from './msg'
import { createQuestionnaire, findClients } from './services/riskalyze.service'

class RiskalyzeStore {
  showModal = null
  _client = null
  _clientId = null
  _clients: RiskalyzeClient[] = []

  constructor() {
    makeObservable(this, {
      showModal: observable,
      _client: observable,
      _clientId: observable,
    })
  }

  findClients = async () => {
    global.app.loading = true
    try {
      const clients = await findClients()

      if (!Array.isArray(clients)) {
        msg.error(['Nitrogen clients', 'fetching'])
      } else {
        this._clients = clients
      }
    } catch (e) {
      msg.error(['Nitrogen clients', 'fetching'])
    }

    global.app.loading = false

    return this._clients
  }

  createQuestionnaire = async (riskalyzeClientId: string | number, type: string, amountInvested?: number) => {
    global.app.loading = true

    try {
      const link = await createQuestionnaire({
        questionnaire: { riskalyzeClientId, type, ...(amountInvested && { amountInvested: +amountInvested }) },
      })

      if (!link) {
        msg.error(['Nitrogen clients', 'fetching'])
        return
      }

      return link
    } catch (error) {
      global.app.loading = true
    }
  }
}

export default RiskalyzeStore
