import { fontsAndFallbacks } from './fonts_data'

function includeFaces(html: string) {
  const includedFonts: string[] = []
  fontsAndFallbacks.forEach(({ font, searchValue }) => {
    const htmlIncludesFontFamily = html.includes(searchValue)
    if (htmlIncludesFontFamily && !includedFonts.includes(font)) {
      console.log(`🐛 -> htmlIncludesFontFamily`, searchValue)
      includedFonts.push(font)
    }
  })

  return includedFonts.join('\n')
}

export function addFonts(html: string) {
  return `<style> ${includeFaces(html)} </style>`
}

/**
 * Adds a fix for missing characters in the Lato font, when used in PDFCrowd
 *
 * PDFCrowd v20.10 is using an old version of Lato font, which has some issues when exporting.
 * Mainly, when copying a string from the PDF, the characters are not copied correctly.
 *
 * Adding this link to the Lato font from Google Fonts fixes the issue.
 * @returns
 */
export function fixLatoFont() {
  return `<link href='http://fonts.googleapis.com/css?family=Lato' rel='stylesheet' type='text/css'>`
}
