import { makeObservable, observable } from 'mobx'
import { WealthboxContact } from '../types/graphql'

class WealthboxStore {
  showModal = null
  _contact = null
  _contactId: string | undefined
  _contacts: WealthboxContact[] = []

  constructor() {
    makeObservable(this, {
      showModal: observable,
      _contact: observable,
      _contactId: observable,
    })
  }
}

export default WealthboxStore
