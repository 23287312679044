import Icon from '@ant-design/icons'
import { useAuth } from '@clerk/clerk-react'
import type { OrganizationMembershipResource } from '@clerk/types'
import { Avatar, Select, SelectProps } from 'antd'
import { startCase } from 'lodash'
import { useCallback } from 'react'
import { GrOrganization } from 'react-icons/gr'
import { useMemberships } from 'stores/auth/hook'
import styled from 'styled-components'
import styles, { FlexRow } from 'styles'

interface StyledOrgProps {
  selected: boolean
}

const StyledOrg = styled(FlexRow)<StyledOrgProps>`
  padding: ${(p) => (p.selected ? 0 : 6)}px 0;
  gap: 12px;

  .ant-avatar {
    background-color: ${styles.colors.primary};
  }

  .org-name {
    font-size: 0.8125rem;
    font-weight: 600;
    font-family: Lato;
    padding-right: 12px;
  }
`

const StyledSelect = styled(Select)<SelectProps>`
  .ant-select-selection-search {
    padding-left: 32px;
    font-size: 0.8125rem;
    font-weight: 600;
    font-family: Lato;
  }
`

const caser = (str: string) => startCase(str.toLowerCase())

const sort = (mems: OrganizationMembershipResource[]) => {
  return mems.sort((a, b) => {
    return caser(a.organization.name)?.localeCompare(caser(b.organization.name), 'en', { sensitivity: 'base' })
  })
}

export const OrgSwitcher = () => {
  const { memberships, setActive } = useMemberships()
  const { orgId } = useAuth()

  const onSelect = useCallback(
    async (newOrgId: string) => {
      await setActive?.({ organization: newOrgId })
      global.auth.reload()
    },
    [setActive],
  )

  return (
    <>
      <StyledSelect
        size="large"
        onSelect={onSelect}
        value={orgId}
        showSearch
        dropdownMatchSelectWidth={false}
        filterOption={(input, option) => {
          return (`${option?.label}` ?? '').toLowerCase().includes(input.toLowerCase())
        }}
      >
        {sort(memberships)?.map((mem) => (
          <Select.Option key={mem.organization.id} label={mem.organization.name}>
            <StyledOrg selected={orgId === mem.organization.id}>
              <Avatar icon={<Icon component={GrOrganization} size={8} />} shape="square" size="small"></Avatar>
              <span className="org-name">{caser(mem.organization.name)}</span>
            </StyledOrg>
          </Select.Option>
        ))}
      </StyledSelect>
    </>
  )
}
