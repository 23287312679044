export const editorStyles = `
* {
  color: #000000;
}

body {
  font-size: 14px;
  font-family: "Lato", Avenir, Adobe Heiti Std, Segoe UI,
      Trebuchet MS, sans‑serif;
  font-variant: tabular-nums;
  line-height: 1.5;
  background-color: #fff;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  color: #000000;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.5;
}

a {
  color: #0000ee;
  text-decoration: none;
}

mark {
  color: #000000;
  padding: inherit;
}

a mark {
  color: #0000ee;
}

ul.task-list {
  list-style: none;
  padding-left: 20px;
  padding-inline-start: 20px;
}

ul.task-list li[data-task-list-item] {

}

ul.task-list li > .task-list-item-checkbox {
  float: left;
  margin-top: 3px;
  height: 15px;
  width: 15px;
}

ul.task-list li > .task-list-item-wrapper {
  margin-left: 23px;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  overflow: hidden;
}

table td {
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
}

table[data-border="dashed"] {
  border-color: #ccc;
  border-width: 1px;
}

img {
  max-width: 100%;
  min-width: 20px;
  object-fit: contain;
}

span, u, em, s, sup, sub {
  color: inherit;
}
`

export const editorPrintFromBrowserRules = editorStyles
