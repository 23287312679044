import { ApplySchemaAttributes, extension, ExtensionPriority, NodeSpecOverride } from '@remirror/core'
import { TableCellExtension } from '@remirror/react'
import { TableSchemaSpec } from 'remirror/dist-types/extensions'

import { createTableNodeSchema } from './TableCustomForExport.utils'

/**
 * The extension for a table cell node.
 */
@extension({ defaultPriority: ExtensionPriority.Low })
export class TableCellCustomForExportExtension extends TableCellExtension {
  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): TableSchemaSpec {
    return createTableNodeSchema(extra, override).tableCell
  }
}
