import Icon, { QuestionOutlined } from '@ant-design/icons'
// @ts-ignore
import { Button, Dropdown, MenuProps } from 'antd'
import { observer } from 'mobx-react'
import { HiSparkles } from 'react-icons/hi'
import { ImSpinner4 } from 'react-icons/im'
import { isDEV } from '../../../../helpers/env'

const HelpMenu = () => {
  const menuClick = ({ key }: { key: string }) => {
    switch (true) {
      case key === 'help-videos':
        global.router.goto('/help/videos/how-to')
        break
      case key === 'pulse-assist':
        /* isDEV  */
        break

      default:
        break
    }
  }

  const items: MenuProps['items'] = [
    ...(isDEV
      ? [
          {
            label: 'Pulse Assist',
            key: 'pulse-assist',
            icon: <Icon component={HiSparkles} />,
          },
        ]
      : []),
    {
      key: 'help-videos',
      icon: <Icon component={ImSpinner4} />,
      label: 'Contact Support',
    },
  ]

  return (
    <Dropdown menu={{ items, onClick: menuClick }} placement="bottomRight" trigger={['click']}>
      <Button shape="circle">
        <QuestionOutlined />
      </Button>
    </Dropdown>
  )
}

export default observer(HelpMenu)
