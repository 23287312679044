import * as apiService from 'lib/apollo/client'
import {
  WealthboxContact,
  WealthboxContactDocument,
  WealthboxContactQuery,
  WealthboxContactQueryVariables,
  WealthboxContactsDocument,
  WealthboxContactsQuery,
  WealthboxContactsQueryVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

export const loadContacts = async (): Promise<WealthboxContact[]> => {
  const { wealthboxContacts: resp = [] } = await apiService.query<
    WealthboxContactsQuery,
    WealthboxContactsQueryVariables
  >(WealthboxContactsDocument)
  if (IsError(resp)) {
    throw new DataError(resp)
  }

  return resp
}

export const loadContact = async (contactId: string): Promise<any> => {
  const { wealthboxContact: resp } = await apiService.query<
    WealthboxContactQuery,
    WealthboxContactQueryVariables
  >(WealthboxContactDocument, {
    contactId,
  })

  if (IsError(resp)) {
    throw new DataError(resp)
  }

  return resp as any
}
