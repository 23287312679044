import { ERRORS as BASE_ERRORS } from '../provider.errors'

export const ERRORS = {
  ...BASE_ERRORS,
  LOAD_CONTACTS_ERROR: 'Unable to load Contacts',
  LOAD_CONTACT_ERROR: 'Unable to load Contacts',
  NO_CONTACTS_ERROR: 'Unable to load Contacts',
  NO_CONTACT_ERROR: 'We could not find that Contact',
  NO_DATA_ERROR: 'There is no data for this Contact/Smartfield',
  UNEXPECTED_ERROR: "We're having trouble fetching your data",
}
