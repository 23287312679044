import { dateFormatter } from 'components/drawers/Smartfields/date/formatter'
import { integrationFormatter } from 'components/drawers/Smartfields/integration/formatter'
import { listFormatter } from 'components/drawers/Smartfields/list/formatter'
import { numberFormatter } from 'components/drawers/Smartfields/number/formatter'
import { textFormatter } from 'components/drawers/Smartfields/text/formatter'
import { SmartField } from 'components/drawers/Smartfields/types'
import {
  SmartFieldConfig,
  SmartFieldDateConfig,
  SmartFieldNumberConfig,
} from 'components/drawers/Smartfields/types/data'
import { isEmpty, isNumber } from 'lodash'
import { NoteReplacementValues } from '.'

/**
 *
 * @param values
 * @returns
 */
export const formatValues = (smartfield: SmartField, values: NoteReplacementValues): NoteReplacementValues => {
  return Object.keys(values).reduce((all, smartfieldId) => {
    return { ...all, [smartfieldId]: formatValue(smartfield, (values as any)[smartfieldId]) }
  }, {})
}

/**
 *
 * @param value
 * @returns
 */
export const formatValue = (smartfield: SmartField, value: any): any => {
  const { type } = smartfield.config

  if (value !== null && value !== undefined && value !== '') {
    switch (type) {
      case 'integration':
        value = formatDynamicValue(smartfield, value)
        break
      default:
        value = formatStandardValue(smartfield, value)
        break
    }
  } else {
    // if no value, set the original smartfield label as value
    return smartfield.label
  }

  return value
}

export const formatStandardValue = (smartfield: SmartField, value: any): any => {
  const { type } = smartfield.config

  switch (type) {
    case 'date':
      value = dateFormatter(smartfield, value)
      break
    case 'list':
      value = listFormatter(smartfield, value)
      break
    case 'number':
      value = numberFormatter(smartfield, value)
      break
    case 'text':
      value = textFormatter(smartfield, value)
      break
  }

  return value
}

export const formatDynamicValue = (smartfield: SmartField, value: any): any => {
  value = integrationFormatter(smartfield, value)

  // if there's number config, apply number formatter
  const numConfig = smartfield.config as SmartFieldConfig<SmartFieldNumberConfig>
  if (!isEmpty(numConfig.format) || isNumber(numConfig?.places)) {
    if (!isNaN(value)) {
      value = numberFormatter(smartfield, Number(value))
    }
  }

  // if there's date config, apply date formatter
  const dateConfig = smartfield.config as SmartFieldConfig<SmartFieldDateConfig>
  if (!isEmpty(dateConfig.date)) {
    value = dateFormatter(smartfield, value)
  }

  return value
}
