import { Mark } from '@remirror/pm/dist-types/model'

export function emptyMergeFieldContent(mergeFieldType: string, pos: number, marks: readonly Mark[]) {
  return {
    type: mergeFieldType,
    pos: pos,
    content: [],
    contentSize: 0,
    marks,
  }
}
