import { Button, Card, Col, Modal, Row } from 'antd'
import styled from 'styled-components'
import styles from 'styles'
import { usePlans } from './hook'

const Wrapper = styled(Card)``

const StyledCard = styled(Card)`
  height: 100%;
  position: relative;

  cursor: pointer;
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 16px; */
  transition: all 0.2s ease 0s;
  /*   :hover {
    box-shadow: rgb(0 0 0 / 9%) 0px 2px 24px;
    transform: scale(1.08);
  } */
  h3 {
    text-align: center;
    line-height: 1.4rem;
  }
`

const StyledShortcutCards = styled(StyledCard)`
  text-align: center;

  h3 {
    font-size: 1.5em;
  }

  &.disabled {
    filter: grayscale(100%);
    cursor: not-allowed;
  }

  .ant-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    ${styles.media.tabletDown(`padding: 12px;`)}
  }
`

const EqualCol = styled(Col)`
  flex: 1 0 0%;
`

const ViewMore = styled.a`
  color: ${styles.colors.black};

  &:hover {
    color: ${styles.colors.green};
  }
`

const Footer = ({ onCancel }: { onCancel: () => void }) => {
  return (
    <Row justify="space-between" align="middle">
      <Col>
        <ViewMore
          href="https://www.pulse360.com/upgrade"
          rel="noreferrer"
          target="_blank"
        >
          Review Upgrade Pricing
        </ViewMore>
      </Col>
      <Col>
        <Button type="default" onClick={onCancel}>
          Cancel
        </Button>
      </Col>
    </Row>
  )
}

function UpgradePlanModal() {
  const { showModal, plans, onUpgradePlan } = usePlans()

  return (
    <Modal
      width={700}
      zIndex={1002}
      title="Upgrade to unlock more automation"
      open={showModal}
      onOk={() => global.perms?.setUpgradeModalVisible(false)}
      onCancel={() => global.perms?.setUpgradeModalVisible(false)}
      footer={
        <Footer onCancel={() => global.perms?.setUpgradeModalVisible(false)} />
      }
    >
      <Wrapper bordered={false}>
        <Row gutter={16}>
          {plans.map((plan) => (
            <Col span={8} key={plan.id}>
              <StyledShortcutCards
                className={
                  plan.id === global.data.practice.planId ? 'disabled' : ''
                }
              >
                <Row justify="center">
                  <h3>{plan.name}</h3>
                </Row>
                <EqualCol>
                  <Row justify="center">{plan.description}</Row>
                </EqualCol>
                <Row justify="center">
                  <Button
                    type="primary"
                    onClick={() => onUpgradePlan('' + plan.id)}
                    disabled={plan.id === global.data.practice.planId}
                    style={{ marginTop: 15 }}
                    block
                  >
                    {plan.id === global.data.practice.planId
                      ? 'Current Plan'
                      : 'Upgrade'}
                  </Button>
                </Row>
              </StyledShortcutCards>
            </Col>
          ))}
        </Row>
      </Wrapper>
    </Modal>
  )
}

export default UpgradePlanModal
