import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import styles from 'styles'

const ImageTile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .ant-progress {
    position: absolute;
  }
  img {
    max-height: 100%;
    width: auto;
    max-width: 100%;
  }
  .load-progress {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .load-icon {
      font-size: 24px;
      color: ${styles.colors.white};
    }
  }
`

const Image = (props) => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(true)
  }, [props.src])
  return (
    <ImageTile className={props.className} style={props.style} alt={props.alt}>
      <img
        onError={() => setLoading(false)}
        onLoad={() => setLoading(false)}
        crossOrigin="anonymous"
        src={props.src}
        alt=""
      />
      {loading && (
        <div className="load-progress">
          <Loading3QuartersOutlined className="load-icon" spin />
        </div>
      )}
    </ImageTile>
  )
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
}

Image.defaultProps = {}

export default Image
