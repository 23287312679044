import { isObjectLike } from 'lodash'
import Str from 'stores/util/str.util'
import { clientsFields } from './data.constants'
export interface CascaderNode {
  value: string
  label: string
  children?: CascaderNode[]
}

/**
 * @param fields
 * @param extra
 * @returns
 */
const parseObject = (fields: any): CascaderNode[] => {
  return Object.keys(fields).map((key) => {
    let field = (fields as any)[key]
    let isArray = Array.isArray(field)
    let isString = typeof field === 'string'

    const label = isString ? field : Str.startCase(key)
    const node: CascaderNode = {
      value: key,
      label: label,
    }

    // if an array, use the first child object to construct the node with.
    // we also need to add a prop to stipulate that when parsing the actual
    // data, we treat this node as an array not an object
    if (isArray) {
      field = field?.[0] ?? {}
    }

    if (isObjectLike(field)) {
      node.children = parseObject(field)
    }

    return node
  })
}

/**
 * @param type
 * @returns
 */
export const cascaderOptions = () => {
  return parseObject(clientsFields)
}

/**
 * @param field
 * @returns
 */
export const parseDataFields = (field: any) => {
  if (Array.isArray(field)) {
    return field
  }

  if (Str.isJSON(field)) {
    return JSON.parse(field)
  }

  return null
}

export const modelName = (model: any) => {
  return Str.startCase(model.title ?? model.name ?? model.slug)
}

export type ModelSelects = {
  title: string
  data: any
}

export const parseRequiredSelects = (client: any, dataFields: any, indices: number[], onUpdate: (val: any) => void) => {
  const allFields = parseDataFields(dataFields) // 4
  const fields = [...allFields]

  let selects: ModelSelects[] = []
  let currentObj = client

  while (currentObj) {
    const currentIndex = allFields.length - fields.length
    const currentField = fields.shift() // 3

    let fieldName = currentField.replace('[]', '')
    let nextObj = currentObj[fieldName]

    if (Array.isArray(nextObj)) {
      selects.push({
        title: Str.startCase(fieldName),
        data: nextObj,
      })

      if (indices[currentIndex] !== undefined) {
        currentObj = nextObj[indices[currentIndex]]
      } else {
        currentObj = undefined
      }
    } else if (isObjectLike(nextObj)) {
      currentObj = nextObj
    } else {
      currentObj = undefined
      onUpdate(nextObj)
    }
  }

  return selects
}
