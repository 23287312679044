import { ApplySchemaAttributes, extension, ExtensionPriority, NodeSpecOverride } from '@remirror/core'
import { TableControllerCellExtension, TableHeaderCellExtension, TableRowExtension } from '@remirror/react'
import { TableSchemaSpec } from 'remirror/dist-types/extensions'
import { TableCellCustomForExportExtension } from './TableCellCustomForExport.extension'

import { createTableNodeSchema } from './TableCustomForExport.utils'

@extension({ defaultPriority: ExtensionPriority.Low })
export class TableRowCustomForExportExtension extends TableRowExtension {
  /**
   * Automatically create the `TableCellExtension` and
   * `TableHeaderCellExtension`. This is placed here so that this extension can
   * be tested independently from the `TableExtension`.
   */
  createExtensions(): Array<
    TableCellCustomForExportExtension | TableHeaderCellExtension | TableControllerCellExtension
  > {
    return [
      new TableCellCustomForExportExtension({ priority: ExtensionPriority.Low }),
      new TableHeaderCellExtension({ priority: ExtensionPriority.Low }),
    ]
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): TableSchemaSpec {
    return createTableNodeSchema(extra, override).tableRow
  }
}
