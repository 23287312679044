/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  SmartFieldConfig,
  SmartFieldIntegrationConfig,
} from 'components/drawers/Smartfields/types/data'
import {
  RiskalyzeConfig,
  RiskalyzeDataConfig,
} from 'components/drawers/Smartfields/types/integration'
import { useEffect } from 'react'
import { ProviderSmartFieldsBaseProps } from '../../../provider.interfaces'
import { ProviderSmartFields } from '../../../provider.smartfields'
import { useRiskalyzeModelData } from '../../riskalyze.hooks'
import { RiskalyzeClients } from '../riskalyze.clients'
import { RiskalyzeDataSelector } from './components/data.selector'

interface RiskalyzeDataSmartFieldsProps extends ProviderSmartFieldsBaseProps {
  config: SmartFieldConfig<SmartFieldIntegrationConfig<RiskalyzeConfig>>
}

export const RiskalyzeDataSmartFields: React.FC<
  RiskalyzeDataSmartFieldsProps
> = ({ form, config, uid, setAsyncLoading }) => {
  const modelConfig = config as SmartFieldConfig<
    SmartFieldIntegrationConfig<RiskalyzeConfig<RiskalyzeDataConfig>>
  >

  const { client, clients, loading, error, loadingClient } =
    useRiskalyzeModelData(modelConfig, form, uid)

  useEffect(() => {
    if (setAsyncLoading) {
      setAsyncLoading(loadingClient)
    }
  }, [loadingClient, setAsyncLoading])

  return (
    <ProviderSmartFields
      form={form}
      uid={uid}
      error={error}
      data-testid="replace-smartfield-date"
      showOnError
      renderOnError={() => (
        <RiskalyzeClients
          form={form}
          formName="model_contact"
          clients={clients}
          loading={loading}
        />
      )}
    >
      <RiskalyzeDataSelector
        form={form}
        dataFields={modelConfig.data_fields}
        client={client}
        uid={uid}
      />
    </ProviderSmartFields>
  )
}
