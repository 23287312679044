import { DISPLAY_MODE } from 'lib/remirror/extensions/smartFieldAtom/utils/displayMode.constant'
import { EditorState } from 'remirror'
import { getNotesByTypeAndTags, getNotesNodesList } from '../../NoteReplacer.helpers'
import { MergeFieldContentToReplace, MergeFieldToReplace, NotesGroup } from '../../NoteReplacer.interfaces'
import { emptyMergeFieldContent } from './utils/emptyMergeFieldContent.util'
import { fixGroupingList } from './utils/fixGroupingList.util'
import { TopicTitle } from './utils/topicTitle.util'
import { hasValidSubtopic } from './utils/hasValidSubtopic.util'

/**
 * Processes a MergeFieldToReplace item, and returns it wrapped in a ListItem.
 * @param state
 * @param mergeFieldToReplace
 * @param notesGroup
 * @returns
 */
export function processMergeFieldToReplace(
  state: Readonly<EditorState>,
  mergeFieldToReplace: MergeFieldToReplace,
  notesGroup: NotesGroup[],
): MergeFieldContentToReplace {
  const { header, tags: placeholderTags, mergeFieldType, kind, marks, pos, parentAttrs } = mergeFieldToReplace

  const notesForPlaceholder = getNotesByTypeAndTags(notesGroup, mergeFieldType, placeholderTags)

  if (!notesForPlaceholder?.length || !hasValidSubtopic(mergeFieldToReplace)) {
    return emptyMergeFieldContent(mergeFieldType, pos, marks)
  }

  /*   const noteNodes = notesForPlaceholder.map((note) => {
    const attrs = note.status === 'Complete' ? { checked: true } : undefined

    return state.schema.nodes.taskListItem.create({ ...parentAttrs, ...attrs }, note.content)
  }) */

  const notesContent = getNotesNodesList(state, notesForPlaceholder, mergeFieldToReplace)

  const noteNodes = notesContent.map((noteNode, index) => {
    const note = notesForPlaceholder[index]

    const attrs = note.status === 'Complete' ? { checked: true } : null

    return state.schema.nodes.taskListItem.create(attrs, noteNode)
  })

  const noteGroupList = state.schema.nodes.taskList.create(null, noteNodes)

  const content = []

  const title = TopicTitle({ kind, header, state, mergeFieldType, placeholderTags, marks, attrs: parentAttrs })

  if (!title) {
    // FIX OL: not grouping lists
    fixGroupingList(state, pos, DISPLAY_MODE.CHECKLIST, (value) => content.push(value))
  }

  if (title) {
    content.push(title)
  }

  content.push(noteGroupList)

  const contentSize = content.reduce((accumulator, node) => accumulator + node.nodeSize, 0)

  return {
    type: mergeFieldType,
    pos,
    content,
    contentSize,
    marks,
  }
}
