import * as React from 'react'

function IconNounInlineArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 1200 1200" fill="currentColor" {...props}>
      <path d="m900 1050c-13.289 0.078125-26.062-5.1406-35.5-14.5-9.4648-9.3867-14.789-22.168-14.789-35.5s5.3242-26.113 14.789-35.5l165-164.5-165-164.5c-12.684-12.684-17.637-31.168-12.992-48.492 4.6406-17.328 18.172-30.859 35.5-35.5 17.324-4.6445 35.809 0.30859 48.492 12.992l200 200c9.4648 9.3867 14.789 22.168 14.789 35.5s-5.3242 26.113-14.789 35.5l-200 200c-9.4375 9.3594-22.211 14.578-35.5 14.5z" />
      <path d="m1100 850h-1e3c-17.863 0-34.371-9.5312-43.301-25-8.9336-15.469-8.9336-34.531 0-50 8.9297-15.469 25.438-25 43.301-25h554c-93.035-58.5-169.72-139.64-222.88-235.82-53.164-96.184-81.074-204.28-81.117-314.18 0-17.863 9.5312-34.371 25-43.301 15.469-8.9336 34.531-8.9336 50 0 15.469 8.9297 25 25.438 25 43.301 0 145.87 57.945 285.77 161.09 388.91 103.14 103.14 243.04 161.09 388.91 161.09h100c17.863 0 34.371 9.5312 43.301 25 8.9336 15.469 8.9336 34.531 0 50-8.9297 15.469-25.438 25-43.301 25z" />
    </svg>
  )
}

export default IconNounInlineArrow
