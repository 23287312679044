import * as apiService from 'lib/apollo/client'
import { EntityId } from 'types/entity.interface'
import {
  AddCollectionItemsDocument,
  AddCollectionItemsMutation,
  AddCollectionItemsMutationVariables,
  CollectionInput,
  CollectionsDocument,
  CollectionsQuery,
  CollectionsQueryVariables,
  CreateCollectionDocument,
  CreateCollectionMutation,
  CreateCollectionMutationVariables,
  DeleteCollectionDocument,
  DeleteCollectionMutation,
  DeleteCollectionMutationVariables,
  SetCollectionItemsDocument,
  SetCollectionItemsMutation,
  SetCollectionItemsMutationVariables,
  UpdateCollectionDocument,
  UpdateCollectionMutation,
  UpdateCollectionMutationVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

class CollectionService {
  async getCollections() {
    const { collections: resp } = await apiService.query<CollectionsQuery, CollectionsQueryVariables>(
      CollectionsDocument,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.collections
  }

  async createCollection(collection: CollectionInput) {
    const { createCollection: resp } = await apiService.mutate<
      CreateCollectionMutation,
      CreateCollectionMutationVariables
    >(CreateCollectionDocument, { collection })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.result
  }

  async updateCollection(id: EntityId, collection: CollectionInput) {
    const { updateCollection: resp } = await apiService.mutate<
      UpdateCollectionMutation,
      UpdateCollectionMutationVariables
    >(UpdateCollectionDocument, { id, collection })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async deleteCollection(id: EntityId) {
    const { deleteCollection: resp } = await apiService.mutate<
      DeleteCollectionMutation,
      DeleteCollectionMutationVariables
    >(DeleteCollectionDocument, { id })

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async addCollectionItems(collectionId: EntityId, items: EntityId[]) {
    const { addCollectionItems: resp } = await apiService.mutate<
      AddCollectionItemsMutation,
      AddCollectionItemsMutationVariables
    >(AddCollectionItemsDocument, { collectionId, items })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async setCollectionItems(collectionId: EntityId, items: EntityId[]) {
    const { setCollectionItems: resp } = await apiService.mutate<
      SetCollectionItemsMutation,
      SetCollectionItemsMutationVariables
    >(SetCollectionItemsDocument, { collectionId, items })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }
}

export default new CollectionService()
