import * as apiService from 'lib/apollo/client'
import {
  AddWorkflowDocument,
  AddWorkflowMutation,
  AddWorkflowMutationVariables,
  DeleteWorkflowDocument,
  DeleteWorkflowMutation,
  DeleteWorkflowMutationVariables,
  FindWorkflowCrmTemplatesDocument,
  FindWorkflowCrmTemplatesQuery,
  FindWorkflowCrmTemplatesQueryVariables,
  UpdateWorkflowDocument,
  UpdateWorkflowMutation,
  UpdateWorkflowMutationVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

class WorkflowService {
  async findWorkflowCrmTemplates() {
    const { findWorkflowCrmTemplates: resp } = await apiService.query<
      FindWorkflowCrmTemplatesQuery,
      FindWorkflowCrmTemplatesQueryVariables
    >(FindWorkflowCrmTemplatesDocument)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.workflowCrmTemplates
  }

  async createWorkflow(payload: AddWorkflowMutationVariables) {
    const { addWorkflow: resp } = await apiService.mutate<AddWorkflowMutation, AddWorkflowMutationVariables>(
      AddWorkflowDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async deleteWorkflow(payload: DeleteWorkflowMutationVariables) {
    const { deleteWorkflow: resp } = await apiService.mutate<DeleteWorkflowMutation, DeleteWorkflowMutationVariables>(
      DeleteWorkflowDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async updateWorkflow(payload: UpdateWorkflowMutationVariables) {
    const { updateWorkflow: resp } = await apiService.mutate<UpdateWorkflowMutation, UpdateWorkflowMutationVariables>(
      UpdateWorkflowDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }
}

export default new WorkflowService()
