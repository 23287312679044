/* eslint-disable no-return-await, no-useless-escape */
import { runInAction } from 'mobx'
import { captureExceptionSilently } from '../helpers/sentry'
import { msg } from './msg'
import filesService from './services/files.service'

class Files {
  /**
   * Load all files for a given s3 path
   */
  load = async (path = '') => {
    global.app.loading = true
    try {
      const files = await filesService.loadFiles(path)
      return files
    } catch (err) {
      captureExceptionSilently(err, { message: 'loadFiles', data: { path } })
      msg.error(err.message)
      throw err
    } finally {
      global.app.loading = false
    }
  }

  sign = async (url = '') => {
    global.app.loading = true
    try {
      const signedURL = await filesService.signFileURL(url)
      return signedURL
    } catch (err) {
      captureExceptionSilently(err, { message: 'signFileURL', data: { url } })
      msg.error(err.message)
      throw err
    } finally {
      global.app.loading = false
    }
  }

  /**
   * Delete a file from s3
   */
  delete = async (key) => {
    global.app.loading = true
    try {
      const deletedFileKey = await filesService.deleteFile(key)
      return deletedFileKey
    } catch (err) {
      captureExceptionSilently(err, { message: 'deleteFile', data: { key } })
      msg.error(err.message)
      throw err
    } finally {
      global.app.loading = false
    }
  }

  /**
   * Create a new 'folder'
   */
  createFolder = async (key) => {
    global.app.loading = true
    try {
      const file = await filesService.createFolder(key)
      return file
    } catch (err) {
      captureExceptionSilently(err, { message: 'createFolder', data: { key } })
      msg.error(err.message)
      throw err
    } finally {
      global.app.loading = false
    }
  }

  /**
   * Update a File
   */
  update = async (file, fields) => {
    global.app.loading = true
    try {
      const uploadedFile = await filesService.updateFile(file.id, fields)
      msg.success('File updated successful')

      runInAction(() => {
        Object.keys(fields).forEach((key) => {
          file[key] = fields[key]
        })
      })

      return uploadedFile
    } catch (err) {
      captureExceptionSilently(err, { message: 'updateFile', data: { key: file.id } })
      msg.error(err.message)
      throw err
    } finally {
      global.app.loading = false
    }
  }

  async prepareFileToUpload(file) {
    if (file.uid) {
      file = {
        id: file.uid,
        name: file.name,
        contentType: file.type,
        encoding: 'base64',
        content: await global.files.getBase64FromLocalFile(file),
      }
    }
    return file
  }

  /**
   * Upload a File to s3
   */
  upload = async (file, key) => {
    global.app.loading = true
    try {
      const uploadedFile = await filesService.uploadFile(file, key)
      msg.success('File upload successful')
      return uploadedFile
    } catch (err) {
      captureExceptionSilently(err, { message: 'uploadFile', data: { key } })
      msg.error(err.message)
      throw err
    } finally {
      global.app.loading = false
    }
  }

  /**
   * @param {*} file
   */
  getBase64FromLocalFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result.toString())
      }
      reader.onerror = (error) => reject(error)
    })
  }

  /**
   * Upload a base64 file to s3
   */
  uploadBase64 = async (base64, key) => {
    const name = key.replace(/^.*[\\\/]/, '')

    const file = await this._base64ToFile(base64, `${__dirname}/${name}`)
    return this.upload(file, key)
  }

  /* ---------- private ---------- */

  /**
   *
   */
  _base64ToFile = (src, fileName, mimeType = 'image/png') => {
    return fetch(src)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], fileName, { type: mimeType }))
  }
}

export default Files
