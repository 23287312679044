import * as apiService from 'lib/apollo/client'
import { EntityId } from 'types/entity.interface'
import {
  PracticeInput,
  UpdatePracticeDocument,
  UpdatePracticeMutation,
  UpdatePracticeMutationVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

class PracticeService {
  async updatePractice(id: EntityId, fields: PracticeInput) {
    const { updatePractice: resp } = await apiService.mutate<UpdatePracticeMutation, UpdatePracticeMutationVariables>(
      UpdatePracticeDocument,
      {
        practice: fields,
        id,
      },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }
}

export default new PracticeService()
