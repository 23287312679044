import log from './util/log'

export default function GLOB() {
  // additional helper classes bind their
  // available methods to the glob global

  log(global)

  return global
}
