import {
  SmartFieldConfig,
  SmartFieldIntegrationConfig,
} from 'components/drawers/Smartfields/types/data'
import {
  GenericConnectorFormValues,
  SmartFieldFormValues,
  SmartFieldIntegrationFormValues,
} from 'components/drawers/Smartfields/types/form'
import { GenericConnectorConfig } from 'components/drawers/Smartfields/types/integration'
import { ConfigParser, FormParser } from '../provider.interfaces'

export const genericParser: FormParser = (values, config) => {
  const cfg = config as SmartFieldIntegrationConfig<GenericConnectorConfig>
  const vals = values as SmartFieldFormValues<
    SmartFieldIntegrationFormValues<GenericConnectorFormValues>
  >

  cfg.data_fields = vals.data_fields
  return config as SmartFieldConfig
}

export const genericConfigParser: ConfigParser = (values, config) => {
  const cfg = config as SmartFieldConfig<
    SmartFieldIntegrationConfig<GenericConnectorConfig>
  >
  const vals = values as SmartFieldFormValues<
    SmartFieldIntegrationFormValues<GenericConnectorFormValues>
  >

  vals.data_fields = cfg.data_fields
  return vals
}
