import { Alert, Form } from 'antd'
import { ConnectionBuilder } from '../connection.builder'
import { useConnection } from '../connection.hooks'

export const RecallZoomBuilder: React.FC = () => {
  const { connection } = useConnection('recall_zoom')

  return (
    <ConnectionBuilder
      provider="recall_zoom"
      title="Recall Zoom"
      learnMoreLink="https://www.recall.com/"
      connection={connection}
      key1="dummy-pulse360"
      key2="dummy"
      renderSettings={(form, submit) => (
        <Form onFinish={submit} form={form}>
          <Alert
            message="Click the 'Connect' button to connect your account"
            type="info"
            showIcon
            // style={{ marginBottom: 16 }}
          />
          <Form.Item name="key1">
            <div />
          </Form.Item>
          <Form.Item name="key2">
            <div />
          </Form.Item>
        </Form>
      )}
    />
  )
}
