/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useViewport } from 'components/hooks/useViewport'
import { isEqual } from 'lodash'
import { autorun } from 'mobx'
import { useCallback, useEffect, useState } from 'react'
import {
  AddSmartFieldSettings,
  EditSmartFieldSettings,
  SmartFieldSettingsMode,
  smartfieldStore,
} from 'stores/smartfields'
import { smartfieldFactory as factory } from 'stores/smartfields/factory'
import Str from '../../../stores/util/str.util'
import { mentionToSmartField, smartfieldToForm, smartfieldToMention } from './smartfield.convertors'
import { saveSmartField } from './smartfield.utils'
import { SmartField, SmartFieldMentionAttrs } from './types'
import { SmartFieldFormValues } from './types/form'

export const useSmartFields = () => {
  const [smartfield, setSmartfield] = useState<SmartField | undefined>()
  const [mode, setMode] = useState<SmartFieldSettingsMode>('closed')
  const [visible, setVisible] = useState<boolean>(false)
  const [forceReuse, setForceReuse] = useState<boolean | undefined>(false)

  const showAddSmartFieldDrawer = useCallback(
    (callback: (mentionAttrs: SmartFieldMentionAttrs) => void, forceReusable = false) => {
      smartfieldStore.setSettings<AddSmartFieldSettings>({
        callback,
        mode: 'add',
        forceReusable,
      })
    },
    [],
  )

  const showEditSmartFieldDrawer = useCallback(
    (callback: (mentionAttrs: SmartFieldMentionAttrs) => void, attrs: SmartFieldMentionAttrs) => {
      const mention = smartfieldToMention(factory.getSmartfieldSync(attrs))
      smartfieldStore.setSettings<EditSmartFieldSettings>({
        callback,
        mode: 'edit',
        attrs: mention,
        forceReusable: false,
      })
    },
    [],
  )

  /**
   * Hide the SmartField drawer
   */
  const hideSmartFieldDrawer = useCallback(() => {
    smartfieldStore.setSettings({ mode: 'closed' })
  }, [])

  /**
   * Inserts the new SmartField into remirror
   */
  const onInsertSmartField = useCallback(async (formValues: SmartFieldFormValues) => {
    const { callback, forceReusable } = smartfieldStore.settings as AddSmartFieldSettings
    const values = { ...formValues, reuse: forceReusable ? true : formValues.reuse }

    const mention = await saveSmartField(values)
    smartfieldStore.setSettings({ mode: 'closed' })

    callback?.(mention)
  }, [])

  /**
   * Updates the remirror merge attrs
   */
  const onUpdateSmartField = useCallback(async (formValues: SmartFieldFormValues) => {
    const { callback, attrs } = smartfieldStore.settings as EditSmartFieldSettings

    const mention = await saveSmartField({ ...attrs, ...formValues })
    smartfieldStore.setSettings({ mode: 'closed' })

    callback?.(mention)
  }, [])

  useEffect(() => {
    return autorun(() => {
      const { settings } = smartfieldStore

      if (settings.mode === 'add') {
        setSmartfield(undefined)
      } else if (settings.mode === 'edit') {
        const { attrs } = smartfieldStore.settings as EditSmartFieldSettings

        setSmartfield(mentionToSmartField(attrs))
      } else if (settings.mode === 'closed') {
        setSmartfield(undefined)
      }

      setForceReuse(settings.forceReusable)
      setVisible(settings.mode !== 'closed')
      setMode(settings.mode)
    })
  }, [])

  return {
    showAddSmartFieldDrawer,
    showEditSmartFieldDrawer,
    hideSmartFieldDrawer,
    onInsertSmartField,
    onUpdateSmartField,
    visible,
    mode,
    smartfield,
    forceReuse,
  }
}

export const useSmartfieldModal = () => {
  const [modalWidth, setModalWidth] = useState<string>('500px')

  const { isDesktopXL, isDesktop, isLaptopUp } = useViewport()

  useEffect(() => {
    switch (true) {
      case isDesktopXL:
        setModalWidth('600px')
        break

      case isDesktop && !isDesktopXL:
        setModalWidth('500px')
        break

      case isLaptopUp && !isDesktop:
        setModalWidth('400px')
        break

      default:
        setModalWidth('300px')
        break
    }
  }, [isDesktopXL, isDesktop, isLaptopUp])

  return {
    modalWidth,
  }
}

export const useSmartfieldChanged = () => {
  const [changed, setChanged] = useState<boolean>(false)

  const { smartfield } = useSmartFields()

  const onChange = useCallback(
    (_changedValues: any, values: any) => {
      if (smartfield && smartfield.reuse) {
        const smartfieldForm = smartfieldToForm(smartfield)
        const formValues = { ...values, label: Str.startCase(values.label) }

        setChanged(!isEqual(smartfieldForm, formValues))
      }
    },
    [smartfield],
  )

  return {
    onChange,
    changed,
  }
}
