/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Form, FormInstance, Radio, RadioChangeEvent } from 'antd'
import { FormItemLabel } from 'components/drawers/Smartfields/smartfield.styles'
import { SmartField } from 'components/drawers/Smartfields/types'
import { RiskalyzeConfig, RiskalyzeQuestionaireConfig } from 'components/drawers/Smartfields/types/integration'
import { useCallback, useEffect } from 'react'
import Str from '../../../../../../../../stores/util/str.util'

interface SmartFieldIntegrationRiskalyzeQuestionaireProps {
  form: FormInstance
  smartfield?: SmartField
  config: RiskalyzeConfig<RiskalyzeQuestionaireConfig>
}

export const SmartFieldIntegrationRiskalyzeQuestionaire: React.FC<SmartFieldIntegrationRiskalyzeQuestionaireProps> = ({
  form,
  config,
}) => {
  const initialValue = config?.questionaire_type ?? 'next_gen'

  const onChangeQuestionaireType = useCallback(
    (e: RadioChangeEvent) => {
      form.setFieldValue('label', `${Str.startCase(e.target.value)} Questionnaire`)
    },
    [form],
  )

  useEffect(() => {
    form.setFieldValue('label', `${Str.startCase(initialValue)} Questionnaire`)
  }, [])

  return (
    <>
      <Form.Item
        name="questionaire_type"
        rules={[{ required: true, message: 'Select questionaire type' }]}
        label={<FormItemLabel label="Questionnaire type" description="Select Questionnaire type" />}
        initialValue={config?.questionaire_type ?? 'next_gen'}
      >
        <Radio.Group onChange={onChangeQuestionaireType}>
          <Radio value="next_gen">Next-Gen Risk</Radio>
          <Radio value="simple">Simple</Radio>
          <Radio value="detailed">Detailed</Radio>
        </Radio.Group>
      </Form.Item>
    </>
  )
}
