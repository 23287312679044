import { Node } from '@remirror/pm/dist-types/model'
import { EditorState, replaceNodeAtPosition, Transaction } from 'remirror'
import { SmartFieldKind } from 'stores/smartfields/constants'

type NodeToFix = { node: Node; pos: number }

export function prepareTemplate(tr: Transaction, state: Readonly<EditorState>) {
  const nodesToFix: NodeToFix[] = getNodesToFix(tr)

  if (!nodesToFix.length) {
    return tr
  }

  rewriteTemplate(tr, state, nodesToFix.reverse())

  return tr
}

function getNodesToFix(tr: Transaction) {
  const nodesToFix: NodeToFix[] = []
  tr.doc.descendants((node, pos, parent, index) => {
    if (node.type.name === 'paragraph') {
      if (checkIfNeedFix(node)) {
        nodesToFix.push({ node, pos: pos })
      }
    }
  })

  return nodesToFix
}

function checkIfNeedFix(paragraph: Node): boolean {
  let mentionAtoms = 0

  paragraph.forEach((node) => {
    if (
      node.type.name === 'mentionAtom' &&
      (node.attrs.kind === SmartFieldKind.TOPIC ||
        node.attrs.kind === SmartFieldKind.PLACERHOLDER ||
        node.attrs.kind === SmartFieldKind.CONTACT) &&
      node.attrs.displayMode !== 'inline'
    ) {
      mentionAtoms++
    }
  })

  if (!mentionAtoms) {
    // no mentionAtom
    return false
  } else if (paragraph.childCount === 1 && mentionAtoms === 1) {
    // nothing to fix
    return false
  }

  return true
}

function rewriteTemplate(
  tr: Transaction,
  state: Readonly<EditorState>,
  nodesToFix: NodeToFix[],
) {
  for (const nodeToFix of nodesToFix) {
    const rewritedContent: Node[] = []

    nodeToFix.node.content.forEach((node, offset, index) => {
      if (node.type.name === 'mentionAtom') {
        fixMentionAtom(node, state, (newNode) => rewritedContent.push(newNode))
      }
      if (node.type.name === 'text') {
        fixText(node, state, (newNode) => rewritedContent.push(newNode))
      }

      if (node.type.name === 'hardBreak') {
        fixHardBreak(node, nodeToFix.node, index, state, (newNode) =>
          rewritedContent.push(newNode),
        )
      }
    })

    replaceNodeAtPosition({
      tr,
      pos: nodeToFix.pos,
      content: rewritedContent,
    })
  }
}

function fixMentionAtom(
  node: Node,
  state: Readonly<EditorState>,
  cb: (node: Node) => void,
) {
  if (node.attrs.kind === 'topic' && node.attrs.displayMode !== 'inline') {
    return cb(state.schema.nodes.paragraph.create(null, node))
  }

  return cb(node)
}

function fixText(
  node: Node,
  state: Readonly<EditorState>,
  cb: (node: Node) => void,
) {
  if (node.text?.trim()) {
    return cb(node)
  }
}

function fixHardBreak(
  node: Node,
  parent: Node,
  index: number,
  state: Readonly<EditorState>,
  cb: (node: Node) => void,
) {
  const prev = parent.content.maybeChild(index - 1)
  const next = parent.content.maybeChild(index + 1)
  if (prev?.type.name === 'hardBreak' || next?.type.name === 'hardBreak') {
    return cb(node)
  }
}
