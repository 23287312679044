export interface Directives {
  childSrc: string[]
  connectSrc: string[]
  defaultSrc: string[]
  fontSrc: string[]
  formAction: string[]
  frameSrc: string[]
  imgSrc: string[]
  mediaSrc: string[]
  scriptSrc: string[]
  styleSrc: string[]
  workerSrc: string[]
}

export type Env = 'dev' | 'staging' | 'prod'

export const baseConfig = (env: Env): Directives => ({
  childSrc: [
    'https://www.youtube.com',
    '*.vimeo.com',
    'https://fast.wistia.net',
    'https://intercom-sheets.com',
    'https://www.intercom-reporting.com',
    'https://www.youtube.com',
    'https://player.vimeo.com',
    'https://fast.wistia.net',
  ],
  connectSrc: [
    "'self'",
    'data:',
    `https://app.${env}.pulse360.com`,
    `https://app-clerk.${env}.pulse360.com`,
    `https://api.${env}.pulse360.com`,
    `https://api-clerk.${env}.pulse360.com`,
    `wss://api.${env}.pulse360.com`,
    `wss://api-clerk.${env}.pulse360.com`,
    '*.amazonaws.com',
    '*.googletagmanager.com',
    '*.google-analytics.com',
    'www.googleapis.com',
    'sentry.io',
    '*.sentry.io',
    '*.segment.com',
    '*.segment.io',
    '*.amplitude.com',
    'https://socket.coview.com',
    'wss://socket.coview.com',
    'https://stats.g.doubleclick.net',
    '*.zoho.com',
    '*.zohopublic.com',
    'wss://vts.zohopublic.com',
    '*.posthog.com',
    '*.helphero.co',
    'https://pshg.prod.pulse360.com',
    'https://api.pdfcrowd.com',
    '*.codox.io',
    'wss://dsm.codox.io',
    '*.intercom.io',
    'wss://nexus-websocket-a.intercom.io',
    'https://api.intercom.io',
    'https://api.au.intercom.io',
    'https://api.eu.intercom.io',
    'https://api-iam.intercom.io',
    'https://api-iam.eu.intercom.io',
    'https://api-iam.au.intercom.io',
    'https://api-ping.intercom.io',
    'https://nexus-websocket-a.intercom.io',
    'wss://nexus-websocket-a.intercom.io',
    'https://nexus-websocket-b.intercom.io',
    'wss://nexus-websocket-b.intercom.io',
    'https://nexus-europe-websocket.intercom.io',
    'wss://nexus-europe-websocket.intercom.io',
    'https://nexus-australia-websocket.intercom.io',
    'wss://nexus-australia-websocket.intercom.io',
    'https://uploads.intercomcdn.com',
    'https://uploads.intercomcdn.eu',
    'https://uploads.au.intercomcdn.com',
    'https://uploads.intercomusercontent.com',
  ],
  defaultSrc: [
    "'self'",
    `app.${env}.pulse360.com`,
    `app-clerk.${env}.pulse360.com`,
    `api.${env}.pulse360.com`,
    `api-clerk.${env}.pulse360.com`,
  ],
  fontSrc: [
    "'self'",
    '*.zohostatic.com',
    '*.zohostatic.eu',
    '*.zohocdn.com',
    '*.gstatic.com',
    '*.pulse360.com',
    '*.intercomcdn.com',
    'https://js.intercomcdn.com',
    'https://fonts.intercomcdn.com',
    '*.typekit.net',
  ],
  formAction: [
    'calendly.com',
    'https://intercom.help',
    'https://api-iam.intercom.io',
    'https://api-iam.eu.intercom.io',
    'https://api-iam.au.intercom.io',
    '*.helphero.co',
  ],
  frameSrc: [
    "'self'",
    '*.zohopublic.com',
    '*.vimeo.com',
    '*.helphero.co',
    '*.youtube.com',
    'www.help.pulse360.com',
    'https://calendly.com',
    'https://pulse360.com',
    'intercom-sheets.com',
  ],
  imgSrc: [
    "'self'",
    "'unsafe-inline'",
    'data:',
    'blob:',
    '*.googletagmanager.com',
    '*.google-analytics.com',
    '*.alipayobjects.com',
    '*.ytimg.com',
    '*.doubleclick.net',
    '*.s3.us-west-2.amazonaws.com',
    '*.zohostatic.com',
    '*.zoho.com',
    '*.zohopublic.com',
    '*.vimeo.com',
    '*.vimeocdn.com',
    'https://vimeo.com',
    '*.pulse360.com',
    'https://pulse360.com',
    '*.intercomassets.com',
    '*.intercomcdn.com',
    'https://js.intercomcdn.com',
    'https://static.intercomassets.com',
    'https://downloads.intercomcdn.com',
    'https://downloads.intercomcdn.eu',
    'https://downloads.au.intercomcdn.com',
    'https://uploads.intercomusercontent.com',
    'https://gifs.intercomcdn.com ',
    'https://video-messages.intercomcdn.com',
    'https://messenger-apps.intercom.io',
    'https://messenger-apps.eu.intercom.io',
    'https://messenger-apps.au.intercom.io',
    'https://*.intercom-attachments-1.com',
    'https://*.intercom-attachments.eu',
    'https://*.au.intercom-attachments.com',
    'https://*.intercom-attachments-2.com',
    'https://*.intercom-attachments-3.com',
    'https://*.intercom-attachments-4.com',
    'https://*.intercom-attachments-5.com',
    'https://*.intercom-attachments-6.com',
    'https://*.intercom-attachments-7.com',
    'https://*.intercom-attachments-8.com',
    'https://*.intercom-attachments-9.com',
    'https://static.intercomassets.eu',
    'https://static.au.intercomassets.com',
    'https://img.clerk.com',
  ],
  mediaSrc: [
    '*.zohostatic.com',
    '*.zoho.com',
    '*.zohopublic.com',
    `app.${env}.pulse360.com`,
    `app-clerk.${env}.pulse360.com`,
    'https://js.intercomcdn.com',
    `practices-assets-${env}.s3.us-west-2.amazonaws.com`,
    `recallai-production-bot-data.s3.amazonaws.com`,
  ],
  scriptSrc: [
    "'self'",
    "'unsafe-inline'",
    "'unsafe-eval'",
    `app.${env}.pulse360.com`,
    `app-clerk.${env}.pulse360.com`,
    `api.${env}.pulse360.com`,
    `api-clerk.${env}.pulse360.com`,
    '*.googletagmanager.com',
    '*.google-analytics.com',
    'sentry.io',
    '*.sentry.io',
    '*.segment.com',
    '*.segment.io',
    '*.amplitude.com',
    '*.coview.com',
    'https://cdn.heapanalytics.com',
    'https://heapanalytics.com',
    '*.helphero.co',
    'helphero.co',
    '*.zohostatic.com',
    '*.zoho.com',
    '*.zohocdn.com',
    '*.posthog.com',
    '*.zohostatic.com',
    '*.cloudfront.net',
    'https://pshg.prod.pulse360.com',
    'use.fontawesome.com',
    'polyfill.io',
    'https://app.codox.io/',
    'https://cdn1.codox.io/',
    '*.intercom.io',
    '*.intercomcdn.com',
    'https://app.intercom.io',
    'https://widget.intercom.io',
    'https://js.intercomcdn.com',
    'https://*.productfruits.com',
    'https://*.chameleon.io',
  ],
  styleSrc: [
    "'self'",
    "'unsafe-inline'",
    '*.zohostatic.com',
    '*.zoho.com',
    '*.zohocdn.com',
    '*.zohopublic.com',
    '*.cloudfront.net',
    'fonts.googleapis.com',
    'https://cdn1.codox.io/',
    '*.typekit.net',
    'https://*.productfruits.com',
    '*.posthog.com',
  ],
  workerSrc: ['*.pulse360.com', '*.codox.io', "'self'", "'unsafe-inline'", '* blob:'],
})
