import { MentionAtomExtensionMatcher } from '@remirror/extension-mention-atom'
import { ExtensionMentionAtomTheme as Theme } from '@remirror/theme'
import { kebabCase } from 'lodash'
import { Attrs, Node } from 'prosemirror-model'
import { smartfieldFactory as factory } from 'stores/smartfields/factory'
import { formatSid } from 'stores/smartfields/utils'
import Str from 'stores/util/str.util'
import { v4 as uuidv4 } from 'uuid'
import {
  AtomAttrs,
  DATA_ATTRIB_CONFIG,
  DATA_ATTRIB_ID,
  DATA_ATTRIB_NAME,
  DATA_ATTRIB_REUSE,
  DATA_ATTRS,
} from './constants'

export const hasMark = (node: Node, name: string) => node.marks.find(({ type }) => type.name === name)

export const parseDOMAttrs = (node: HTMLElement) => {
  const dataAttrs = Object.keys(DATA_ATTRS).reduce<AtomAttrs>(
    (all, key) => ({ ...all, [(DATA_ATTRS as any)[key]]: node.getAttribute(key) }),
    {} as AtomAttrs,
  )

  const { id, name, reuse = 'false', config } = attrsFixes(dataAttrs)
  const newAttrs = {
    id,
    name,
    reuse,
    label: node.textContent,
    config,
  }

  return newAttrs
}

export const toDOMAttrs = (node: Node) => {
  const { id, name, reuse = 'false', config } = attrsFixes(node.attrs as AtomAttrs)

  const newAttrs = {
    [DATA_ATTRIB_ID]: id,
    [DATA_ATTRIB_NAME]: name,
    [DATA_ATTRIB_REUSE]: reuse,
    [DATA_ATTRIB_CONFIG]: config,
  }

  return newAttrs
}

export const toDOMClasses = (node: Node, matcher?: MentionAtomExtensionMatcher) => {
  const classeNames = [
    hasMark(node, 'bold') && 'remirror-mention-atom-bold',
    hasMark(node, 'textHighlight') && 'remirror-mention-atom-text-highlight',
    hasMark(node, 'textColor') && 'remirror-mention-atom-text-color',
    hasMark(node, 'italic') && 'remirror-mention-atom-italic',
  ]
    .filter(Boolean)
    .join(' ')

  const mentionClassName = matcher ? matcher.mentionClassName ?? Theme.MENTION_ATOM : Theme.MENTION_ATOM
  const customClassName = `${mentionClassName}-${kebabCase(node.attrs.name)}`

  return {
    class: [mentionClassName, customClassName, classeNames].join(' '),
  }
}

export const attrsFixes = (attrs: AtomAttrs): AtomAttrs => {
  const { id, reuse, config, label } = reuseIdFix(oldReusableIdFix(attrs))
  return Object.assign({}, attrs, {
    id,
    reuse,
    config: stringTypeFix(config, label),
  })
}

export const stringTypeFix = (config: string = '{}', label: string = '') => {
  if (Str.isJSON(config)) {
    const cfg = JSON.parse(config)
    if (cfg.type === 'string' || !cfg.type) cfg.type = 'text'
    if (label !== '' && !cfg.label) cfg.label = label
    return JSON.stringify(cfg)
  }

  return config
}

export const reuseIdFix = (attrs: Attrs): Attrs => {
  if (attrs.reuse === 'true' && factory.loaded && !factory.has(attrs.id)) {
    return { ...attrs, id: uuidv4(), reuse: 'false' }
  }

  return attrs
}

export const oldReusableIdFix = (attrs: Attrs): Attrs => {
  return { ...attrs, id: formatSid(attrs.id) }
}
