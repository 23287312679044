import { EditorState } from '@remirror/pm'
import { getNotesByTypeAndTags, getNotesNodes } from '../../NoteReplacer.helpers'
import { MergeFieldContentToReplace, MergeFieldToReplace, NotesGroup } from '../../NoteReplacer.interfaces'
import { emptyMergeFieldContent } from './utils/emptyMergeFieldContent.util'
import { TopicTitle } from './utils/topicTitle.util'
import { hasValidSubtopic } from './utils/hasValidSubtopic.util'

/**
 * Processes a MergeFieldToReplace item, and returns it wrapped in a ListItem.
 * @param state
 * @param mergeFieldToReplace
 * @param notesGroup
 * @returns
 */
export function processMergeFieldToReplace(
  state: Readonly<EditorState>,
  mergeFieldToReplace: MergeFieldToReplace,
  notesGroup: NotesGroup[],
): MergeFieldContentToReplace {
  const { header, tags: placeholderTags, mergeFieldType, kind, marks, pos, parentAttrs } = mergeFieldToReplace

  const notesForPlaceholder = getNotesByTypeAndTags(notesGroup, mergeFieldType, placeholderTags)

  if (!notesForPlaceholder?.length || !hasValidSubtopic(mergeFieldToReplace)) {
    return emptyMergeFieldContent(mergeFieldType, pos, marks)
  }

  const content = []
  const noteGroupList = getNotesNodes(state, notesForPlaceholder, mergeFieldToReplace)

  const title = TopicTitle({ kind, header, state, mergeFieldType, placeholderTags, marks, attrs: parentAttrs })
  if (title) {
    content.push(title)
  }

  content.push(...noteGroupList)

  const contentSize = content.reduce((accumulator, node) => accumulator + node.nodeSize, 0)

  return {
    type: mergeFieldType,
    pos,
    content,
    contentSize,
    marks,
    parentAttrs,
  }
}
