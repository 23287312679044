import type { LoadedClerk, SessionResource, UserResource } from '@clerk/types'
import { message } from 'antd'
import { makeAutoObservable } from 'mobx'
import { connectionStore } from 'stores/connections'
import { integrationStore } from 'stores/integrations'
import { login } from 'stores/services/auth.service'
import supportAndMarketingInstance from '../supportAndMarketing'

type NullResource = null | undefined

export interface Auth {
  get session(): SessionResource | NullResource
  get user(): UserResource | NullResource
  get orgName(): string | NullResource
  get signedIn(): boolean

  setClerk: (clerk: LoadedClerk) => void
  signIn: (orgId: string, orgSlug: string) => void
  signOut: () => void
  reload: () => void
  getToken: () => void
}

class ClerkAuth implements Auth {
  clerk: LoadedClerk
  loaded: boolean = false
  orgId: string
  orgSlug: string

  get session() {
    return this.clerk?.session
  }

  get user() {
    return this.clerk?.user
  }

  get orgName() {
    return this.clerk?.organization?.name
  }

  get signedIn() {
    return !!(this.orgId && this.orgSlug && this.loaded)
  }

  constructor() {
    makeAutoObservable(this)
  }

  /**
   * @param clerk
   */
  setClerk = (clerk: LoadedClerk) => {
    this.clerk = clerk
  }

  /**
   * @param orgId
   * @param orgSlug
   * @returns
   */
  signIn = async (orgId: string, orgSlug: string): Promise<any> => {
    // if no org, or same as current org, abort
    if (!orgId || orgId === this.orgId) {
      return false
    }

    console.log('LoadOrgId: ' + orgId)

    this.loaded = false
    const resp = await login()

    // if error, display it and go back to signIn
    if (resp.error) {
      if (typeof resp.error === 'string') {
        message.error(resp.error, 6)
      }
      return this.signOut()
    }

    this._setInitialState(resp)
    // @ts-ignore
    supportAndMarketingInstance.initializePosthog(resp, resp.practice.domain)
    supportAndMarketingInstance.initializeIntercom()

    Object.assign(this, {
      loaded: true,
      orgSlug,
      orgId,
    })
  }

  /**
   * @returns
   */
  signOut = async () => {
    await this.clerk?.signOut()
    this.loaded = false
  }

  /**
   *
   */
  reload = async () => {
    await this.clerk?.organization?.reload()
    // global.router.reload()
  }

  /**
   * @returns
   */
  getToken = async () => {
    return this.session?.getToken()
  }

  /**
   * @param resp
   */
  private _setInitialState = (resp: any) => {
    const isAdmin = resp.advisor?.isAdmin || false
    integrationStore.setIntegrations(resp.integrations, resp.integration_syncs)
    connectionStore.setConnections(
      resp.connections,
      isAdmin ? resp.adminConnections : [],
    )
    global.data.practice.setStatuses(resp.statuses)
    global.data.practice.setTypes(resp.types)
    global.data.practice.setPractice(resp.practice, resp.advisor)
    global.data.advisors.setMe(resp.advisor)
    global.tags.setTags(resp.tags)
  }
}

const authStore = new ClerkAuth()
export { authStore }
