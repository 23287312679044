import { upperFirst } from 'lodash'

const startCaseNoSplitNumbers = (string: string) => {
  const text = string.replace(/[^a-zA-Z0-9]/g, ' ')

  return text
    .split(/[^\w]/)
    .map((word) => upperFirst(word))
    .join('')
    .split(/(?=[A-Z])/)
    .join(' ')
}

export default startCaseNoSplitNumbers
