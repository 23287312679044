import ROLES, { atLeast, Level } from 'constants/roles'
import { action, makeObservable, observable } from 'mobx'
// import { changePermission } from './apollo/query/account.gql'
import { find, get } from 'lodash'
import { Permission } from 'types/graphql'

export interface PermissionStore {
  get: (key: string, defaultValue?: any) => any
  find: (key: string) => Permission | undefined
  hasRole: (role: Level) => boolean
  isAvailable: (key: string) => string | number | boolean
  setUpgradeModalVisible: (show: boolean) => void
  showUpgradeModal: boolean
  permissions: Permission[]
  showAssistantV2: boolean
}

class Permissions implements PermissionStore {
  permissions: Permission[] = []
  showUpgradeModal = false
  showAssistantV2 = false

  constructor() {
    makeObservable(this, {
      permissions: observable,
      showUpgradeModal: observable,
      setUpgradeModalVisible: action.bound,
    })
  }

  setUpgradeModalVisible = (show: boolean) => {
    this.showUpgradeModal = show
  }

  /* ---------- permissions ---------- */
  /**
   * @param {*} perm
   */
  get = (key: string, defaultValue = false) => {
    const perm = this.find(key)
    if (perm) {
      return this._castValue(perm)
    }
    return defaultValue
  }

  isAvailable = (key: string) => {
    const perm = this.find(key)
    if (perm) {
      return this._castAvailable(perm)
    }

    return false
  }

  /**
   * @param {*} perm
   */
  find = (key: string): Permission | undefined => {
    return find(this.permissions, { key }) as Permission
  }

  /* ---------- roles ---------- */

  /**
   * myRole=ADVISOR
   * required=ADMIN_MANAGER
   * disabled=myRole<required
   */
  hasRole = (role: Level) => {
    const myRole = get(global, 'data.advisors.me.role', 'blah') as Level
    const isAtLeast = atLeast(myRole, role)
    return isAtLeast
  }

  // following shortcut roles, in order of seniority
  isUser = () => this.hasRole(ROLES.USER)
  isManager = () => this.hasRole(ROLES.MANAGER)
  isAdmin = () => this.hasRole(ROLES.ADMIN)
  isSuperAdmin = () => this.hasRole(ROLES.SUPER_ADMIN)
  isPulseAdmin = () => this.hasRole(ROLES.PULSE_ADMIN)

  /* ---------- private ---------- */

  /**
   *
   */
  _castValue = (perm: Permission) => {
    if (perm.type === 'boolean') {
      return Boolean(JSON.parse(perm.value))
    }
    if (perm.type === 'string') {
      return String(JSON.parse(perm.value))
    }
    if (perm.type === 'string') {
      return Number(JSON.parse(perm.value))
    }
    return perm.type
  }

  _castAvailable = (perm: Permission) => {
    if (perm.type === 'boolean') {
      return Boolean(JSON.parse(perm.available))
    }
    if (perm.type === 'string') {
      return String(JSON.parse(perm.available))
    }
    if (perm.type === 'string') {
      return Number(JSON.parse(perm.available))
    }
    return perm.type
  }
}

export default Permissions
