import * as apiService from 'lib/apollo/client'
import { EntityId } from 'types/entity.interface'
import {
  AuditInfoDocument,
  AuditInfoQuery,
  AuditInfoQueryVariables,
  AuditRevertDocument,
  AuditRevertMutation,
  AuditRevertMutationVariables,
  LoadDocument,
  LoadQuery,
  LoadQueryVariables,
  LogDocument,
  LogMutation,
  LogMutationVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

class AuditService {
  async loadLogs(category: string, source: string, page: number, size: number, start?: string, end?: string) {
    const { load: resp } = await apiService.query<LoadQuery, LoadQueryVariables>(LoadDocument, {
      start,
      end,
      category,
      source,
      page,
      size,
    })

    if (IsError(resp)) {
      throw new DataError(resp)
    }
    return resp
  }

  async checkAuditForReversal(id: EntityId) {
    const { auditInfo: resp } = await apiService.query<AuditInfoQuery, AuditInfoQueryVariables>(AuditInfoDocument, {
      id,
    })

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    const parsedUpdatedData = JSON.parse(resp.data)

    return parsedUpdatedData
  }

  async auditRevert(id: EntityId) {
    const { auditRevert: resp } = await apiService.mutate<AuditRevertMutation, AuditRevertMutationVariables>(
      AuditRevertDocument,
      { id },
    )

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async log(advisorId: EntityId, data: string, category: string, message: string) {
    const { log: resp } = await apiService.mutate<LogMutation, LogMutationVariables>(LogDocument, {
      log: {
        advisorId,
        data,
        category,
        message,
      },
    })

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }
}

export default new AuditService()
