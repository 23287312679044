// icon:paragraph | Bootstrap https://icons.getbootstrap.com/ | Bootstrap
import * as React from 'react'

function IconParagraph(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="currentColor" viewBox="0 0 16 16" height="1em" width="1em" {...props}>
      <path d="M10.5 15a.5.5 0 01-.5-.5V2H9v12.5a.5.5 0 01-1 0V9H7a4 4 0 110-8h5.5a.5.5 0 010 1H11v12.5a.5.5 0 01-.5.5z" />
    </svg>
  )
}

export default IconParagraph
