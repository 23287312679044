import { LoadingOutlined } from '@ant-design/icons'
import { notification } from 'antd'
import Messages from 'components/base/Alert/messages'
import { computed, makeObservable, observable, observe } from 'mobx'

class App {
  showAddTag = false
  appName = 'Pulse360'
  enterLicense = false
  leftNavCollapsed = false
  leftSiderCollapsed = false
  _loading = false
  _waiting = false
  fromEula = false
  phoneNum = '951-254-2373'
  supportEmail = 'support@pulse360.com'
  passwordRequirements = 'A combination of at least 8 characters, symbols and numbers.'

  get isProd() {
    return process.env.NODE_ENV === 'production'
  }

  get homePage() {
    if (this.fromEula) {
      return '/onboard'
    }
    return '/contacts'
  }

  constructor() {
    makeObservable(this, {
      showAddTag: observable,
      appName: observable,
      enterLicense: observable,
      leftNavCollapsed: observable,
      leftSiderCollapsed: observable,
      _loading: observable,
      _waiting: observable,
      fromEula: observable,
      phoneNum: observable,
      supportEmail: observable,
      passwordRequirements: observable,
      homePage: computed,
    })

    observe(
      global.router,
      'path',
      (c) => {
        // set side menu open on every route change
        this.leftSiderCollapsed = false
      },
      true,
    )
  }

  /**
   * I have disabled this, as there were just too many of these popping up all over the place, looked erratic!
   *
   * If a main data load operation requires a 'loader' use 'wait' function below
   */
  // eslint-disable-next-line accessor-pairs
  set loading(value) {
    // if (value && !this._loading) {
    //   this._loading = true
    //   notification.open({
    //     message: (
    //       <FlexRow>
    //         {/* <LoadingOutlined style={{ color: styles.colors.green, fontSize: 30, marginRight: 24 }} /> */}
    //         <Messages msg={typeof value === "string" && value} />
    //       </FlexRow>
    //     ),
    //     style: { transform: "unset!important" },
    //     placement: 'bottom',
    //     className: 'p360-message',
    //     duration: 0,
    //     bottom: 6,
    //     maxCount: 1,
    //     key: 'app_info'
    //   });
    // } else if (!value && this._loading) {
    //   notification.close('app_info')
    //   this._loading = false
    // }
  }

  get loading() {
    return this._loading
  }

  set wait(value: boolean | string | undefined) {
    if (!!value && !this._waiting) {
      this._waiting = true
      notification.open({
        message: <Messages msg={typeof value === 'string' && value} />,
        placement: 'bottom',
        duration: 0,
        bottom: 6,
        maxCount: 1,
        key: 'app_wait',
        icon: <LoadingOutlined />,
      })
    } else if (!value && this._waiting) {
      notification.close('app_wait')
      this._waiting = false
    }
  }

  get wait() {
    return this._waiting
  }
}

export default App
