export const clientsFields = {
  risk_number: 'Risk Number',
  status: 'Status',
  created: 'Created Date',
  risk_metric: {
    value: 'Value',
    type: 'Type',
    scale: 'Scale',
    status: 'Status',
  },
  retirements: [
    {
      title: 'Title',
      settings: {
        portfolio_id: 'Portfolio Id',
        portfolio_r: 'Portfolio Risk',
        portfolio_s: 'Portfolio Score',
        risk_number: 'Risk Number',
        life_expectancy: 'Life Expectancy',
        inflation_rate: 'Inflation Rate',
        contribution_increase: 'Contribution Increase',
        investment_amount: 'Investment Amount',
        investment_amount_total: 'Investment Amount Total',
        retirement_year: 'Retirement Year',
        birth_year: 'Birth Year',
        savings_rate: 'Savings Rate',
        withdrawal_rate: 'Withdrawal Rate',
      },
      data: {
        title: 'Title',
        amountOnRetire: 'Amount On Retire',
        time: 'Time',
        amountNeeded: 'Amount Needed',
        probability: 'Probability',
        stepSize: 'Step Size',
        portfolio_return_pre: 'Portfolio Return (Pre)',
        portfolio_return_post: 'Portfolio Return (Post)',
        portfolio_sigma_6mo: 'Portfolio Sigma (6m)',
        axisMax: 'Axis Max',
      },
    },
  ],
  portfolios: [
    {
      name: 'Name',
      total: 'Total',
      type: 'Type',
      analysis: {
        return: 'Return',
        ttm_dividend: 'Dividend (TTM)',
        annual_fee: 'Annual Fee',
        stdev: 'Standard Deviation',
        risk_number: 'Risk Number',
        risk_metric: {
          type: 'Type',
          value: 'Value',
        },
        worst: 'Worst',
        best: 'Best',
        stresstests: [
          {
            slug: 'Slug',
            return: 'Return',
            stdev: 'Standard Deviation',
            risk_number: 'Risk Number',
          },
        ],
        categories: {
          asset_class: {
            bonds: 'Bonds',
            stocks: 'Stocks',
            cash: 'Cash',
            other: 'Other',
            unavailable: 'Unavailable',
          },
        },
        nocorr_stdev: 'Non-correlated St. Deviation',
        nocorr_risk_number: 'Non-correlated Risk Number',
        gpa: 'GPA',
        gpa_version: 'GPA Version',
        heatmap_normalization_factor: 'Heatmap Normalization Factor',
      },
      data_models: [
        {
          id: 'Id',
          name: 'Name',
          description: 'Description',
          type: 'Type',
          market: 'Market',
          interest: 'Interest',
          risk_number: 'Risk Number',
        },
      ],
    },
  ],
  available_models: [
    {
      name: 'Name',
      total: 'Total',
      settings: {
        risk_model: 'Risk Model',
        model_unsynced: 'Model Unsynced',
      },
      status: 'Status',
      updated: 'Updated',
      analysis: {
        return: 'Return',
        ttm_dividend: 'Dividend (TTM)',
        annual_fee: 'Annual Fee',
        advisory_fee: 'Advisory Fee',
        stdev: 'Standard Deviation',
        risk_number: 'Risk Number',
        risk_metric: {
          type: 'Type',
          value: 'Value',
        },
        worst: 'Worst',
        best: 'Best',
        stresstests: [
          {
            slug: 'Slug',
            return: 'Return',
            stdev: 'Standard Deviation',
            risk_number: 'Risk Number',
          },
        ],
      },
    },
  ],
}
