import { msg } from 'stores/msg'
import { ConnectionProvider, connectionStore } from '.'
import { captureExceptionSilently } from '../../helpers/sentry'
import connectionService from '../services/connection.service'

type ConnectionCredentials = {
  clientId: string
  clientSecret: string
}

export const connectConnection = async (
  provider: ConnectionProvider,
  { clientId, clientSecret }: ConnectionCredentials,
) => {
  const connection = connectionStore.getConnection(provider)
  if (!connection) {
    return
  }

  try {
    const authorizationUri = await connectionService.connect(
      clientId,
      clientSecret,
      window.location.href,
      provider,
      connection.type,
    )
    document.location.replace(authorizationUri)
  } catch (err: any) {
    msg.error(err.message)
    captureExceptionSilently(err, {
      message: 'connectConnection',
      data: { provider, clientId },
    })
  }
}

/**
 * @param {*} fields
 */
export const reconnectConnection = async (provider: ConnectionProvider) => {
  const connection = connectionStore.getConnection(provider)
  if (!connection) {
    return
  }

  try {
    const authorizationUri = await connectionService.reconnect(
      provider,
      window.location.href,
      connection.type,
    )
    document.location.replace(authorizationUri)
  } catch (err: any) {
    msg.error(err.message)
    captureExceptionSilently(err, {
      message: 'reconnectConnection',
      data: { provider },
    })
  }
}

/**
 * @param {*} fields
 */
export const connectConnectionCallback = async (
  provider: ConnectionProvider,
  authCode: string,
) => {
  const connection = connectionStore.getConnection(provider)
  if (!connection) {
    return
  }

  const { origin, pathname } = window.location
  const redirectCallback = `${origin}${pathname}`

  try {
    const response = await connectionService.connectCallback(
      redirectCallback,
      provider,
      authCode,
      connection.type,
    )

    if (response) {
      connectionStore.updateConnection(provider, response)
    }
  } catch (err: any) {
    msg.error(err.message)
    captureExceptionSilently(err, {
      message: 'connectCallbackConnection',
      data: { provider },
    })
  }
}

/**
 * Disable the integration
 */
export const disconnectConnection = async (provider: ConnectionProvider) => {
  const connection = connectionStore.getConnection(provider)
  if (!connection) {
    return
  }

  try {
    const removeConnectionResponse = await connectionService.removeCallback(
      provider,
      connection.type,
    )

    if (removeConnectionResponse) {
      connectionStore.updateConnection(provider, removeConnectionResponse)
    }
  } catch (err: any) {
    msg.error(err.message)
    captureExceptionSilently(err, {
      message: 'disconnectConnection',
      data: { provider },
    })
  }
}
