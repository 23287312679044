import { Button, Modal, Skeleton, Upload } from 'antd'
import { UploadFile } from 'antd/lib/upload'
import fileUpload from 'assets/images/gfx/file-search.png'
import Image from 'components/base/Image'
import useImagePicker from 'components/hooks/useImagePicker'
import React from 'react'
import styled from 'styled-components'
import styles, { FlexCol, FlexRow } from 'styles'
import { FileObject } from 'types/graphql'

const StyledModal = styled(Modal)`
  .ant-btn-lg {
    > span {
      padding-bottom: 4px;
    }
  }
  .upload-container {
    .wrapper {
      justify-content: center;
      .center {
        .cta {
          display: flex;
          flex-direction: column;
          align-items: center;
          p {
            margin-bottom: 10px;
            font-family: 'proxima-nova';
            font-size: 16px;
          }
          .ant-btn {
            width: 120px;
            > span {
              padding-bottom: 4px;
            }
          }
        }
        img {
          margin-right: 30px;
          height: 100px;
        }
      }
    }
    .card {
      display: inline-block;
      margin-top: 16px;
      width: 100%;
      h2 {
        margin-bottom: 10px !important;
        font-family: 'proxima-nova';
        font-weight: 600;
        &.status {
          color: rgba(71, 86, 105, 0.4) !important;
          margin-left: 10px;
        }
      }
    }
  }
`

const Grid = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
`

type TileProps = {
  onSelect: (file: FileObject) => void
  selected: boolean
  file: FileObject
}

const Tiled = styled.div<{ selected: boolean }>`
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  cursor: pointer;

  ${(p) =>
    p.selected &&
    `
    border: 1px solid ${styles.colors.blue};
  `}

  &:hover {
    border: 1px solid ${styles.colors.blue};
  }

  margin: 0 8px 8px 0;
  border-radius: 4px;
  height: 104px;
  width: 104px;
  float: left;

  .upload_card-item {
    // background: rgba(0,0,0,0.4);
    width: 100%;
    height: 100%;

    img {
      pointer-events: none;
      object-fit: contain;
      height: 102px;
      width: 102px;
      padding: 8px;
    }
  }
`

const StyledTile = (props: TileProps) => {
  return (
    <Tiled onClick={() => props.onSelect(props.file)} selected={props.selected} className="upload_card">
      <div className="upload_card-item">
        <Image src={props.file.url as string} />
      </div>
    </Tiled>
  )
}

type FooterProps = {
  selectedFile: UploadFile
  onRemove: () => void
  onInsert: () => void
  onClose?: () => void
}

const Footer = (props: FooterProps) => {
  return (
    <React.Fragment>
      {props.selectedFile && (
        <Button onClick={props.onRemove} size="large" danger>
          Delete
        </Button>
      )}
      {props.selectedFile && (
        <Button onClick={props.onInsert} type="primary" size="large" data-tour="image_upload_button_insert">
          Insert
        </Button>
      )}
      <Button
        onClick={() => {
          global.images.close()
          props.onClose?.()
        }}
        size="large"
      >
        Cancel
      </Button>
    </React.Fragment>
  )
}

type UploadProps = {
  onClose?: () => void
}

const ImageUpload = (props: UploadProps) => {
  const { selectedFile, images, loading, onSelect, onRemove, onInsert, onUpload } = useImagePicker()

  return (
    <StyledModal
      onCancel={() => {
        global.images.close()
        props.onClose?.()
      }}
      closable={false}
      width={720}
      open
      // wrapProps={{ 'data-tour': 'image_upload' }}
      // bodyProps={{ 'data-tour': 'image_upload_body' }}
      footer={
        <Footer
          {...props}
          selectedFile={selectedFile as UploadFile}
          onRemove={onRemove}
          onInsert={onInsert}
          onClose={props.onClose}
        />
      }
    >
      <div className="upload-container">
        <Upload.Dragger
          beforeUpload={onUpload}
          accept=".jpeg,.jpg,.png,.gif"
          customRequest={() => {}}
          showUploadList={false}
          multiple={false}
        >
          <FlexRow className="wrapper">
            <FlexRow className="center">
              <img src={fileUpload} alt="" />
              <FlexCol className="cta">
                <p>Drag or drop your files anywhere or</p>
                <Button size="large">Upload a file</Button>
              </FlexCol>
            </FlexRow>
          </FlexRow>
        </Upload.Dragger>
        <div className="card">
          <FlexRow>
            <h2>{loading ? 'Loading library..' : 'Image library'}</h2>
            <h2 className="status">{loading ? '' : 'select to insert, or upload new'}</h2>
          </FlexRow>
          <Grid>
            {loading && <Skeleton active />}
            {!loading &&
              images.length > 0 &&
              images.map((image) => (
                <StyledTile onSelect={onSelect} selected={selectedFile?.id === image.id} key={image.id} file={image} />
              ))}
          </Grid>
        </div>
      </div>
    </StyledModal>
  )
}

export default ImageUpload
