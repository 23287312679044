import { MergeFieldToReplace } from '../../../NoteReplacer.interfaces'

/**
 * Checks if the `MergeFieldToReplace` has a valid subtopic.
 *
 * @param mergeField
 * @returns `boolean` condition if subtopic attached (if there is one) actually exists
 */
export function hasValidSubtopic(mergeField: MergeFieldToReplace) {
  const hasSubtopicValue = Boolean(mergeField.node.attrs.tags)
  if (hasSubtopicValue) {
    if (Array.isArray(mergeField.tags)) {
      return mergeField.tags.find((subtopic) => subtopic.value === mergeField.node.attrs.tags)
    }

    return mergeField.tags === mergeField.node.attrs.tags
  }
  return true
}
