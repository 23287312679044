import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { ProductFruits } from 'react-product-fruits'
import { isDEV, isPROD } from '../../../helpers/env'
import supportAndMarketing from '../../../stores/supportAndMarketing'

type UserInfo = Awaited<ReturnType<typeof supportAndMarketing.initializeProductFruits>>

function ProductFruitsWrapper() {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null)

  useEffect(() => {
    autorun(() => {
      if (!isDEV && !isPROD) {
        return
      }

      if (!global.data.advisors.me) {
        return
      }

      supportAndMarketing
        .initializeProductFruits(global.data.advisors.me)
        .then((userInfoPayload) => setUserInfo(userInfoPayload))
    })
  }, [])

  if (!userInfo) {
    return null
  }

  return <ProductFruits workspaceCode="gMbOKs0LSpkNveha" language="en" user={userInfo} />
}

export default observer(ProductFruitsWrapper)
