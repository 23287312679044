import { kebabCase } from 'lodash'
import { Directives } from './base'
import { config as devConfig } from './dev'
import { config as prodConfig } from './prod'
import { config as stagingConfig } from './staging'

export const csp = () => {
  const env = process.env.REACT_APP_ENV ?? 'dev'

  const directive = (
    {
      dev: devConfig,
      staging: stagingConfig,
      prod: prodConfig,
    } as any
  )[env]

  const config = parse(directive)
  return config.join('; ')
}

export const parse = (config: Directives) => {
  return Object.keys(config).map((directive) => {
    const dirName = kebabCase(directive)
    const values = (config as any)[directive].join(' ')

    return `${dirName} ${values}`
  })
}
