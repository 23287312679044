import { SmartFieldConfig, SmartFieldDateConfig } from '../types/data'
import { SmartFieldDateFormValues, SmartFieldFormValues } from '../types/form'

const fields = ['date']

export const dateFormParser = (
  values: SmartFieldFormValues,
  config: Partial<SmartFieldConfig<SmartFieldDateConfig>>,
): SmartFieldConfig => {
  const dateValues = values as SmartFieldFormValues<SmartFieldDateFormValues>

  fields.forEach((field) => {
    ;(config as any)[field] = (dateValues as any)[field]
  })

  return config as SmartFieldConfig<SmartFieldDateConfig>
}

export const dateConfigParser = (config: SmartFieldConfig): SmartFieldDateFormValues => {
  return fields.reduce((values, value) => {
    return { ...values, [value]: (config as any)[value] }
  }, {}) as SmartFieldDateFormValues
}
