import { MentionAtomOptions } from '@remirror/extension-mention-atom'
import { BaseExtensionOptions } from 'remirror'
import { DISPLAY_MODE } from './utils/displayMode.constant'
import { FroalaTopicParseDOM } from './utils/froalaTopicParseDOM.util'

type Options = MentionAtomOptions & BaseExtensionOptions

export const smartFieldAtomConfig: Options = {
  matchers: [
    /* { name: 'at', char: '@', appendText: ' ' }, */
    { name: 'command', char: '/', matchOffset: 0, supportedCharacters: /[\w\d_]+/ },
  ],
  appendText: ' ',
  extraAttributes: {
    header: {
      default: '',
      parseDOM: (dom) => dom.getAttribute('data-header'),
      toDOM: (attrs) => ['data-header', attrs.header as string],
    },
    tags: {
      default: '',
      parseDOM: (dom) => dom.getAttribute('data-tags'),
      toDOM: (attrs) => ['data-tags', attrs.tags as string],
    },
    kind: {
      default: '',
      parseDOM: (dom) => dom.getAttribute('data-kind'),
      toDOM: (attrs) => ['data-kind', attrs.kind as string],
    },
    displayMode: {
      default: DISPLAY_MODE.PARAGRAPH,
      parseDOM: (dom) => dom.getAttribute('data-display-mode'),
      toDOM: (attrs) => ['data-display-mode', attrs.displayMode as string],
    },
    // is the smartfield re-usable?
    reuse: {
      default: 'false',
      parseDOM: (dom) => dom.getAttribute('data-reuse'),
      toDOM: (attrs) => ['data-reuse', attrs.reuse as string],
    },
    config: {
      default: '',
      parseDOM: (dom) => dom.getAttribute('data-config'),
      toDOM: (attrs) => ['data-config', attrs.config as string],
    },
  },
  nodeOverride: FroalaTopicParseDOM,
}
