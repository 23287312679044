/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Form, Input, Select } from 'antd'
import { GenericProviderErrorAlert } from 'components/drawers/Smartfields/smartfield.alerts'
import { FormItemLabel } from 'components/drawers/Smartfields/smartfield.styles'
import { smartfieldStore } from 'stores/smartfields'
import { ProviderSmartFieldsBaseProps } from '../../../../provider.interfaces'
import { ERRORS } from '../../../riskalyze.errors'
import { useModelSelects } from '../data.hooks'
import { modelName } from '../data.utils'

interface RiskalyzeNestedDataProps extends ProviderSmartFieldsBaseProps {
  dataFields: any
  client?: any
}

export const RiskalyzeDataSelector: React.FC<RiskalyzeNestedDataProps> = ({
  form,
  dataFields,
  client,
  uid,
}) => {
  const { selects, onSelectIndice, error } = useModelSelects(
    client,
    dataFields,
    form,
    uid,
  )

  return (
    <>
      {error && (
        <>
          <GenericProviderErrorAlert
            error={error}
            style={{ marginBottom: 16 }}
          />
          <br />
        </>
      )}

      {!error &&
        selects.map((select, index) => {
          if (!select?.data || !select?.data.length) {
            return (
              <>
                <GenericProviderErrorAlert
                  error={ERRORS.NO_DATA_ERROR}
                  style={{ marginBottom: 16 }}
                  testid="provider-error"
                />

                <Form.Item
                  name={uid}
                  label={
                    <FormItemLabel
                      label="Manual Override"
                      description="Add a manual override to build your document"
                    />
                  }
                  data-testid="manual-override-input"
                  initialValue={smartfieldStore?.currentValue}
                >
                  <Input size="large" autoFocus />
                </Form.Item>
                <br />
              </>
            )
          }

          return (
            <Form.Item
              name={`select-${index}`}
              rules={[{ required: true, message: 'Please select a value' }]}
              label={
                <FormItemLabel
                  label={select.title}
                  description={`Select required field`}
                />
              }
            >
              <Select
                onChange={(val) => onSelectIndice(val, index)}
                placeholder={`Select ${select.title}`}
                style={{ width: '100%' }}
                size="large"
                showSearch
                filterOption={(input, option) =>
                  (`${option?.label}` ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {select.data.map((b: any, i: number) => {
                  const name = modelName(b)
                  return (
                    <Select.Option value={i} label={name}>
                      {name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          )
        }, undefined)}
    </>
  )
}
