import { SmartFieldDOMAttrs } from 'components/drawers/Smartfields/types'
import { smartFieldAtomReduced } from '../../lib/remirror/extensions/smartFieldAtom/atoms/smartField/atom'
import { SMARTFIELD_NO_NAME } from './constants'
import { createDOMAttrs, preventMatchedSubstringInsert, sanitiseLabel } from './utils'

const CLASSES = ['remirror-mention-atom', 'remirror-mention-atom-command']

export class SmartfieldDOMWidget {
  static create(domLabel: string): HTMLElement {
    const label = sanitiseLabel(domLabel)

    const container = document.createElement('span')
    SmartfieldDOMWidget.createAtom(container, label)
    return container
  }

  static createHtml(label: string, mention?: SmartFieldDOMAttrs): string {
    const lbl = label ? label : SMARTFIELD_NO_NAME
    const mergeField = SmartfieldDOMWidget.create(lbl)

    const smartFieldAtom = mergeField.firstElementChild as HTMLElement

    const attrs = mention ?? createDOMAttrs(lbl)

    Object.entries(attrs).forEach(([attr, val]) => {
      smartFieldAtom.setAttribute(attr, val as string)
    })

    smartFieldAtom.classList.add(...CLASSES)

    return preventMatchedSubstringInsert(smartFieldAtom.outerHTML)
  }

  private static createAtom(container: HTMLElement, label: string) {
    container.innerHTML = smartFieldAtomReduced({ label })
  }
}
