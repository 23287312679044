import { SmartField } from 'components/drawers/Smartfields/types'
import { reaction } from 'mobx'
import { useEffect, useState } from 'react'
import { smartfieldFactory as factory } from 'stores/smartfields/factory'

export const useSmartfieldFactory = () => {
  const [smartfields, setSmartfields] = useState<SmartField[]>([])
  const [archived, setArchived] = useState<SmartField[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    return reaction(
      () => factory._smartfields.toJSON(),
      () =>
        Promise.resolve()
          .then(() => factory.getSmartfields())
          .then(() => {
            setSmartfields(factory.smartfields)
            setArchived(factory.archived)
            setLoading(false)
          }),
      { fireImmediately: true },
    )
  }, [])

  return {
    smartfields,
    archived,
    loading,
  }
}
