import React from 'react'
import styled from 'styled-components'

interface ImageProps {
  src: string
  size?: number
}

const StyledImage = styled.img<ImageProps>`
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
`

export const ImageIcon: React.FC<ImageProps> = ({ src, size = 20, ...rest }) => (
  <StyledImage src={src} size={size} {...rest} />
)
