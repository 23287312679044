import DataError from 'helpers/dataError'
import * as apiService from 'lib/apollo/client'
import { LoginDocument, LoginMutation, LoginMutationVariables, LoginResponse } from 'types/graphql'

export type LoginError = {
  error: string
}

export const login = async (): Promise<LoginResponse | LoginError> => {
  try {
    const loginResp = await apiService.mutate<LoginMutation, LoginMutationVariables>(LoginDocument, {})
    if (!loginResp.login.ok) {
      throw new DataError(loginResp.login.error!)
    }
    return loginResp.login
  } catch (err) {
    const error = err as DataError
    const msg = error.message?.split(': ')?.[1] ?? 'Unknown error'

    return { error: msg }
  }
}
