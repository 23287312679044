import * as apiService from 'lib/apollo/client'
import {
  AddTemplateCategoryDocument,
  AddTemplateCategoryMutation,
  AddTemplateCategoryMutationVariables,
  AddTemplateDocument,
  AddTemplateMutation,
  AddTemplateMutationVariables,
  AllCategoryTemplatesDocument,
  AllCategoryTemplatesQuery,
  AllCategoryTemplatesQueryVariables,
  DeleteTemplateCategoryDocument,
  DeleteTemplateCategoryMutation,
  DeleteTemplateCategoryMutationVariables,
  DeleteTemplateDocument,
  DeleteTemplateMutation,
  DeleteTemplateMutationVariables,
  DuplicateTemplateDocument,
  DuplicateTemplateMutation,
  DuplicateTemplateMutationVariables,
  RecentlyUsedTemplatesDocument,
  RecentlyUsedTemplatesQuery,
  RecentlyUsedTemplatesQueryVariables,
  UpdateTemplateCategoryDocument,
  UpdateTemplateCategoryMutation,
  UpdateTemplateCategoryMutationVariables,
  UpdateTemplateDocument,
  UpdateTemplateMutation,
  UpdateTemplateMutationVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

class TemplateService {
  async allCategoryTemplates() {
    const { allCategoryTemplates: resp } = await apiService.query<
      AllCategoryTemplatesQuery,
      AllCategoryTemplatesQueryVariables
    >(AllCategoryTemplatesDocument, {}, { fetchPolicy: 'no-cache' })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.templateCategories
  }

  async addTemplate(payload: AddTemplateMutationVariables) {
    const { addTemplate: resp } = await apiService.mutate<AddTemplateMutation, AddTemplateMutationVariables>(
      AddTemplateDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async updateTemplate(payload: UpdateTemplateMutationVariables) {
    const { updateTemplate: resp } = await apiService.mutate<UpdateTemplateMutation, UpdateTemplateMutationVariables>(
      UpdateTemplateDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async deleteTemplate(payload: DeleteTemplateMutationVariables) {
    const { deleteTemplate: resp } = await apiService.mutate<DeleteTemplateMutation, DeleteTemplateMutationVariables>(
      DeleteTemplateDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async addTemplateCategory(payload: AddTemplateCategoryMutationVariables) {
    const { addTemplateCategory: resp } = await apiService.mutate<
      AddTemplateCategoryMutation,
      AddTemplateCategoryMutationVariables
    >(AddTemplateCategoryDocument, payload)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async updateTemplateCategory(payload: UpdateTemplateCategoryMutationVariables) {
    const { updateTemplateCategory: resp } = await apiService.mutate<
      UpdateTemplateCategoryMutation,
      UpdateTemplateCategoryMutationVariables
    >(UpdateTemplateCategoryDocument, payload)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async deleteTemplateCategory(payload: DeleteTemplateCategoryMutationVariables) {
    const { deleteTemplateCategory: resp } = await apiService.mutate<
      DeleteTemplateCategoryMutation,
      DeleteTemplateCategoryMutationVariables
    >(DeleteTemplateCategoryDocument, payload)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async recentlyUsedTemplates(payload: RecentlyUsedTemplatesQueryVariables) {
    const { recentlyUsedTemplates: resp } = await apiService.query<
      RecentlyUsedTemplatesQuery,
      RecentlyUsedTemplatesQueryVariables
    >(RecentlyUsedTemplatesDocument, payload, {
      fetchPolicy: 'no-cache',
    })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.templates
  }

  async duplicateTemplate(payload: DuplicateTemplateMutationVariables) {
    const { duplicateTemplate: resp } = await apiService.mutate<
      DuplicateTemplateMutation,
      DuplicateTemplateMutationVariables
    >(DuplicateTemplateDocument, payload)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }
}

export default new TemplateService()
