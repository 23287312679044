import { Transaction } from 'remirror'
import {
  getAllTableCellsAttributes,
  getTableAttributes,
  setSelectedTableAndChildAttributes,
  setSelectedTableAttribute,
} from './TableCustom.utils'

export function getTableBorders(tr: Transaction): string | null {
  const tableAttrs = getTableAttributes(tr)
  if (tableAttrs!.border) {
    return tableAttrs!.border
  } else {
    const tableCellAttrs = getAllTableCellsAttributes(tr)
    if (tableCellAttrs) {
      const tableCellBorderAttrs = tableCellAttrs.map(({ attrs }) => attrs.border)
      const allTableCellBorderHaveSameAttr = tableCellBorderAttrs.every((attr) => attr === tableCellBorderAttrs[0])
      if (allTableCellBorderHaveSameAttr) {
        return tableCellBorderAttrs[0]
      }
    }
  }
  return null
}

export function setTableBorders(tr: Transaction, borderStyle: string | null): Transaction {
  setSelectedTableAttribute(tr, { border: borderStyle })
  setSelectedTableAndChildAttributes(tr, { border: borderStyle })
  return tr
}

export function reapplyTableBorders(tr: Transaction): Transaction {
  const currentBorders = getTableBorders(tr)
  if (currentBorders) {
    setTableBorders(tr, currentBorders)
  }
  return tr
}
