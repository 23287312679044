// icon:bx-text | Boxicons https://boxicons.com/ | Atisa
import * as React from 'react'

function IconBxText(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props}>
      <path d="M5 8h2V6h3.252L7.68 18H5v2h8v-2h-2.252L13.32 6H17v2h2V4H5z" />
    </svg>
  )
}

export default IconBxText
