import { Attrs, Fragment } from '@remirror/pm/model'
import { TextSelection, Transaction } from '@remirror/pm/state'
import { CellSelection, TableView } from '@remirror/pm/tables'
import { ReplaceStep } from '@remirror/pm/transform'
import { DecorationSet } from '@remirror/pm/view'
import {
  ApplySchemaAttributes,
  CommandFunction,
  EditorState,
  ExtensionTag,
  findParentNodeOfType,
  FromToProps,
  NodeSpecOverride,
  ProsemirrorNode,
  ResolvedPos,
  Selection,
} from 'remirror'
import { TableSchemaSpec } from 'remirror/dist-types/extensions'

export function isInTable(state: Readonly<EditorState>) {
  let $head = state.selection.$head
  for (let d = $head.depth; d > 0; d--) {
    if ($head.node(d).type.spec.tableRole === 'row') {
      return true
    }
  }
  return false
}

export function selectionCell(state: EditorState) {
  let sel = state.selection as Selection
  return cellAround(sel.$head) || cellNear(sel.$head)
}

export function cellAround($pos: ResolvedPos) {
  for (let d = $pos.depth - 1; d > 0; d--) {
    if ($pos.node(d).type.spec.tableRole === 'row') {
      const data = $pos.node(0).resolve($pos.before(d + 1))
      return data
    }
  }
  return null
}

function cellNear($pos: ResolvedPos) {
  for (let after = $pos.nodeAfter, pos = $pos.pos; after; after = after.firstChild, pos++) {
    let role = after.type.spec.tableRole
    if (role === 'cell' || role === 'header_cell') {
      return $pos.doc.resolve(pos)
    }
  }
  for (let before = $pos.nodeBefore, pos = $pos.pos; before; before = before.lastChild, pos--) {
    let role = before.type.spec.tableRole
    if (role === 'cell' || role === 'header_cell') {
      return $pos.doc.resolve(pos - before.nodeSize)
    }
  }
}

export function moveCellForward($pos: ResolvedPos) {
  return $pos.node(0).resolve($pos.pos + $pos.nodeAfter!.nodeSize)
}

function findNextCell($cell: ResolvedPos, dir: number) {
  if (dir < 0) {
    let before = $cell.nodeBefore
    if (before) {
      return $cell.pos - before.nodeSize
    }
    for (let row = $cell.index(-1) - 1, rowEnd = $cell.before(); row >= 0; row--) {
      let rowNode = $cell.node(-1).child(row)
      if (rowNode.childCount) {
        return rowEnd - 1 - rowNode.lastChild!.nodeSize
      }
      rowEnd -= rowNode.nodeSize
    }
  } else {
    if ($cell.index() < $cell.parent.childCount - 1) {
      return $cell.pos + $cell.nodeAfter!.nodeSize
    }
    let table = $cell.node(-1)
    for (let row = $cell.indexAfter(-1), rowStart = $cell.after(); row < table.childCount; row++) {
      let rowNode = table.child(row)
      if (rowNode.childCount) {
        return rowStart + 1
      }
      rowStart += rowNode.nodeSize
    }
  }
}

export function goToNextCell(direction: number): CommandFunction {
  return ({ state, dispatch, tr }) => {
    if (!isInTable(state)) {
      return false
    }

    let cell = findNextCell(selectionCell(state)!, direction)
    if (cell == null) {
      return false
    }
    if (dispatch) {
      let $cell = state.doc.resolve(cell)
      dispatch(state.tr.setSelection(TextSelection.between($cell, moveCellForward($cell))).scrollIntoView())
    }
    return true
  }
}

export function setCellAttr(name: string, value: any): CommandFunction {
  return ({ state, dispatch }) => {
    if (!isInTable(state)) {
      return false
    }
    let $cell = selectionCell(state)
    if ($cell!.nodeAfter!.attrs[name] === value) {
      return false
    }
    if (dispatch) {
      let tr = state.tr
      if (state.selection instanceof CellSelection) {
        state.selection.forEachCell((node, pos) => {
          if (node.attrs[name] !== value) {
            tr.setNodeMarkup(pos, null, setAttr(node.attrs, name, value))
          }
        })
      } else {
        tr.setNodeMarkup($cell!.pos, null, setAttr($cell!.nodeAfter!.attrs, name, value))
      }
      dispatch(tr)
    }
    return true
  }
}

export function setCellAttrByResolvedPos($cell: ResolvedPos, name: string, value: any): CommandFunction {
  return ({ state, dispatch }) => {
    if ($cell!.nodeAfter!.attrs[name] === value) {
      return false
    }
    if (dispatch) {
      let tr = state.tr
      if (state.selection instanceof CellSelection) {
        state.selection.forEachCell((node, pos) => {
          if (node.attrs[name] !== value) {
            tr.setNodeMarkup(pos, null, setAttr(node.attrs, name, value))
          }
        })
      } else {
        tr.setNodeMarkup($cell!.pos, null, setAttr($cell!.nodeAfter!.attrs, name, value))
      }
      dispatch(tr)
    }
    return true
  }
}

export function setAttr(attrs: Attrs, name: string, value: any) {
  let result: { [key: string]: any } = {}
  for (let prop in attrs) {
    result[prop] = attrs[prop]
  }
  result[name] = value
  return result
}

/**
 * This function creates the base for the tableNode ProseMirror specs.
 */
export function createTableNodeSchema(
  extra: ApplySchemaAttributes,
  override: NodeSpecOverride,
): Record<'table' | 'tableRow' | 'tableCell' | 'tableHeaderCell', TableSchemaSpec> {
  const cellAttrs = {
    ...extra.defaults(),
    colspan: { default: 1 },
    rowspan: { default: 1 },
    colwidth: { default: null },
    background: { default: null },
    verticalAlign: { default: null },
    textAlign: { default: null },
    border: { default: null },
  }

  const headerCellAttrs = {
    ...cellAttrs,
    background: { default: '#8b929e' },
  }

  const tableAttrs = {
    ...extra.defaults(),
    border: { default: null },
    zebra: { default: null },
    width: { default: null },
  }

  const tableRowAttrs = {
    ...extra.defaults(),
    background: { default: null },
  }

  return {
    table: {
      isolating: true,
      ...override,
      attrs: tableAttrs,
      content: 'tableRow+',
      tableRole: 'table',
      parseDOM: [
        {
          tag: 'table',
          getAttrs: (dom) => ({ ...extra.parse(dom), ...getTableAttrs(dom as HTMLElement) }),
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM(node) {
        return ['table', { ...extra.dom(node), ...setTableAttrs(node, 25) }, ['tbody', 0]]
      },
    },

    tableRow: {
      ...override,
      attrs: tableRowAttrs,
      content: '(tableCell | tableHeaderCell)*',
      tableRole: 'row',
      parseDOM: [
        { tag: 'tr', getAttrs: (dom) => ({ ...extra.parse(dom), ...getRowAttrs(dom as HTMLElement) }) },
        ...(override.parseDOM ?? []),
      ],
      toDOM(node) {
        return ['tr', { ...extra.dom(node), ...setRowAttrs(node) }, 0]
      },
    },

    tableCell: {
      isolating: true,
      content: `${ExtensionTag.Block}+`,
      ...override,
      attrs: cellAttrs,
      tableRole: 'cell',
      parseDOM: [
        {
          tag: 'td',
          getAttrs: (dom) => ({ ...extra.parse(dom), ...getCellAttrs(dom as HTMLElement) }),
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM(node) {
        return ['td', { ...extra.dom(node), ...setCellAttrs(node) }, 0]
      },
    },

    tableHeaderCell: {
      isolating: true,
      content: `${ExtensionTag.Block}+`,
      ...override,
      attrs: headerCellAttrs,
      tableRole: 'header_cell',
      parseDOM: [
        {
          tag: 'th',
          getAttrs: (dom) => ({ ...extra.parse(dom), ...getCellAttrs(dom as HTMLElement) }),
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM(node) {
        return ['th', { ...extra.dom(node), ...setCellAttrs(node) }, 0]
      },
    },
  }
}

function getBorder(dom: HTMLElement) {
  const borderAttr = dom.getAttribute('data-border')

  if (borderAttr) {
    return borderAttr
  }

  const borderClass = dom.classList.contains('white-borders') ? 'none' : null
  if (borderClass) {
    return borderClass
  }

  const borderStyle = dom.style.border || null

  return borderStyle
}

function getTableAttrs(dom: HTMLElement) {
  const border = getBorder(dom)
  const zebra = dom.getAttribute('data-zebra')
  const width = dom.getAttribute('data-width')

  return {
    border: border,
    zebra: zebra || null,
    width: width || null,
  }
}

function setTableAttrs(node: ProsemirrorNode, cellMinWidth: number) {
  const attrs: Record<string, string> = {}

  if (node.attrs.border) {
    attrs.style = `${attrs.style ?? ''}border-style: ${node.attrs.border as string};`
    attrs['data-border'] = node.attrs.border
  }

  const nodeView = new TableView(node, cellMinWidth)

  const tableWidth = nodeView.table.style.width

  if (tableWidth) {
    attrs.style = `${attrs.style ?? ''}width: ${tableWidth};`
    attrs['data-width'] = tableWidth.replace('px', '')
  }

  return attrs
}

function getRowAttrs(dom: HTMLElement) {
  const background = dom.getAttribute('data-background')
  return {
    background: background || null,
  }
}

function setRowAttrs(node: ProsemirrorNode) {
  const attrs: Record<string, string> = {}

  if (node.attrs.background) {
    attrs.style = `${attrs.style ?? ''}background: ${node.attrs.background as string};`
    attrs['data-background'] = node.attrs.background
  }

  return attrs
}

// FIXME: copied from `table-utils`.ts

function getCellWidthAttr(colwidthAttr: string | null, widthStyle: string | null, colspan: number) {
  if (colwidthAttr && /^\d+(,\d+)*$/.test(colwidthAttr)) {
    const widths = colwidthAttr.split(',').map((s) => Number(s))

    if (widths.length === colspan) {
      return widths
    }
  }

  if (widthStyle && /^\d*\.?\d+px/.test(widthStyle)) {
    return [Number(widthStyle.replace('px', ''))]
  }

  return null
}

function getCellAttrs(dom: HTMLElement) {
  const widthAttr = dom.getAttribute('data-colwidth')
  const widthStyle = dom.style.width
  const colspan = Number(dom.getAttribute('colspan') ?? 1)
  const backgroundColor = dom.getAttribute('data-background-color')
  const verticalAlign = dom.getAttribute('data-vertical-align')
  const textAlign = dom.getAttribute('data-text-align') || dom.style.textAlign

  const table = dom.closest('table')
  const border = (table && getBorder(table)) || null

  const colwidth = getCellWidthAttr(widthAttr, widthStyle, colspan)
  return {
    colspan,
    rowspan: Number(dom.getAttribute('rowspan') ?? 1),
    colwidth: colwidth,
    background: backgroundColor || dom.style.backgroundColor || null,
    verticalAlign: verticalAlign || null,
    textAlign: textAlign || null,
    border: border || null,
  }
}

// FIXME: copied from `table-utils`.ts
function setCellAttrs(node: ProsemirrorNode) {
  const attrs: Record<string, string> = {}

  if (node.attrs.colspan !== 1) {
    attrs.colspan = node.attrs.colspan
  }

  if (node.attrs.rowspan !== 1) {
    attrs.rowspan = node.attrs.rowspan
  }

  if (node.attrs.colwidth) {
    /* attrs.style = `${attrs.style ?? ''}width: ${node.attrs.colwidth as string}px;` */
    attrs['data-colwidth'] = node.attrs.colwidth.join(',')
  }

  if (node.attrs.background) {
    attrs.style = `${attrs.style ?? ''}background-color: ${node.attrs.background as string};`
    attrs['data-background-color'] = node.attrs.background
  }

  if (node.attrs.verticalAlign) {
    attrs.style = `${attrs.style ?? ''}vertical-align: ${node.attrs.verticalAlign as string};`
    attrs['data-vertical-align'] = node.attrs.verticalAlign
  }

  if (node.attrs.textAlign) {
    attrs.style = `${attrs.style ?? ''}text-align: ${node.attrs.textAlign as string};`
    attrs['data-text-align'] = node.attrs.textAlign
  }

  if (node.attrs.border) {
    attrs.style = `${attrs.style ?? ''}border-style: ${node.attrs.border as string};`
    attrs['data-border'] = node.attrs.border
  }

  return attrs
}

interface GenerateDecorationsProps extends FromToProps {
  /**
   * The starting decoration set.
   *
   * @defaultValue DecorationSet.empty
   */
  decorationSet?: DecorationSet

  /**
   * The document which is being acted on.
   */
  doc: ProsemirrorNode

  /**
   * A list of the whitespace decorators which are used to create decorations
   * from the provided ranges.
   */
  decorators: TableDecorator[]
}

interface TableDecoratorRange extends FromToProps {
  doc: ProsemirrorNode
  decorationSet: DecorationSet
}

type TableDecorator = (details: TableDecoratorRange) => DecorationSet

export function generateDecorations(props: GenerateDecorationsProps): DecorationSet {
  const { from, to, doc, decorators } = props
  let { decorationSet = DecorationSet.empty } = props

  for (const decorator of decorators) {
    decorationSet = decorator({ decorationSet, doc, from, to })
  }

  return decorationSet
}

export function checkIfNewCellsWereAdded(tr: Transaction) {
  if (!tr.docChanged || !tr.steps.length) {
    return false
  }

  const isNewCellAdded = tr.steps.some((step) => {
    if ('slice' in step) {
      const replaceStep = step as ReplaceStep
      const replaceStepFragment = replaceStep.slice.content as Fragment & { content: ProsemirrorNode[] }
      const contents = replaceStepFragment.content
      const newCellAddedInStep = contents.some((node) => node.type.name === 'tableCell')
      return newCellAddedInStep
    }
  })

  return isNewCellAdded
}

// export function reapplyTableStyles(tr: Transaction, dispatch: DispatchFunction): boolean {
//   const currentTableBorders = this.getTableBorders(tr)
//   console.log('currentTableBorders', currentTableBorders)
//   if (currentTableBorders) {
//     this.setTableBorders(tr, dispatch!, currentTableBorders)
//   }
//   return true
// }

export function getTableAttributes(tr: Transaction): Attrs | null {
  const foundTable = findParentNodeOfType({ selection: tr.selection, types: 'table' })
  if (foundTable) {
    return foundTable.node.attrs
  }
  return null
}

export function getAllTableCellsAttributes(tr: Transaction): { pos: number; attrs: Attrs }[] | null {
  const { selection } = tr
  const foundTable = findParentNodeOfType({ selection, types: 'table' })
  if (foundTable) {
    const foundTableCells: { node: ProsemirrorNode; pos: number }[] = []
    foundTable.node.descendants((node, pos) => {
      if (node.type.name === 'tableCell') {
        foundTableCells.push({ node, pos: pos + foundTable.pos + 1 })
        return false
      }
      return true
    })

    const attrsFound = foundTableCells.map((tableCell) => {
      return {
        pos: tableCell.pos,
        attrs: tableCell.node.attrs,
      }
    })
    return attrsFound
  }
  return null
}

export function setSelectedTableAttribute(tr: Transaction, attributes: Attrs): Transaction {
  const { selection } = tr

  const found = findParentNodeOfType({ selection, types: 'table' })

  if (found) {
    tr.setNodeMarkup(found.pos, undefined, { ...found.node.attrs, ...attributes })
  }
  return tr
}

export function setSelectedTableCellAttribute(tr: Transaction, attributes: Attrs): Transaction {
  const { selection } = tr

  if (selection instanceof CellSelection) {
    selection.forEachCell((cellNode, pos) => {
      tr.setNodeMarkup(pos, undefined, { ...cellNode.attrs, ...attributes })
    })
    return tr
  }

  const found = findParentNodeOfType({ selection, types: 'tableCell' })

  if (found) {
    tr.setNodeMarkup(found.pos, undefined, { ...found.node.attrs, ...attributes })
  }

  return tr
}

export function setSelectedTableAndChildAttributes(tr: Transaction, attributes: Attrs): Transaction {
  const { selection } = tr
  const foundTable = findParentNodeOfType({ selection, types: 'table' })

  if (foundTable) {
    const foundTableCells: { node: ProsemirrorNode; pos: number }[] = []
    foundTable.node.descendants((node, pos) => {
      if (node.type.name === 'tableCell') {
        foundTableCells.push({ node, pos: pos + foundTable.pos + 1 })
        return false
      }
      return true
    })
    foundTableCells.reverse().forEach((tableCell) => {
      tr.setNodeMarkup(tableCell.pos, undefined, { ...tableCell.node.attrs, ...attributes })
    })
  }
  return tr
}
