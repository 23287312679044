import * as apiService from 'lib/apollo/client'
import { EntityId } from 'types/entity.interface'
import {
  Communication,
  GetStyledCommunicationDocument,
  GetStyledCommunicationMutation,
  GetStyledCommunicationMutationVariables,
  Load360ViewDocument,
  Load360ViewQuery,
  Load360ViewQueryVariables,
  LoadCommunicationDocument,
  LoadCommunicationQuery,
  LoadCommunicationQueryVariables,
  LoadCommunicationsByApptIdDocument,
  LoadCommunicationsByApptIdQuery,
  LoadCommunicationsByApptIdQueryVariables,
  LoadCommunicationsDocument,
  LoadCommunicationsQuery,
  LoadCommunicationsQueryVariables,
  LogPdfCommunicationDocument,
  LogPdfCommunicationMutation,
  LogPdfCommunicationMutationVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

class CommunicationsService {
  async load360View(range: { start: string; end: string }) {
    const { load360View: resp } = await apiService.query<Load360ViewQuery, Load360ViewQueryVariables>(
      Load360ViewDocument,
      { start: range.start, end: range.end },
      { fetchPolicy: 'no-cache' },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.communications
  }

  async loadCommunication(id: EntityId) {
    const { loadCommunication: resp } = await apiService.query<LoadCommunicationQuery, LoadCommunicationQueryVariables>(
      LoadCommunicationDocument,
      { id },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.communication
  }

  async loadCommunications(ids: EntityId[]): Promise<Communication[]> {
    const { loadCommunications: resp } = await apiService.query<
      LoadCommunicationsQuery,
      LoadCommunicationsQueryVariables
    >(LoadCommunicationsDocument, { ids })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.communications as Communication[]
  }

  async loadCommunicationsByApptID({
    appointmentIds,
    contactId,
  }: {
    appointmentIds?: EntityId[]
    contactId?: EntityId
  }) {
    const { loadCommunicationsByApptID: resp } = await apiService.query<
      LoadCommunicationsByApptIdQuery,
      LoadCommunicationsByApptIdQueryVariables
    >(LoadCommunicationsByApptIdDocument, { appointmentIds, contactId }, { fetchPolicy: 'no-cache' })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.communications as Communication[]
  }

  async logPDFCommunication({
    html,
    content,
    contactIds,
    appointmentId,
    templateCategoryId,
    source,
    name,
    documentId,
  }: {
    html: string
    content: string
    contactIds: EntityId[]
    appointmentId?: EntityId
    templateCategoryId?: EntityId
    source?: string
    name?: string
    documentId?: EntityId
  }) {
    const { logPDFCommunication: resp } = await apiService.mutate<
      LogPdfCommunicationMutation,
      LogPdfCommunicationMutationVariables
    >(LogPdfCommunicationDocument, {
      html,
      name,
      content,
      statusData: { origin: 'browser' },
      appointmentId,
      templateCategoryId,
      contactIds,
      source,
      documentId,
    })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.communication
  }

  /**
   * Calls BE function to style communication.
   * @deprecated no styles are inherited from server now. All is being done on client side
   */
  async getStyledCommunication(html: string) {
    const { getStyledCommunication: resp } = await apiService.mutate<
      GetStyledCommunicationMutation,
      GetStyledCommunicationMutationVariables
    >(GetStyledCommunicationDocument, {
      html,
    })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.html
  }
}

export default new CommunicationsService()
