export const DISPLAY_MODE = {
  INLINE: 'inline',
  PARAGRAPH: 'paragraph',
  OLLIST: 'ol-list',
  ULLIST: 'ul-list',
  CHECKLIST: 'check-list',
} as const

export type DisplayMode = (typeof DISPLAY_MODE)[keyof typeof DISPLAY_MODE]

export const DISPLAY_MODE_LABELS = {
  [DISPLAY_MODE.INLINE]: 'Continuous Notes',
  [DISPLAY_MODE.PARAGRAPH]: 'Paragraphs',
  [DISPLAY_MODE.OLLIST]: 'Numbered List',
  [DISPLAY_MODE.ULLIST]: 'Bullet List',
  [DISPLAY_MODE.CHECKLIST]: 'Check List',
} as const
