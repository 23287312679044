import { Form, FormInstance, Select } from 'antd'
import { FormItemLabel } from 'components/drawers/Smartfields/smartfield.styles'
import { isEmpty } from 'lodash'
import { autorun } from 'mobx'
import { useEffect } from 'react'
import { GenericSmartfieldOptions } from './generic.replacer'

interface GenericContactsProps {
  options: Pick<
    GenericSmartfieldOptions,
    'firstNameProp' | 'lastNameProp' | 'onContactSelect' | 'selectedContactId'
  >
  contacts: any[]
  loading: boolean
  form: FormInstance
}

export const GenericConnectorContacts: React.FC<GenericContactsProps> = ({
  options,
  contacts,
  loading,
  form,
}) => {
  const {
    firstNameProp = 'first_name',
    lastNameProp = 'last_name',
    onContactSelect,
    selectedContactId,
  } = options

  useEffect(() => {
    return autorun(() => {
      const contact = form.getFieldValue('contact_id')

      if (contacts.length && isEmpty(contact) && selectedContactId) {
        form.setFieldValue('contact_id', selectedContactId)
      }
    })
  }, [contacts, form, selectedContactId])

  return (
    <Form.Item
      name="contact_id"
      rules={[{ required: true, message: 'Please select a contact' }]}
      label={<FormItemLabel label="Contact" description="Select a contact" />}
    >
      <Select
        onChange={onContactSelect}
        placeholder={loading ? 'Fetching contacts..' : 'Select a contact'}
        disabled={loading}
        style={{ width: '100%' }}
        size="large"
        loading={loading}
        showSearch
        filterOption={(input, option) =>
          (`${option?.label}` ?? '').toLowerCase().includes(input.toLowerCase())
        }
      >
        {contacts.map((contact) => (
          <Select.Option
            value={contact.id}
            label={`${contact[firstNameProp]} ${contact[lastNameProp]}`}
          >
            {`${contact[firstNameProp]} ${contact[lastNameProp]}`}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
