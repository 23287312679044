import { autorun } from 'mobx'
import { useCallback, useEffect, useState } from 'react'
import permissionService from 'stores/services/permission.service'
import { Plan } from 'types/graphql'

export const usePlans = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [plans, setPlans] = useState<Plan[]>([])

  const onUpgradePlan = useCallback(async (planId: string) => {
    global.app.wait = 'Upgrading plan...'
    await permissionService.changePlan({ planId })
    window.location.reload()
    global.app.wait = false
  }, [])

  useEffect(() => {
    return autorun(async () => {
      setShowModal(global.perms?.showUpgradeModal)

      if (global.perms?.showUpgradeModal) {
        const { plans } = await permissionService.plans()
        const enabled = plans.filter((plan) => plan.enabled || plan.id === global.data.practice.planId)

        setPlans(enabled)
      }
    })
  }, [])

  return {
    plans,
    showModal,
    onUpgradePlan,
  }
}
