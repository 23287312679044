import { SmartField } from 'components/drawers/Smartfields/types'
import { SmartFieldConfig, SmartFieldNumberConfig } from 'components/drawers/Smartfields/types/data'
import Str from '../../../../../stores/util/str.util'

export const formatLabel = (smartfield: SmartField): string => {
  return smartfield?.label ?? ''
}

export const formatDesc = (smartfield: SmartField): string => {
  if (smartfield?.type === 'date') {
    return 'Select a date:'
  }

  if (smartfield?.type === 'list') {
    return 'Select a list option:'
  }

  if (smartfield?.type === 'number') {
    const numberConfig = smartfield.config as SmartFieldConfig<SmartFieldNumberConfig>
    const parts = []

    if (numberConfig.format !== 'automatic') {
      parts.push(`${Str.startCase(numberConfig.format)} format`)
    }

    if (numberConfig.format !== 'percent' && (numberConfig.places ?? 0) > 0) {
      parts.push(`${numberConfig.places} decimals`)
    }

    if (parts.length) {
      return `Enter a number: (${parts.join(', ')})`
    }

    return 'Enter a number:'
  }

  if (smartfield?.type === 'text') {
    return 'Enter your text:'
  }

  return smartfield?.label ?? ''
}
