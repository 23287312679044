import { throttle } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import styles from 'styles'

class Viewport {
  width = 0
  height = 0

  get isMobile() {
    return this.width < styles.media.points.tablet
  }

  get isTablet() {
    return this.width >= styles.media.points.mobile && this.width < styles.media.points.laptop
  }

  get isTabletUp() {
    return this.width >= styles.media.points.tablet
  }

  get isTabletDown() {
    return this.width < styles.media.points.laptop
  }

  get isLaptop() {
    return this.width >= styles.media.points.laptop && this.width < styles.media.points.desktop
  }

  get isLaptopUp() {
    return this.width >= styles.media.points.laptop
  }

  get isLaptopDown() {
    return this.width < styles.media.points.desktop
  }

  get isDesktop() {
    return this.width >= styles.media.points.desktop
  }

  get availableWidth() {
    const collapsed = global.app.leftNavCollapsed
    const offset = collapsed ? styles.sideMenuCollapsedWidth : styles.sideMenuWidth
    return this.width - offset
  }

  get current() {
    if (this.isMobile) {
      return 'mobile'
    }

    if (this.isTablet) {
      return 'tablet'
    }

    if (this.isLaptop) {
      return 'laptop'
    }

    return 'desktop'
  }

  constructor() {
    makeObservable(this, {
      width: observable,
      height: observable,
      isMobile: computed,
      isTablet: computed,
      isTabletUp: computed,
      isTabletDown: computed,
      isLaptop: computed,
      isLaptopUp: computed,
      isLaptopDown: computed,
      isDesktop: computed,
      availableWidth: computed,
      current: computed,
      resize: action.bound,
    })

    this.resize()
    this.onResize = throttle(this.resize, 500)
    window.addEventListener('resize', this.onResize, this)
  }

  /**
   *
   */
  resize() {
    const { clientWidth, clientHeight } = document.documentElement
    this.width = clientWidth
    this.height = clientHeight
  }
}

export default Viewport
