import { blue, gold, green, red, yellow, generate } from '@ant-design/colors'
import { lighten } from 'polished'

// const grey = '#D3DCE6'
const grey = '#e6eaf0'

export const logoColors = {
  pink: '#db007a',
  lime: '#aec944',
  blue: '#30bdea',
  purple: '#9355d2',
}

interface Colors {
  [color: string]: string
}

const brandPalette = generate('#16ac19')

const brand: Colors = {
  primary: brandPalette[5],
  selectedBackground: brandPalette[0],
  hover: brandPalette[4],
  click: brandPalette[6],
}

const neutralColors = {
  gray1: '#ffffff',
  gray2: '#fafafa',
  gray3: '#f5f5f5',
  gray4: '#f0f0f0',
  gray5: '#d9d9d9',
  gray6: '#bfbfbf',
  gray7: '#8c8c8c',
  gray8: '#595959',
  gray9: '#434343',
  gray10: '#262626',
  gray11: '#1f1f1f',
  gray12: '#141414',
  gray13: '#000000',
}

const functionalColors: Colors = {
  link: blue.primary!,
  success: green.primary!,
  warning: gold.primary!,
  error: red[4],
}

const fileTypeColors: Colors = {
  pdf: red[4],
  png: red[5],
  gif: red[5],
  jpeg: red[5],
  jpg: red[5],
  xls: green[6],
  xlsx: green[6],
  csv: blue[6],
  doc: blue[4],
  docx: blue[4],
  txt: blue[4],
  zip: neutralColors.gray8,
  unknownFile: neutralColors.gray8,
  folder: neutralColors.gray8,
}

const colors: Colors = {
  // primary
  primary: brand.primary,
  lightPrimary: brand.hover,
  darkPrimary: brand.click,

  // colours
  red: '#f64f64',
  orange: '#F7BA2A',
  darkOrange: '#fa8314',
  // yellow: '#FFFF47',
  yellow: '#ffe000',
  lime: '#A3FF47',
  green: brand.primary,
  cyan: '#13c2c2',
  blue: '#20A0FF',
  logoBlue: logoColors.blue,
  deepBlue: '#4747ff',
  geekblue: '#4747ff',
  purple: '#a347ff',
  lightPurple: lighten(0.5, '#a347ff'),
  volcano: '#f64f64',
  magenta: '#eb2f96',
  gold: '#faad14',
  darkPurple: '#733E96',

  // used for summary-agenda-client-todo tags
  lightBlue: '#eff8ff',
  lightGreen: '#f6ffed',
  lightRed: '#fbeded',

  // red: '#FF4949',
  // orange: '#f8b334',
  // yellow: '#FFFF47',
  // lime: '#aec944',
  // green: '#53aa28',
  // cyan: '#13c2c2',
  // blue: '#30bdea',
  // deepBlue: '#4747ff',
  // purple: '#9355d2',
  // volcano: '#f15a24',
  // tones

  // black: '#1F2D3D',
  black: '#263545',
  lightBlack: '#324057',
  extraLightBlack: 'rgba(71, 86, 105, 0.8)',
  reallyLightBlack: 'rgba(71, 86, 105, 0.5)',
  ultraLightBlack: 'rgba(71, 86, 105, 0.25)',

  silver: '#8492A6',
  lightSilver: '#99A9BF',
  extraLightSilver: '#C0CCDA',

  grey: grey,
  lightGrey: '#E5E9F2',
  extraLightGrey: '#EFF2F7',
  reallyLightGrey: '#f8f9fb',
  coolGrey: '#f5f9ff',

  white: '#ffffff',
  // darkWhite: '#f9f9f9',
  darkWhite: '#f6f7fb',
  darkerWhite: '#e1e2e6',
  offWhite: '#fbfcff',
  // ui

  get lightBorder() {
    return this.extraLightGrey
  },
  get layoutBorder() {
    return this.lightGrey
  },

  get greenHover() {
    return lighten(0.5, this.green as string)
  },

  hover: '#e6f7ff',
  selected: '#d4f1ff',
  disabled: '#f5f5f5',
  disabledBorder: '#d9d9d9',

  get filterColor() {
    return this.darkWhite
  },
  get filterHoverColor() {
    return this.darkerWhite
  },

  transparent: 'transparent',

  // background
  background: neutralColors.gray3,

  // neutral colors
  ...neutralColors,

  // functional colors
  ...functionalColors,

  // Brand Colors
  ...brand,

  // text
  heading: 'rgba(0, 0, 0, 0.85)', // heading text color
  text: 'rgba(0, 0, 0, 0.65)', // major text color
  textSecondary: 'rgba(0, 0, 0, 0.45)', // secondary text color

  // borders
  border: '#EAEAEA',

  // elements
  star: yellow[5],

  // file types
  ...fileTypeColors,
}

export default colors
