// icon:text-slash | Fontawesome https://fontawesome.com/ | Fontawesome
import * as React from 'react'

function IconTextSlash(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 640 512" fill="currentColor" height="1em" width="1em" {...props}>
      <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2s-6.3 25.5 4.1 33.7l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L355.7 253.5 400.2 96H503l-6 24.2c-4.3 17.1 6.1 34.5 23.3 38.8s34.5-6.1 38.8-23.3l16-64c2.4-9.6.2-19.7-5.8-27.5S553.9 32 544 32H192c-14.7 0-27.5 10-31 24.2l-9.3 37.3L38.8 5.1zm168 131.7c.1-.3.2-.7.3-1L217 96h116.7l-32.4 114.8-94.5-74.1zM243.3 416H192c-17.7 0-32 14.3-32 32s14.3 32 32 32h160c17.7 0 32-14.3 32-32s-14.3-32-32-32h-42.2l17.6-62.1-54.5-42.9-29.6 105z" />
    </svg>
  )
}

export default IconTextSlash
