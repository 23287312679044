import { Alert, AlertProps, Button } from 'antd'

interface GenericProviderErrorAlertProps extends AlertProps {
  error: string
  testid?: string
}

export const GenericProviderErrorAlert: React.FC<GenericProviderErrorAlertProps> = ({
  error,
  style,
  testid = 'error',
}) => {
  return <Alert message={error} showIcon type="error" style={style} data-testid={`smartfield-alert-${testid}`} />
}

export const UnsupportedProviderAlert: React.FC = () => {
  return (
    <GenericProviderErrorAlert error="SmartFields do not yet support this Integration" testid="unsupported-provider" />
  )
}

export const ChangeReusableAlert: React.FC = () => {
  return (
    <Alert
      message="Any changes will affect all SmartField's in the workspace"
      style={{ marginBottom: 16 }}
      type="warning"
      showIcon
    />
  )
}

interface ConnectionErrorAlertProps {
  provider: string
}

export const ConnectionErrorAlert: React.FC<ConnectionErrorAlertProps> = ({ provider }) => {
  return (
    <GenericProviderErrorAlert
      error="There's a problem with your Integration"
      testid="connection-error"
      action={
        <Button
          onClick={() => {
            global.router.goto(`/settings/integrations/${provider}`)
          }}
          danger
        >
          Fix this
        </Button>
      }
    />
  )
}
