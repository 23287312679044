import {
  SmartFieldConfig,
  SmartFieldIntegrationConfig,
} from 'components/drawers/Smartfields/types/data'
import {
  RiskalyzeFormValues,
  SmartFieldFormValues,
  SmartFieldIntegrationFormValues,
} from 'components/drawers/Smartfields/types/form'
import { RiskalyzeConfig } from 'components/drawers/Smartfields/types/integration'
import { ConfigParser, FormParser } from '../provider.interfaces'
import {
  riskalyzeModelConfigParser,
  riskalyzeModelParser,
} from './types/data/parser'
import {
  riskalyzeQuestionaireConfigParser,
  riskalyzeQuestionaireParser,
} from './types/questionaire/parser'

export const riskalyzeParser: FormParser = (values, config) => {
  const cfg = config as SmartFieldIntegrationConfig<RiskalyzeConfig>
  const vals = values as SmartFieldFormValues<
    SmartFieldIntegrationFormValues<RiskalyzeFormValues>
  >

  cfg.provider = 'riskalyze'
  cfg.dataType = vals.dataType

  switch (vals.dataType) {
    case 'questionaire':
      config = riskalyzeQuestionaireParser(values, config)
      break
    default:
      config = riskalyzeModelParser(values, config)
      break
  }

  return config as SmartFieldConfig
}

export const riskalyzeConfigParser: ConfigParser = (values, config) => {
  const cfg = config as SmartFieldConfig<
    SmartFieldIntegrationConfig<RiskalyzeConfig>
  >
  const vals = values as SmartFieldFormValues<
    SmartFieldIntegrationFormValues<RiskalyzeFormValues>
  >

  switch (cfg.dataType) {
    case 'questionaire':
      riskalyzeQuestionaireConfigParser(values, cfg)
      break
    default:
      riskalyzeModelConfigParser(values, cfg)
      break
  }

  return vals
}
