/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Cascader, Form } from 'antd'
import { DefaultOptionType } from 'antd/lib/cascader'
import { SmartFieldContainer } from 'components/drawers/Smartfields/smartfield.container'
import { FormItemLabel } from 'components/drawers/Smartfields/smartfield.styles'
import { SmartFieldProvider } from 'components/drawers/Smartfields/types'
import {
  SmartFieldConfig,
  SmartFieldIntegrationConfig,
} from 'components/drawers/Smartfields/types/data'
import { GenericConnectorConfig } from 'components/drawers/Smartfields/types/integration'
import { isArray } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useConnection } from 'stores/connections/connection.hooks'
import { SmartFieldIntegrationFormatter } from '../../components/format.selector'
import { ProviderSmartFieldsBuilderProps } from '../provider.interfaces'
import { CascaderNode, cascaderOptions, parseDataFields } from './generic.utils'

interface SmartFieldIntegrationGenericBuilderProps
  extends ProviderSmartFieldsBuilderProps {
  contactFields: any
  provider: SmartFieldProvider
  providerTitle: string
}

export const SmartFieldIntegrationGenericBuilder: React.FC<
  SmartFieldIntegrationGenericBuilderProps
> = ({ smartfield, form, contactFields, provider, providerTitle }) => {
  const config = smartfield?.config as SmartFieldConfig<
    SmartFieldIntegrationConfig<GenericConnectorConfig>
  >
  const [options, setOptions] = useState<CascaderNode[]>([])

  const { connected } = useConnection(provider)

  const onChange = useCallback(
    (value: any, options: any) => {
      if (isArray(options) && options.length) {
        const newLabel = options.map((o) => o.label).join(' ')
        form.setFieldValue('label', newLabel)
      }
    },
    [form],
  )

  useEffect(() => {
    setOptions(cascaderOptions(contactFields))
  }, [contactFields])

  return (
    <SmartFieldContainer
      title={`${providerTitle} Options`}
      connectionError={!connected}
      provider={provider}
      testId={`smartfield-${provider}-form`}
    >
      <Form.Item
        name="data_fields"
        rules={[{ required: true, message: `Select model field` }]}
        label={
          <FormItemLabel
            label={`Data field`}
            description={`Select field to use`}
          />
        }
        initialValue={parseDataFields(config?.data_fields)}
        preserve={false}
      >
        <Cascader
          options={options}
          onChange={onChange}
          placeholder={`Select model field`}
          size="large"
          allowClear
          autoFocus
          showSearch={{
            filter: (inputValue: string, path: DefaultOptionType[]) =>
              path.some(
                (option) =>
                  (option.label as string)
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) > -1,
              ),
          }}
        />
      </Form.Item>

      <SmartFieldIntegrationFormatter form={form} smartfield={smartfield} />
    </SmartFieldContainer>
  )
}
