/* eslint-disable */
import * as Sentry from '@sentry/browser'
import initHelpHero from 'helphero'
import { get, uniqueId } from 'lodash'
import { isDEV, isPROD } from './env'
const ENV = process.env.REACT_APP_ENV
const chunkLoadError = 'ChunkLoadError'
let hlp = undefined

export const HelpHeroTours = {
  AddNotesHelp: { tourId: 'idRElTR0Jd', startingStep: 'cku32jc0k007r3a5tbcfa57u5' },
  EmptyContact: { tourId: 'esR2ll4Qdhn', startingStep: 'ckn9kub1100363b5s81gyfosl' },
  EmptyQuickNotes: { tourId: '6FmlTCr9r4d', startingStep: 'ckn8f61zp09jy3b5s9eiv0pic' },
  EmptyEvent: { tourId: 'dTpDdDONCWd', startingStep: 'ckn9mboju0r8d3b5sopv3zy3h' },
  EmptyEventNotes: { tourId: '6FmlTCr9r4d', startingStep: 'ckn8f61zp09jy3b5s9eiv0pic' },
  EmptyAllNotes: { tourId: 'JYaLZyMDWxX', startingStep: 'ckn9n1lqd16vq3b5s3d6vxkp1' },
}

/**
 * Capture an event and send it to Sentry, with extra data
 * @param {{message:!String, data:Object}} eventData
 */
export function captureEvent({ message, data }) {
  Sentry.addBreadcrumb({ message, data: { ...data } })
  Sentry.captureEvent({ message })
}

export function captureExceptionSilently(error, { message, data = {} }) {
  Sentry.addBreadcrumb({ message, data: { ...data } })

  Sentry.withScope((scope) => {
    scope.setExtra('silent', true)
    Sentry.captureException(error)
  })
}

export const update = (name, pool, user) => {
  {
    const scope = Sentry.getCurrentScope()
    scope.setTag('workspace', name)
    scope.setTag('pool', pool)
    scope.setUser(user)
  }

  if (!hlp) {
    return
  }

  if (user) {
    hlp.identify(user.email, {
      workspace: name,
      email: user.email,
      nickname: user.nickName,
    })
  } else {
    hlp.anonymous()
  }
}

export const identifyHelpHero = (advisor, workspaceName) => {
  if (!hlp) {
    return
  }
  if (advisor) {
    hlp.identify(advisor.email, {
      workspace: workspaceName,
      email: advisor.email,
      nickname: advisor.nickName,
    })
  } else {
    hlp.anonymous()
  }
}

export const updateHelpHero = (params) => {
  if (!hlp) {
    return
  }
  if (params && typeof params === 'object') {
    hlp.update(params)
  }
}

export const startHelpHeroTour = (tourName, skipIfAlreadySeen = false) => {
  if (!hlp) {
    return
  }
  const tour = HelpHeroTours[tourName]
  if (tour) {
    console.info('Help Hero Tour', tourName, JSON.stringify(tour))
    hlp.startTour(tour.tourId, { skipIfAlreadySeen, stepId: tour.startingStep })
  } else {
    console.error('Invalid tour', tourName, tour)
  }
}

/**
 * @param {*} advisorId
 */
export default () => {
  if (isDEV || isPROD) {
    Sentry.init({
      environment: ENV === 'dev' ? 'development' : 'production',
      dsn: process.env.REACT_APP_SENTRY_ENDPOINT,
      release: process.env.REACT_APP_APP_VERSION,
      enableJavaScript: true,
      enableNative: true,
      maxBreadcrumbs: 20,
      ignoreErrors: [
        /ResizeObserver/,
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications',
      ],
      beforeSend(event) {
        // if it's a ChunkLoadError, reload page and don't send event
        const errorType = get(event, 'exception.values[0].type', 'none')
        if (errorType === chunkLoadError) {
          window.location.reload()
          return null
        }
        const isSilent = get(event, 'extra.silent', false)

        if (IsFroalaError(event)) {
          return null
        }

        // Check if it is an exception, if so, show the report dialog
        // Note that this only will work in the renderer process, it's a noop on the main process
        if (event.exception && !isSilent) {
          console.error('Exception', JSON.stringify(event.exception))
          Sentry.showReportDialog()
        }
        return event
      },
    })
    Sentry.getCurrentScope().setUser({ id: uniqueId(), user: null })

    hlp = initHelpHero('lBGBLDwISJX')
  }
}

export function _buildTrackNote(summary, topic, creationContext) {
  const tags = Array.isArray(summary.tags) ? summary.tags : []
  const noteText = typeof summary.text === 'string' ? summary.text : ''
  return {
    creationContext,
    destinationContext: global.data.appt?.isGlobal ? 'Global Appt' : 'Appt',
    Topic: topic.id + ':' + topic.name,
    MicroTemplateId: summary.id,
    hasFlexFields: noteText.includes('{{'),
    tags: tags.map((tag) => tag?.name).filter(Boolean),
    numberOfTasksAttached: Number(!!summary.taskTemplate),
    numberOfWorkflowsAttached: Number(!!summary.workflowTemplate),
    numberOfTagsAttached: tags.length,
    numberOfFilesAttached: [summary.file1, summary.file2, summary.file3].filter(Boolean).length,
  }
}

function IsFroalaError(event) {
  const errorType = get(event, 'exception.values[0].type', null)
  const errorValue = get(event, 'exception.values[0].value', null)

  if (!errorType || !errorValue) {
    return false
  }

  return !!event.extra?.arguments?.some((arg) => arg.target.includes('fr-wrapper'))
}
