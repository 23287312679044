const POINTS_NAME = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  LAPTOP: 'laptop',
  DESKTOP: 'desktop',
  LARGE_DESKTOP: 'large_desktop',
}

const points = {
  [POINTS_NAME.MOBILE]: 0,
  [POINTS_NAME.TABLET]: 600,
  [POINTS_NAME.LAPTOP]: 1000,
  [POINTS_NAME.DESKTOP]: 1500,
  [POINTS_NAME.LARGE_DESKTOP]: 1800,
}

const mobile = (content) => `@media (max-width: ${points.tablet - 1}px) { ${content} }`

const tablet = (content) =>
  `@media (min-width: ${points.tablet}px) and (max-width: ${points.laptop - 1}px) { ${content} }`
const tabletUp = (content) => `@media (min-width: ${points.tablet}px) { ${content} }`
const tabletDown = (content) => `@media (max-width: ${points.laptop - 1}px) { ${content} }`

const laptop = (content) =>
  `@media (min-width: ${points.laptop}px) and (max-width: ${points.desktop - 1}px) { ${content} }`
const laptopUp = (content) => `@media (min-width: ${points.laptop}px) { ${content} }`
const laptopDown = (content) => `@media (max-width: ${points.desktop - 1}px) { ${content} }`

const desktop = (content) => `@media (min-width: ${points.desktop}px) { ${content} }`

const largeDesktop = (content) => `@media (min-width: ${points.large_desktop}px { ${content} })`

const media = {
  POINTS_NAME,
  points,
  mobile,
  tablet,
  tabletUp,
  tabletDown,
  laptop,
  laptopUp,
  laptopDown,
  desktop,
  largeDesktop,
}

export default media
