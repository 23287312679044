export const fontFamilyAvailable: string[] = [
  'Arimo',
  'Crimson Text',
  'Dancing Script',
  'EB Garamond',
  'Lato',
  'Montserrat',
  'Old Standard TT',
  'Open Sans',
  'Roboto',
]
