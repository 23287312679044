/* eslint-disable @typescript-eslint/ban-ts-comment */
import { GenericConnectorConfig } from 'components/drawers/Smartfields/types/integration'
import { loadContact, loadContacts } from 'stores/services/wealthbox.service'
import { GenericReplacer } from '../genericData/generic.replacer'
import { ProviderSmartFieldsReplacerProps } from '../provider.interfaces'

export const WealthboxReplacer: React.FC<
  ProviderSmartFieldsReplacerProps<GenericConnectorConfig>
> = (props) => (
  <GenericReplacer
    {...props}
    options={{
      provider: 'wealthbox-connector',

      loadContact,
      loadContacts,

      selectedContactId: global.wealthbox._contactId,
      onContactSelect: (contactId: string) => {
        global.wealthbox._contactId = contactId
      },
    }}
  />
)
