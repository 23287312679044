import { isString } from 'lodash'
import {
  EditorState,
  ErrorConstant,
  getTextSelection,
  invariant,
  isChrome,
  isMarkType,
  isNodeType,
  preserveSelection,
  ProsemirrorNode,
  ReplaceTextProps,
  Selection,
  Transaction,
} from 'remirror'

export interface ReplaceTextCustomProps extends ReplaceTextProps {
  state: Readonly<EditorState>
  tr: Transaction
}

export function replaceTextCustom(props: ReplaceTextCustomProps): Transaction {
  const { attrs = {}, appendText = '', content = '', keepSelection = false, range, state, tr } = props
  const schema = state.schema
  const selection = getTextSelection(props.selection ?? range ?? tr.selection, tr.doc)
  const index = selection.$from.index()
  const { from, to, $from } = selection

  const type = isString(props.type) ? schema.nodes[props.type] ?? schema.marks[props.type] : props.type

  invariant(isString(props.type) ? type : true, {
    code: ErrorConstant.SCHEMA,
    message: `Schema contains no marks or nodes with name ${type}`,
  })

  if (isNodeType(type)) {
    if (!$from.parent.canReplaceWith(index, index, type)) {
      return tr
    }

    tr.replaceWith(from, to, type.create(attrs, content ? schema.text(content) : undefined))
  } else {
    invariant(content, {
      message: '`replaceText` cannot be called without content when using a mark type',
    })

    tr.replaceWith(from, to, schema.text(content, isMarkType(type) ? [type.create(attrs)] : undefined))
  }

  // Only append the text if text is provided (ignore the empty string).
  if (appendText) {
    tr.insertText(appendText)
  }

  if (keepSelection) {
    preserveSelection(state.selection, tr)
  }

  // A workaround for a chrome bug
  // https://github.com/ProseMirror/prosemirror/issues/710#issuecomment-338047650
  if (isChrome(60)) {
    document.getSelection()?.empty()
  }
  return tr
}

export function reapplySelectionMarks(tr: Transaction, selection: Selection): Transaction {
  const currentSelection = selection as Selection & { node: ProsemirrorNode }
  if (currentSelection.node && currentSelection.node.marks) {
    currentSelection.node.marks.forEach((mark) => tr.addMark(currentSelection.from, currentSelection.to, mark))
  }
  return tr
}
