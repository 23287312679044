import { autorun } from 'mobx'
import { useEffect, useState } from 'react'
import { smartfieldStore } from 'stores/smartfields'

export const useReplaceSmartfieldsModal = () => {
  const [visible, setVisible] = useState(smartfieldStore.visible)

  useEffect(() => {
    return autorun(() => {
      setVisible(smartfieldStore.visible)
    })
  }, [])

  return {
    visible,
  }
}
