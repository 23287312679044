import { FormInstance } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { smartfieldStore } from 'stores/smartfields'
import { ERRORS } from '../../riskalyze.errors'
import { ModelSelects, parseDataFields, parseRequiredSelects } from './data.utils'

export const useModelSelects = (client: any, dataFields: any, form: FormInstance, uid: string) => {
  const [selects, setSelects] = useState<ModelSelects[]>([])
  const [indices, setIndices] = useState<number[]>([])
  const [error, setError] = useState<string | undefined>()

  const onSelectIndice = useCallback(
    (indice = 0, index: number) => {
      const newIndices = [...indices]
      newIndices[index] = indice

      setIndices(newIndices)
      setError(undefined)
    },
    [indices],
  )

  const onUpdate = useCallback(
    (val: any) => {
      smartfieldStore.update({ [uid]: val })
      form?.setFieldValue(uid, val)
    },
    [form, uid],
  )

  useEffect(() => {
    if (client?.id) {
      const fields = parseDataFields(dataFields)
      setIndices(new Array(fields.length))
      setError(undefined)
      setSelects([])
    }
  }, [client?.id, dataFields])

  useEffect(() => {
    if (indices?.length) {
      try {
        setSelects(parseRequiredSelects(client, dataFields, indices, onUpdate))
        setError(undefined)
      } catch (er) {
        setError(ERRORS.UNEXPECTED_ERROR)
      }
    }
  }, [client, indices, dataFields, onUpdate])

  return useMemo(
    () => ({
      selects,
      error,
      onSelectIndice,
    }),
    [selects, error, onSelectIndice],
  )
}
