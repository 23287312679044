import { Drawer } from 'antd'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { SmartFieldDrawerContent } from './smartfield'
import { useSmartFields, useSmartfieldModal } from './smartfield.hooks'

const StyledDrawer = styled(Drawer)`
  .ant-drawer-wrapper-body {
    display: flex;
    flex-direction: column;

    .ant-drawer-body {
      overflow: scroll;
      padding: 0px;
    }
  }
`

type Titles = 'Add SmartField' | 'Edit SmartField'

export const SmartFieldDrawer: React.FC = () => {
  const [drawerTitle, setDrawerTitle] = useState<Titles>('Add SmartField')

  const { visible, mode, smartfield, hideSmartFieldDrawer } = useSmartFields()
  const { modalWidth } = useSmartfieldModal()

  useEffect(() => {
    setDrawerTitle(mode === 'add' ? 'Add SmartField' : 'Edit SmartField')
  }, [mode])

  return (
    <StyledDrawer onClose={hideSmartFieldDrawer} title={drawerTitle} destroyOnClose width={modalWidth} open={visible}>
      <SmartFieldDrawerContent smartfield={smartfield} mode={mode} />
    </StyledDrawer>
  )
}
