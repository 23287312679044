import Icon from '@ant-design/icons'
import {
  MdAdminPanelSettings,
  MdBusiness,
  MdHome,
  MdOutlineAccountCircle,
  MdOutlineNotInterested,
  MdOutlinePeople,
  MdWidgets,
} from 'react-icons/md'
// FIXME: change icons

const iconType = {
  company: MdBusiness,
  contact: MdOutlinePeople,
  household: MdHome,
  lead: MdOutlineAccountCircle,
  other: MdWidgets,
  trust: MdAdminPanelSettings,
  not_connected: MdOutlineNotInterested,
}

export const ContactIcon = ({ type, ...props }) => <Icon {...props} component={iconType[type] || iconType.contact} />
