import Advisors from './advisors'
import Appt from './appointments'
import AppointmmentTypes from './appointmentTypes'
import Audit from './audit'
import Clients from './clients'
import Docs from './docs'
import Html from './html'
import Practice from './practice'
import Templates from './templates'
import Topics from './topics'

class Data {
  practice: Practice
  advisors: Advisors
  clients: Clients
  topics: Topics
  audit: Audit
  appt: Appt
  html: Html
  docs: Docs
  templates: Templates
  appointmentTypes: AppointmmentTypes

  constructor() {
    this.practice = new Practice()
    this.advisors = new Advisors()
    this.clients = new Clients()
    this.topics = new Topics()
    this.audit = new Audit()
    this.appt = new Appt()
    this.html = new Html()
    this.docs = new Docs()
    this.templates = new Templates()
    this.appointmentTypes = new AppointmmentTypes()
  }
}

export default Data
