import * as apiService from 'lib/apollo/client'
import { EntityId } from 'types/entity.interface'
import {
  AddBlockToTemplateDocument,
  AddBlockToTemplateMutation,
  AddBlockToTemplateMutationVariables,
  AllBlocksDocument,
  AllBlocksQuery,
  AllBlocksQueryVariables,
  BlockInput,
  CheckBlockUsageDocument,
  CheckBlockUsageQuery,
  CheckBlockUsageQueryVariables,
  CreateBlockDocument,
  CreateBlockMutation,
  CreateBlockMutationVariables,
  DeleteBlockDocument,
  DeleteBlockFromTemplateDocument,
  DeleteBlockFromTemplateMutation,
  DeleteBlockFromTemplateMutationVariables,
  DeleteBlockMutation,
  DeleteBlockMutationVariables,
  ReorderBlocksDocument,
  ReorderBlocksMutation,
  ReorderBlocksMutationVariables,
  TemplateBlockDocument,
  TemplateBlockQuery,
  TemplateBlockQueryVariables,
  TemplateBlocksDocument,
  TemplateBlocksQuery,
  TemplateBlocksQueryVariables,
  UpdateBlockDocument,
  UpdateBlockMutation,
  UpdateBlockMutationVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

class BlockService {
  async getTemplateBlocks(templateId: EntityId) {
    const { templateBlocks: resp } = await apiService.query<TemplateBlocksQuery, TemplateBlocksQueryVariables>(
      TemplateBlocksDocument,
      { templateId },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.blocks
  }

  async getAllBlocks() {
    const { allBlocks: resp } = await apiService.query<AllBlocksQuery, AllBlocksQueryVariables>(AllBlocksDocument)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.blocks
  }

  async getTemplateBlock(blockId: EntityId) {
    const { templateBlock: resp } = await apiService.query<TemplateBlockQuery, TemplateBlockQueryVariables>(
      TemplateBlockDocument,
      { blockId },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.block
  }

  async checkBlockUsage(blockId: EntityId) {
    const { checkBlockUsage: resp } = await apiService.query<CheckBlockUsageQuery, CheckBlockUsageQueryVariables>(
      CheckBlockUsageDocument,
      { blockId },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async updateBlock(id: EntityId, block: BlockInput) {
    const { updateBlock: resp } = await apiService.mutate<UpdateBlockMutation, UpdateBlockMutationVariables>(
      UpdateBlockDocument,
      { block, id },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.block
  }

  async createBlock(name: string, markup: string, desc: string, content: string) {
    const { createBlock: resp } = await apiService.mutate<CreateBlockMutation, CreateBlockMutationVariables>(
      CreateBlockDocument,
      { block: { markup, name, desc, content } },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.block
  }

  async deleteBlock(id: EntityId) {
    const { deleteBlock: resp } = await apiService.mutate<DeleteBlockMutation, DeleteBlockMutationVariables>(
      DeleteBlockDocument,
      { id },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async addBlockToTemplate(templateId: EntityId, blockId: EntityId) {
    const { addBlockToTemplate: resp } = await apiService.mutate<
      AddBlockToTemplateMutation,
      AddBlockToTemplateMutationVariables
    >(AddBlockToTemplateDocument, { templateId, blockId })

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.blocks
  }

  async deleteBlockFromTemplate(templateId: EntityId, blockId: EntityId) {
    const { deleteBlockFromTemplate: resp } = await apiService.mutate<
      DeleteBlockFromTemplateMutation,
      DeleteBlockFromTemplateMutationVariables
    >(DeleteBlockFromTemplateDocument, { templateId, blockId })

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.blocks
  }

  async reorderBlocks(templateId: EntityId, order: EntityId[]) {
    const { reorderBlocks: resp } = await apiService.mutate<ReorderBlocksMutation, ReorderBlocksMutationVariables>(
      ReorderBlocksDocument,
      { templateId, order },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }
}

export default new BlockService()
