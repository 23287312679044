import arimo from './cdn/arimo'
import crimson from './cdn/crimson+text'
import dancing from './cdn/dancing+script'
import garamond from './cdn/garamond'
import lato from './cdn/lato'
import montserrat from './cdn/montserrat'
import oldstandard from './cdn/old+standard'
import opensans from './cdn/opensans'
import roboto from './cdn/roboto'

/* Add new fonts:
1. Add the source to the app and email.
    - app: src/styles/fonts/app/cdn_fonts.js
    - email: src/styles/fonts/email/cdn_fonts.ts
2. Add the font in the remirror settings.
    - src/lib/remirror/components/toolbar/Toolbar.config.tsx fontFamilyAvailable
3. Update the remirror theme
    - src/lib/remirror/styles/theme.ts FONT
*/

export const fontsAndFallbacks = [
  { font: arimo, searchValue: `Arimo`, fallback: `Arial, Helvetica, sans-serif` },
  { font: crimson, searchValue: `Crimson Text`, fallback: `Georgia, serif` },
  { font: dancing, searchValue: `Dancing Script`, fallback: `cursive` },
  { font: dancing, searchValue: `"Dancing Script"`, fallback: `cursive` },
  { font: garamond, searchValue: `EB Garamond`, fallback: `Georgia, serif` },
  { font: montserrat, searchValue: `Montserrat`, fallback: `Verdana, 'Trebuchet MS', sans-serif` },
  { font: lato, searchValue: `Lato`, fallback: `Arial, Helvetica, sans-serif` },
  { font: oldstandard, searchValue: `Old Standard TT`, fallback: `'Times New Roman', serif` },
  { font: opensans, searchValue: `Open Sans`, fallback: `Verdana, 'Trebuchet MS', sans-serif` },
  { font: opensans, searchValue: `OpenSans`, fallback: `Verdana, 'Trebuchet MS', sans-serif` },
  { font: roboto, searchValue: `Roboto`, fallback: `Geneva, 'Trebuchet MS', sans-serif` },
]
