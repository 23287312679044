import { setStyle } from '@remirror/core'
import { EditorView, NodeView, ProsemirrorNode } from '@remirror/pm'
import { ResizableRatioType } from 'prosemirror-resizable-view'
import { ResizableNodeViewCustom } from './ResizableNodeViewCustom/ResizableNodeViewCustom'

/**
 * ResizableImageView is a NodeView for image. You can resize the image by
 * dragging the handle over the image.
 */
export class ResizableImageView extends ResizableNodeViewCustom implements NodeView {
  constructor(node: ProsemirrorNode, view: EditorView, getPos: () => number) {
    super({ node, view, getPos, aspectRatio: ResizableRatioType.Fixed })
  }

  createElement({ node }: { node: ProsemirrorNode }): HTMLImageElement {
    const inner = document.createElement('img')

    inner.setAttribute('src', node.attrs.src)

    setStyle(inner, {
      width: '100%',
      minWidth: '20px',
      objectFit: 'contain', // maintain image's aspect ratio
      userSelect: 'text',
    })

    return inner
  }
}
