import * as apiService from 'lib/apollo/client'
import {
  AddTagDocument,
  AddTagMutation,
  AddTagMutationVariables,
  AddTagsDocument,
  AddTagsMutation,
  AddTagsMutationVariables,
  CreateTagDocument,
  CreateTagMutation,
  CreateTagMutationVariables,
  DestroyTagDocument,
  DestroyTagMutation,
  DestroyTagMutationVariables,
  RemoveTagDocument,
  RemoveTagMutation,
  RemoveTagMutationVariables,
  RemoveTagsDocument,
  RemoveTagsMutation,
  RemoveTagsMutationVariables,
  RestoreTagDocument,
  RestoreTagMutation,
  RestoreTagMutationVariables,
  TagInput,
  UpdateTagDocument,
  UpdateTagMutation,
  UpdateTagMutationVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

class TagsService {
  async addTag(args: AddTagMutationVariables) {
    const { addTag: resp } = await apiService.mutate<AddTagMutation, AddTagMutationVariables>(AddTagDocument, args)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.result
  }

  async addTags(args: AddTagsMutationVariables) {
    const { addTags: resp } = await apiService.mutate<AddTagsMutation, AddTagsMutationVariables>(AddTagsDocument, args)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.result
  }

  async removeTag(args: RemoveTagMutationVariables) {
    const { removeTag: resp } = await apiService.mutate<RemoveTagMutation, RemoveTagMutationVariables>(
      RemoveTagDocument,
      args,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.result
  }

  async removeTags(args: RemoveTagsMutationVariables) {
    const { removeTags: resp } = await apiService.mutate<RemoveTagsMutation, RemoveTagsMutationVariables>(
      RemoveTagsDocument,
      args,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.result
  }

  async createTag(tag: TagInput) {
    const { createTag: resp } = await apiService.mutate<CreateTagMutation, CreateTagMutationVariables>(
      CreateTagDocument,
      { tag },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.result
  }

  async updateTag(value: string, tag?: TagInput) {
    const { updateTag: resp } = await apiService.mutate<UpdateTagMutation, UpdateTagMutationVariables>(
      UpdateTagDocument,
      { value, tag },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.result
  }

  async destroyTag(value: string, replacementValue?: string) {
    console.log('asdf', { value, replacementValue })
    const { destroyTag: resp } = await apiService.mutate<DestroyTagMutation, DestroyTagMutationVariables>(
      DestroyTagDocument,
      { value, replacementValue },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.value
  }

  async restoreTag(value: string) {
    const { restoreTag: resp } = await apiService.mutate<RestoreTagMutation, RestoreTagMutationVariables>(
      RestoreTagDocument,
      { value },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.value
  }
}

export default new TagsService()
