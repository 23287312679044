import * as apiService from 'lib/apollo/client'
import { EntityId } from 'types/entity.interface'
import {
  GetScratchDocument,
  GetScratchesDocument,
  GetScratchesOfApptsDocument,
  GetScratchesOfApptsQuery,
  GetScratchesOfApptsQueryVariables,
  GetScratchesQuery,
  GetScratchesQueryVariables,
  GetScratchQuery,
  GetScratchQueryVariables,
  SaveScratchDocument,
  SaveScratchMutation,
  SaveScratchMutationVariables,
  TransferScratchesDocument,
  TransferScratchesMutation,
  TransferScratchesMutationVariables,
  UpdateScratchNoteIntegrationDocument,
  UpdateScratchNoteIntegrationMutation,
  UpdateScratchNoteIntegrationMutationVariables,
} from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

class ScratchService {
  async getScratch(scratchId: EntityId) {
    const { getScratch: resp } = await apiService.query<GetScratchQuery, GetScratchQueryVariables>(GetScratchDocument, {
      scratchId,
    })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async getScratches(payload: GetScratchesQueryVariables) {
    const { getScratches: resp } = await apiService.query<GetScratchesQuery, GetScratchesQueryVariables>(
      GetScratchesDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.scratches
  }

  async getScratchesOfAppts(payload: GetScratchesOfApptsQueryVariables) {
    const { getScratchesOfAppts: resp } = await apiService.query<
      GetScratchesOfApptsQuery,
      GetScratchesOfApptsQueryVariables
    >(GetScratchesOfApptsDocument, payload)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.scratches
  }

  async saveScratch(payload: SaveScratchMutationVariables) {
    const { saveScratch: resp } = await apiService.mutate<SaveScratchMutation, SaveScratchMutationVariables>(
      SaveScratchDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async transferScratches(payload: TransferScratchesMutationVariables) {
    const { transferScratches: resp } = await apiService.mutate<
      TransferScratchesMutation,
      TransferScratchesMutationVariables
    >(TransferScratchesDocument, payload)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.accepted
  }

  async updateScratchNoteIntegration(payload: UpdateScratchNoteIntegrationMutationVariables) {
    const { updateScratchNoteIntegration: resp } = await apiService.mutate<
      UpdateScratchNoteIntegrationMutation,
      UpdateScratchNoteIntegrationMutationVariables
    >(UpdateScratchNoteIntegrationDocument, payload)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }
}

export default new ScratchService()
