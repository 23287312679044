import { observable, action, makeObservable } from 'mobx'
import { get, set, isNil } from 'lodash'

/**
 * In-Memory storage for user settings, backed up by browser localStorage
 */
class Storage {
  data = {}
  loaded = false
  log = false

  constructor(namespace = null) {
    makeObservable(this, {
      data: observable.ref,
      loaded: observable,
      _init: action,
    })

    this.rootKey = `pulse360-web-${process.env.REACT_APP_ENV}`
    this.namespace = namespace
    this._init()
  }

  /**
   * Creates a namespaced version of this store
   */
  create = (namespace) => new Storage(namespace)

  /**
   * Stores an item in the local storage
   *
   * @param {string} key
   * @param {string} value
   * @returns {string}
   */
  setItem = (key, value) => {
    if (this.log) {
      global.log('SET', key)
    }
    set(this, this._path(key), value)

    this._update()
    return value
  }

  /**
   * @param obj
   */
  setItems = (obj) => {
    Object.keys(obj).forEach((k) => this.setItem(k, obj[k]))
  }

  /**
   * Retrieves an item from the local storage
   *
   * @param {string} key
   * @returns {string}
   */
  getItem = (key, defaultVal) => {
    if (this.log) {
      global.log('GET', key)
    }
    // if a value is retrieved return it
    const val = get(this, this._path(key))
    if (!isNil(val)) {
      return val
    }
    // otherwise, if not previously set, and a defaultValue
    // has been provided, set that, and return it
    if (!isNil(defaultVal)) {
      return this.setItem(key, defaultVal)
    }
  }

  /**
   * Get all data associated to this cache
   */
  get = () => {
    const ns = this.namespace
    if (this.log) {
      global.log('GET', ns)
    }
    return get(this, ns ? `data.${ns}` : `data`)
  }

  /**
   * @param {*} key
   */
  hasItem(key) {
    const val = get(this, this._path(key))
    return !isNil(val)
  }

  /**
   * Removes an item from local storage
   *
   * @param {string} key
   */
  removeItem = (key) => {
    if (this.log) {
      global.log('REMOVE', key)
    }
    const item = this.namespace ? this.data[this.namespace] : this.data
    if (item) {
      delete item[key]
    }
    this._update()
    return true
  }

  /**
   * Clears the app local storage
   */
  clear = () => {
    if (this.namespace) {
      this.data[this.namespace] = {}
    } else {
      this.data = {}
    }
    this._update()
  }

  /* ---------- Private ---------- */

  /**
   * Initializes the store and any namespace provided
   */
  _init() {
    const data = localStorage.getItem(this.rootKey)
    this.data = data ? JSON.parse(data) : {}

    // if namespaced, ensure that this.data has the namespace
    if (this.namespace && !this.data[this.namespace]) {
      this.data[this.namespace] = {}
    }
    // ensure new values are persisted
    this._update()
  }

  /**
   * Retrieves the store key according to namespace
   */
  _path = (key) => {
    return this.namespace ? `data.${this.namespace}.${key}` : `data.${key}`
  }

  /**
   * Update the storage
   * @param val
   * @returns {Promise.<void>}
   * @private
   */
  _update = () => {
    localStorage.setItem(this.rootKey, JSON.stringify(this.data))
  }
}

export default Storage
