/* eslint-disable @typescript-eslint/ban-ts-comment */
import { SearchOutlined } from '@ant-design/icons'
import { Select, SelectProps, Skeleton, Spin, Tooltip } from 'antd'
import { debounce, snakeCase } from 'lodash'
import { observer } from 'mobx-react'
import type { BaseSelectRef } from 'rc-select'
import React, { useCallback, useState } from 'react'
import { integrationStore } from 'stores/integrations'
import styles from 'styles'
import { ExistsInCrm } from '../../../../constants/client'
import { ContactIcon } from '../../Icon/ContactIcon'
import { Client, IContactAutocompletePM } from './ContactAutocomplete.pm'

interface DefaulOptionProps {
  item: Client
  hasIntegration: boolean
}

const DefaultOption = ({ item, hasIntegration }: DefaulOptionProps) => {
  const label = global.data.clients.getFullName(item) || ''
  const tourLabel = global.data.clients.getFullName(item, false, false) || ''

  return (
    <Select.Option
      value={String(item.id)}
      label={label}
      data-tour={`contact-autocomplete-default-option__item_${snakeCase(tourLabel)}`}
      key={String(item.id)}
    >
      <ContactIcon type={item.type} style={{ paddingRight: 8 }} />
      {item.fullname}
      {hasIntegration && item.existsInCrm !== ExistsInCrm.EXISTS && (
        <ContactIcon
          type={'not_connected'}
          style={{ paddingLeft: 8, color: styles.colors.error }}
        />
      )}
    </Select.Option>
  )
}

const EmailOption = ({ item, hasIntegration }: DefaulOptionProps) => {
  const label = `${global.data.clients.getFullName(item)} (${item.email ? item.email : 'No Email'})`
  return (
    <Select.Option
      value={String(item.id)}
      label={label}
      key={String(item.id)}
      data-tour={`contact-autocomplete-email-option__item_${label}`}
    >
      <Tooltip title={item.email}>
        <ContactIcon type={item.type} style={{ paddingRight: 8 }} />
        {item.fullname}
        {hasIntegration && item.existsInCrm !== ExistsInCrm.EXISTS && (
          <ContactIcon
            type={'not_connected'}
            style={{ paddingLeft: 8, color: styles.colors.error }}
          />
        )}
      </Tooltip>
    </Select.Option>
  )
}

interface ContactAutoCompleteProps extends SelectProps {
  disabled?: boolean
  onSearch?: (value: any, option?: any) => void
  onSelect?: (value: any, option?: any) => void
  onClear?: () => void
  placeholder?: string
  value?: string | number
  defaultValue?: Record<string, any>
  pm: IContactAutocompletePM
  includeArchived?: boolean
  optionMode: 'default' | 'email'
  size?: 'large' | 'middle' | 'small'
}

const ContactAutoComplete = (
  {
    disabled = false,
    onSearch,
    onSelect,
    onClear,
    placeholder = 'Search Contact',
    value,
    pm,
    includeArchived = false,
    optionMode = 'default',
    autoFocus = true,
    size = 'large',
    allowClear = true,
    ...props
  }: ContactAutoCompleteProps,
  ref?: React.Ref<BaseSelectRef>,
) => {
  const [visible, setVisible] = useState(false)

  // @ts-ignore
  const hasIntegration = Boolean(integrationStore.enabledCRM || false)
  const handleSearch = debounce((value: string) => {
    pm.onSearch(value, includeArchived)
    onSearch && onSearch(value)
  }, 300)

  const onSelectContact = useCallback(
    (value: any) => {
      setVisible(false)
      onSelect?.(value)
    },
    [onSelect],
  )

  const onSearchClear = () => {
    onClear?.()
  }

  return pm.loadingContacts ? (
    <Skeleton active title={true} paragraph={false} />
  ) : (
    <Select
      {...props}
      ref={ref}
      value={value}
      showSearch
      placeholder={placeholder}
      optionFilterProp="label"
      size={size}
      allowClear={allowClear}
      autoFocus={autoFocus}
      suffixIcon={<SearchOutlined style={{ fontSize: 14 }} />}
      optionLabelProp="label"
      onSearch={handleSearch}
      onSelect={onSelectContact}
      onClear={onSearchClear}
      notFoundContent={pm.loading ? <Spin size="small" /> : null}
      loading={pm.loading}
      // onDropdownVisibleChange={(isOpen) => (isOpen ? pm.clearClients : null)}
      tokenSeparators={
        optionMode === 'email' ? [', ', '; ', ',', ';'] : undefined
      }
      onDropdownVisibleChange={setVisible}
      open={visible}
      filterOption={optionMode !== 'default'}
      disabled={disabled}
    >
      {pm.clients.map((item) =>
        optionMode === 'default'
          ? DefaultOption({ item: item, hasIntegration: hasIntegration })
          : EmailOption({ item: item, hasIntegration: hasIntegration }),
      )}
    </Select>
  )
}

export default observer(React.forwardRef(ContactAutoComplete))
