// import { message } from 'antd'
import { notification } from 'antd'
// import { uniqueId } from 'lodash'

notification.config({
  placement: 'topRight',
  maxCount: 1,
  duration: 3,
})

class Msg {
  lastMsg = null
  key = null
  // success('Well Done', 3)
  // success('Well Done', 'You did good', 3)
  success(message, description, duration) {
    return this._msg('success', arguments)
  }

  /**
   * Shows a wser-facing error message
   * @param {String[] | String} message if Array, will use the generic wording, if string it will use that
   * @param {Number} duration
   */
  error(message, description = '', duration = 6) {
    if (Array.isArray(message)) {
      return this._msg('error', [this._genericErrorMsg(...message), description, duration])
    }
    return this._msg('error', arguments)
  }

  info(message, description, duration) {
    return this._msg('info', arguments)
  }

  warning(message, description, duration = 6) {
    return this._msg('warning', arguments)
  }

  _genericErrorMsg(article, verb, adjective = 'the') {
    return `There was a problem ${verb} ${adjective} ${article}, we'll be taking a look to see what happened :-)`
  }

  _msg(type, args) {
    // if (isEqual(this.lastMsg, args)) {
    //   return
    // }
    let [message, description, duration] = args

    // if (this.key && String(this.key).includes(message)) {
    //   notification.close(this.key)
    // }

    // this.key = uniqueId() + '__' + message
    // this.lastMsg = args
    let placement = 'topRight'

    if (typeof description === 'number') {
      duration = description
      description = null
      if (typeof duration === 'string') {
        placement = duration
      }
    }

    notification[type]({
      className: `p360-message ${type}`,
      // key: this.key,
      description,
      placement,
      duration,
      message,
    })
  }
}

export default Msg

export const msg = new Msg()
